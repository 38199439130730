import { DropDownProps, ValueLabelProps } from "@hi-interactive-admin/simulador-kinto";

export interface Simulation {
    // [key: string]: any;
    id: number;
    vehicleId: number;
    no: string;
    deadline: number;
    miles: number;
    tires: boolean;
    tiresQuantity: string;
    mostUsed:number;
    insurance: string;
    maintenance: boolean;
    secureOptions: boolean;
    vehicleReplacementOptions: boolean;
    ownDamage: string;
    occupants: string;
    travelAssistance: string;
    responsibility: string;
    glassBreakage: string;
    maintenanceDescription: string;
    iuc: number;
    fuelCard: boolean;
    petrolPump: boolean;
    viaVerde: boolean;
    entityViaVerde: string;
    fuelDescriptiom: string;
    runFlaat: boolean;
    vehicleType:string;
    vsOverhaulVehicle: string;
    vsOverhaulVehicleType: string;
    vsAssistanceVehicleType: string;
    vsAssistanceVehicle: string;
    vsAccidentVehicleType: string;
    vsAccidentVehicle: string;
    vsTheftVehicleType: string;
    vsTheftVehicle: string;
    insurancePackageOptions: any[];
    vehicleDamageOptions: any[];
    occupiersOptions: any[];
    roadAssistanceOptions: any[];
    liabilityOptions: any[];
    isolatedGlassBreakOptions: any[];
    fuelOptions: any[];
    viaVerdeOptions: any[];
    vehicleTypeOptions: any[];
    vsAssistanceVehicleOptions: any[];
    vsAssistanceVehicleTypeOptions: any[];
    vsAccidentVehicleOptions: any[];
    vsAccidentVehicleTypeOptions: any[];
    vsTheftVehicleOptions: any[];
    vsTheftVehicleTypeOptions: any[];
    vsOverhaulVehicleOptions: any[];
    vsOverhaulVehicleTypeOptions: any[];

    //campos adicionados para cotações
    //configurações de negócio
    priceexclVAT: number;
    consumersPrice: number;

    totalExtraWithoutTax:number;
    totalExtraWithTax:number;

    totalAccessoriesWithout:number;
    totalAccessoriesWith:number;

    discountWithoutTax: number;
    discountWithTax: number;
    discountWithTaxPercentage: number;
    discountWithTaxTotal:number;

    proformaInvoicePriceWithoutTax:number;
    proformaInvoicePriceWithTax:number;

    campaignWithoutTax: number;
    campaignWithTax: number;
    campaignWithTaxPercentage: number;
    campaignWithTaxTotal:number;

    rappelWithoutTax: number;
    rappelWithTax: number;
    rappelWithTaxPercentage: number;
    rappelWithTaxTotal:number;

    extraRappel: number;

    businessValueWithoutTax:number;
    businessValueWithTax:number;
    //tipo de contrato
    quantity:number;
    // quotation calculated fields
    baseIncomeWithoutTax:number;
    incomeWithoutTax:number;
    incomeWithTax:number;
    increaseVR:number;
    margin:number;
    realVR:number;
    vrEurotax:number;
    vrTable:number;
    baseVRTable:number;

    amortization:number;
    interests:number;
    tyresCost:number;
    maintenanceCost:number;
    iucCost:number;
    insuranceCost:number;
    vsCost:number;

    finalTax:number;
    estimatedMargin:number;
    monthlyTCOWithTax:number;
    monthlyTCOWithoutTax:number;
    additionalKmsWithTax:number;
    additionalKmsWithoutTax:number;
    collateral:number;
    fee:number;
    initialEntry:number;
    travelledKmsWithTax:number;
    travelledKmsWithoutTax:number;
    proposeToCustomer:boolean;
    title: string;
    visibility: number;
    complementaryProducts: ComplementaryProduct[]; 
    maximum_Kilometers: number;

    contractExpenses: number;
    commissionValue: number;
    commissionCode: string;
}

export const newSimulation: Simulation = {
    id: 0,
    vehicleId: 0,
    no: "", // vehicle.simulations[0].no //TODO validar se é para preencher neste caso
    deadline: 0,
    miles: 0,
    tires: false,
    mostUsed: 0,
    tiresQuantity: "",
    insurance: "",
    maintenance: false,
    secureOptions: false,
    vehicleReplacementOptions: false,
    ownDamage: "",
    occupants: "",
    travelAssistance: "",
    responsibility: "",
    glassBreakage: "",
    maintenanceDescription: "",
    iuc: 0,
    fuelCard: false,
    petrolPump: false,
    viaVerde: false,
    entityViaVerde: "",
    fuelDescriptiom: "",
    runFlaat: false,
    vehicleType: "",
    vsOverhaulVehicle: "",
    vsOverhaulVehicleType: "",
    vsAssistanceVehicleType: "",
    vsAssistanceVehicle: "",
    vsAccidentVehicleType: "",
    vsAccidentVehicle: "",
    vsTheftVehicle: "",
    vsTheftVehicleType: "",
    insurancePackageOptions: [],
    vehicleDamageOptions: [],
    occupiersOptions: [],
    roadAssistanceOptions: [],
    liabilityOptions: [],
    fuelOptions: [],
    viaVerdeOptions: [],
    isolatedGlassBreakOptions: [],
    vehicleTypeOptions: [],
    vsAssistanceVehicleOptions: [],
    vsAssistanceVehicleTypeOptions: [],
    vsAccidentVehicleOptions: [],
    vsAccidentVehicleTypeOptions: [],
    vsOverhaulVehicleOptions: [],
    vsOverhaulVehicleTypeOptions: [],
    vsTheftVehicleOptions: [],
    vsTheftVehicleTypeOptions: [],
    //campos das cotações
    priceexclVAT: 0,
    consumersPrice: 0,
    totalExtraWithoutTax: 0,
    totalExtraWithTax: 0,
    totalAccessoriesWithout: 0,
    totalAccessoriesWith: 0,
    discountWithoutTax: 0,
    discountWithTax: 0,
    discountWithTaxPercentage: 0,
    discountWithTaxTotal: 0,
    proformaInvoicePriceWithoutTax: 0,
    proformaInvoicePriceWithTax: 0,
    campaignWithoutTax: 0,
    campaignWithTax: 0,
    campaignWithTaxPercentage: 0,
    campaignWithTaxTotal: 0,
    rappelWithoutTax: 0,
    rappelWithTax: 0,
    rappelWithTaxPercentage: 0,
    rappelWithTaxTotal: 0,
    extraRappel: 0,
    businessValueWithoutTax: 0,
    businessValueWithTax: 0,
    //tipo de contrato
    quantity: 0,
    // quotation calculated fields
    baseIncomeWithoutTax:0,
    incomeWithoutTax: 0,
    incomeWithTax: 0,
    increaseVR: 0,
    margin: 0,
    realVR: 0,
    vrEurotax: 0,
    vrTable: 0,
    baseVRTable:0,

    amortization: 0,
    interests: 0,
    //validar se estes 4 campos estão duplicados
    tyresCost: 0,
    maintenanceCost: 0,
    iucCost: 0,
    insuranceCost: 0,
    vsCost: 0,

    finalTax: 0,
    estimatedMargin: 0,
    monthlyTCOWithTax: 0,
    monthlyTCOWithoutTax: 0,
    additionalKmsWithTax: 0,
    additionalKmsWithoutTax: 0,
    collateral: 0,
    fee: 0,
    initialEntry: 0,
    travelledKmsWithTax: 0,
    travelledKmsWithoutTax: 0,
    proposeToCustomer: false,
    title: "",
    visibility: 0,
    complementaryProducts: [],
    maximum_Kilometers: 0,
    contractExpenses: 0,
    commissionValue: 0,
    commissionCode: ""
}

export interface ComplementaryProduct {
    id: number;
    simulationId: number; 
    no: string;
    description: string; 
    additionalCostValue: string;
    additionalCostTimePeriod : string;
    insurance : string;
    accessoryType : string;
    basePriceWithoutTax: number;
    basePriceWithTax: number;
    residualValue: number;
    supplier: string;
    registerCode: string;
    lineNo: number;
    headerNo: string;
}

export const newComplementaryProduct: ComplementaryProduct = {
    id: 0,
    simulationId: 0, 
    no: '',
    description: '', 
    additionalCostValue: '',
    additionalCostTimePeriod : '',
    insurance : '',
    accessoryType : '',
    basePriceWithoutTax: 0,
    basePriceWithTax: 0,
    residualValue: 0,
    supplier: '',
    registerCode: '',
    lineNo: 0,
    headerNo: ''
}

export interface CommissionOptions {
    title: string;
    comissionValue: ValueLabelProps;
    comissionCode: DropDownProps;
}

export const newCommissionOption: CommissionOptions = {
    title: "",
    comissionValue: {
        value: "", 
        label: ""
    },
    comissionCode: {
        label: 'Código de comissão',
        value : '',
        defaultValue: '',
        options: [],
        name: 'commissionCode',
        onChange:  () => {}, 
        disabled: false,
        hidden: false,
        warning: false
    }
}

export interface CommissionOoptionsPerSimulation {
    vehicleIndex: number;
    simulationIndex: number;
    commissionOptions: CommissionOptions;
}

export interface CommercialConditionCommissionFilters {
    commercialCondition: string,
    comissionFilters: string[]
}