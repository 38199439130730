//import { VehicleDetailsProps } from "@hi-interactive-admin/simulador-kinto";
import { IAccessory } from "./Accessory";
import { IExtraStandard } from "./ExtraStandard";
import { Simulation } from "./Simulation";
import { Option } from "./interfaces";

export interface IVehicle {
  [x: string]: any;
  id:number,
  leaseQuoteNo: string;
  proposalId: number;
  makeId: string;
  modelId: string;
  modelDescription:string; //usado nas cotações
  versionId: string;
  versionDescription: string;
  caracteristics: string;
  no:string;
  hidden:boolean; //variavel de estado nos dados da viatura
  euroTaxInternationalSegmt: string;
  driverName:string; //usado na listagem das propostas

  //despesas
  
 
  recycling_charge: number; //ecovalor
  luxury_tax: number;
  luxury_tax_vat: number;
  vat:number;
  transport_x0026_immatriculation_cost: number;
  pvp:number;

  //caracteristicas
  licensePlate: string;
  engineCc: number;
  powerHP: number;
  lotId:string;

  //configurações do negócio
  priceexclVAT: number;
  consumersPrice: number;

  totalExtraWithoutTax:number;
  totalExtraWithTax:number;

  totalAccessoriesWithout:number;
  totalAccessoriesWith:number;

  discountWithoutTax: number;
  discountWithTax: number;
  discountWithTaxPercentage: number;
  discountWithTaxTotal:number;

  proformaInvoicePriceWithoutTax:number;
  proformaInvoicePriceWithTax:number;

  campaignWithoutTax: number;
  campaignWithTax: number;
  campaignWithTaxPercentage: number;
  campaignWithTaxTotal:number;

  rappelWithoutTax: number;
  rappelWithTax: number;
  rappelWithTaxPercentage: number;
  rappelWithTaxTotal:number;

  extraRappel: number;

  //acessorios e extras
  accessories: IAccessory[];
  extraStandard: IExtraStandard[];
  extraOptionsSearch:any[];
  extraOptionSelect:any;
  simulations:Simulation[]
  //caracteristicas tecnicas
  modelYear: string;
  numberofDoors: number;
  recordDate: string;
  status: string;
  mileage: number;
  use: string;

  technMaxQuantityKm:number;
  fuelId: string;
  //espaço e dimensões
  quantity_of_Seats: number;
  lengthmm: number;
  widthmm: number;
  heightmm: number;
  trunkVolumel: number;
  wheelBasemm: number;
  trainFrontWheelsmm: number;
  trainRearWheelsmm: number;
  //especificações do motor
  compressionratio: number;
  bore: number;
  torqueNm: number;
  powerkW: number;
  fuelTankCapacityl: number;
  fuelType: string;
  cO2DischargeDecF: number;
  maxspeedkmh: number;
  acceleration0100kmh: number;
  //outras informações/serviços
  bPM:number;
  businessValueWithoutTax:number;
  businessValueWithTax:number;
  oilChange:number;
  //tipo de contrato
  typeofRental:string;
  commercialConditions:string;
  typeofIncome:string;
  quantity:number;
  renew:boolean;
  driverId:string;
  iuc:number;
  commercialOptions:Option[]; //lista por linha de viatura no tipo de contrato
  number_of_wheels:number,
  group_Price_Map:string;
  // quotation props
  vehicleStatus:{
    color: 'error' | 'success' | 'warning' | 'info';
    label: string;
  };

  functionalGroup: string;
}

export interface IModel {
  makeId: string;
  models: [{ label: string; value: string;}];
}
export interface IVersion {
  makeId: string;
  modelId: string;
  versions: { label: string; value: string; isInactive: boolean; }[];
}
export interface IVehicleAccessories {
  indexVehicle: number;
  accessories: IAccessory[];
}
export interface IVehicleExtrasStandard {
  indexVehicle: number;
  extraStandard: IExtraStandard[];
}

export interface ICommision {
  code: string; 
  business_Price_Percent: number; 
  amount: number;
  description: string; 
}
export const newVehicle: IVehicle = {
  id:0,
  proposalId: 0,//proposal.id,
  makeId: "",
  leaseQuoteNo:"",
  versionId: "",
  modelId: "",
  licensePlate: "",
  priceexclVAT: 0,
  consumersPrice: 0,
  pvp:0,
  recycling_charge: 0,
  luxury_tax: 0,
  transport_x0026_immatriculation_cost: 0,
  status: "New",
  use: "",
  numberofDoors: 0,
  fuelId: "100001",
  fuelType: "",
  euroTaxInternationalSegmt: "",
  quantity_of_Seats: 0,
  lengthmm: 0,
  widthmm: 0,
  heightmm: 0,
  trunkVolumel: 0,
  wheelBasemm: 0,
  trainFrontWheelsmm: 0,
  trainRearWheelsmm: 0,
  compressionratio: 0,
  bore: 0,
  powerHP: 0,
  powerkW: 0,
  cO2DischargeDecF: 0,
  engineCc: 0,
  torqueNm: 0,
  fuelTankCapacityl: 0,

  maxspeedkmh: 0,
  acceleration0100kmh: 0,
  mileage: 0,
  modelYear: "",
  recordDate: "",
  accessories: [],
  extraStandard: [],
  simulations: [],
  no: "",
  lotId:"",
  totalExtraWithoutTax:0,
  totalAccessoriesWithout:0,
  proformaInvoicePriceWithoutTax:0,
  totalExtraWithTax:0,
  totalAccessoriesWith:0,
  proformaInvoicePriceWithTax:0,
  discountWithTaxTotal:0,
  rappelWithTaxTotal:0,
  campaignWithTaxTotal:0,
  businessValueWithoutTax:0,
  businessValueWithTax:0,
  discountWithTax:0,
  discountWithoutTax:0,
  discountWithTaxPercentage:0,
  campaignWithTax:0,
  campaignWithoutTax:0,
  campaignWithTaxPercentage:0,
  rappelWithTax:0,
  rappelWithTaxPercentage:0,
  rappelWithoutTax:0,
  extraRappel:0,
  extraOptionsSearch:[],
  extraOptionSelect:0,
  versionDescription: "",
  caracteristics:"",
  quantity:1,
  typeofRental: '',
  commercialConditions: '',
  typeofIncome: '',
  oilChange:0,
  bPM:0,
  luxury_tax_vat:0,
  vat:0,
  technMaxQuantityKm:0,
  driverId:"", //FIXME susbtituir por nome do contato do user quando este estiver definido no user
  renew:false,
  hidden:false,
  commercialOptions:[],
  modelDescription: "",
  iuc:0,
  group_Price_Map:"",
  vehicleStatus: {
    color: "warning",
    label: "evaluating",
  },
  driverName:"",
  number_of_wheels:0,
  functionalGroup: "",
}

export interface CommercialConditionObject {
  code: string,
  commissioning_Filter: string,
  insurance_Filter: string,
  label: string,
  value: string
}