import {
  AccountIcon,
  Alert,
  AlertProps,
  DocumentAndImageIcon,
  DocumentIcon,
  DocumentPDFIcon,
  EditProposalPage,
  EditProposalPageProps,
  ProgressBarProps,
  PumpDieselIcon,
  RadioGroupProps,
  RichEditorProps,
  VehicleFrontIcon,
} from "@hi-interactive-admin/simulador-kinto";
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Proposal } from "../../../model/Proposal";
import { IVehicle, newVehicle } from "../../../model/Vehicle";
import { Option } from "../../../model/interfaces";
import { KintoRootProps } from "../../../model/KintoComponentProps";

import { ProposalService } from "../../../services/ProposalService";
import { ContactsService } from "../../../services/ContactsService";
import { VehicleService } from "../../../services/VehicleService";
import { SettingsService } from "../../../services/SettingsService";

import { AppSettings, EmptyAppSettings } from "../../../model/Settings";
import { AutocompleteInputChangeReason } from "@mui/material";

import useVehiclesCustomHook from "./vehicleCustomHook";
import useNavbarHook from "../../../customCallbacks/navbarHook";
import usePermissionsHook from "../../../customCallbacks/permissions/permissionsHook";

import { convertStringToEnum, downloadFile, formatDateWithTimeZone } from "../../../Utilities/Utils";
import { AdvancedFilterProposalOptions, ExportFormat } from "../../../model/Enums";

import { useAsyncRequest } from "../../../services/AsyncRequestContext";
import { UpsertProposalRequest } from "../../../model/UpsertProposalRequest";
// import { Simulation } from "../../../model/Simulation";
import useRentalTypesCustomHook from "../proposalListCallbacks/rentalTypeHooks";
import useVehicleBrandsCustomHook from "../proposalListCallbacks/vehicleBrandsHooks";
import useDriverNamesCustomHook from "../proposalListCallbacks/driverNamesHooks";
import useDurationCustomHook from "../proposalListCallbacks/durationHooks";
import useDistanceCustomHook from "../proposalListCallbacks/distanceHooks";
import useProposalStatesHook from "../../../customCallbacks/proposal/proposalStatesHook";

type EditProposalRouteParams = { proposalId: string; };

const contactService = new ContactsService();
const proposalService = new ProposalService();
const vehicleService = new VehicleService();


interface SimulationTableData {
  vehicle: ExpVehicle;
  simulationID: string;
  residualValue: string;
  clientPrice: string;
  status: {
      color: 'error' | 'success' | 'warning' | 'info';
      label: string;
  };
  header: VehicleHeader;
  index: number;
}
interface ExpVehicle {
  icons: string[];
  model: string;
  brand: string;
  version: string;
  segment: string;
  characteristics: string;
  lot: string;
}

interface VehicleHeader {
  icons: string[];
  model: string;
  characteristics: string;
  lot: string;
}

const EditProposalPageDemo: React.FC<KintoRootProps>  = ({ onLogout }) => {

  const [open, setOpen] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<Omit<AlertProps, "open" | "onClose">>({
    variant: "info",
    text: "",
    title: "",
    autoHideDuration: 3000,
  });
  const openAlert = (alertProps: Omit<AlertProps, "open" | "onClose">) => {
    setAlertProps(alertProps);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  const [settings, setSettings] = useState<AppSettings>(new EmptyAppSettings());
  useEffect(() => {
    SettingsService.getInstance()
      .then(x => setSettings(x.settings));
  }, []);
  

  const timeZone:string = "pt-PT";
  const { 
    //navigate,
    handleCardClick,
    // handleLogout,
    topBarPropsMemo,
    dashboardMenuButtonPropsMemo, 
    proposalMenuButtonPropsMemo, 
    clientsMenuButtonPropsMemo, 
    contractsMenuButtonPropsMemo,
    performanceMenuButtonPropsMemo, 
    backofficeMenuButtonPropsMemo
  } = useNavbarHook(onLogout);

  // utilizador logado
  const {
    currentUser, 
    // setCurrentUser, 
    propertyDisabled, 
    propertyHidden
  } = usePermissionsHook();

  const params = useParams<EditProposalRouteParams>();
  const [contractSelectedRows, setContractSelectedRows] = 
    useState<{vehicleIndex: number, simulationIndex: number }[]>([{ vehicleIndex: -1, simulationIndex: -1}]);
    
  const [newNote, setNewNote] = useState<RichEditorProps['html']>(``);
  const [filesProgressBarProps, setFilesProgressBarProps] = useState<ProgressBarProps[]>();
  const handleNewNoteChange: RichEditorProps['onChange'] = (value) => { setNewNote(value); };

  const [option, setOption] = useState<string>(ExportFormat.PDF);

  const handleExportOptionChange: RadioGroupProps['onChange'] = (e) => { setOption(e.target.value); };

  const [proposal, setProposal] = useState<Proposal>({ //state?.proposal ?? {
    id: 0,
    description:"",
    webPortalContactId: "",
    contactId: "",
    inputChannel: "0",
    requestingChannel: "0",
    totalFleet: 0,
    clientType: "",
    clientTypology: "",
    comments: [],
    vehicles:[],
    leaseQuoteNo: "",//"CO10156555",
    sendToApprovedDate:"",
    sendToApprovedValidateDate:"",
    //listagem de propostas
    clientName: "",
    nif: "",
    salespersonCode: "",
    salesPersonName: "",
    state: "Pendente",
    // {
    //   label: "Estado",
    //   tagLabel: "Pendente",
    //   color: "warning",
    //   description: "descrição",
    // },
    isClientApproved: false,
    printProposalAttachments: [],
    printProposalComments: "",
    printProposalDestinationEmailAddress: "",
    printProposalRentalTypeId:"",
    printProposalTitle:"",
    functionalGroup: "",
  });
  const [proposalContactName, setProposalContactName] = useState<string>('');

  // const [vehicleDataTable, setVehicleDataTable] = useState<IVehicle[]>([]);
  const [searchOptions, setSearchOptions] = useState<Option[]>([]);
  const [searchValue, setSearchValue] = useState<any>('');
  const [searchResults, setSearchResults] = useState<IVehicle[]>([]);
  const [clearSearch, setClearSearch] = useState<boolean>(true);

  const [exportSearchOptions, setExportSearchOptions] = useState<Option[]>([]);  
  const [exportSearchValue, setExportSearchValue] = useState<any>('');
  const [exportSearchResults, setExportSearchResults] = useState<SimulationTableData[]>([]); // Fix the type declaration
  const [clearExportSearch, setClearExportSearch] = useState<boolean>(true);
  
  const { 
    vehicleDataTable, 
    setVehicleDataTable, 
    // vehicles, 
    // handleVehicleCallback, 
    vehiclesMemo,
    setAnnualKmOptions,
    vehicleGroupEditModalValues, 
    setVehicleGroupEditModalValues,
    handleGroupEditModalChange, 
    handleCloseGroupEditModal
  } = useVehiclesCustomHook(proposal, searchResults, setContractSelectedRows);

  const handleOnInputSearchOptions = useCallback((e:React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    if(value !== 'undefined') {
      //TODO validar porque e que o valor aqui é limpo no input
      let option: Option = {
        label: value,
        value: value,
      }
      setSearchValue(option);
    }
  },[])

  // const handleOnChangeSearchOptions = useCallback((event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
  //   setSearchValue(value);
  //   setClearSearch(false);
  // },[])

  const handleSelectOption = useCallback(() => {
    if(searchValue) {
      let results1:IVehicle[] = []; 
      vehicleDataTable.forEach((vehicle, index) => {
        if(vehicle.versionDescription.toLocaleLowerCase().includes(searchValue?.label.toLowerCase())) {
          results1.push(vehicle);
        }
        else {
          vehicle.simulations.forEach((simulation, simIndex) => {
            if(simulation.no.toLocaleLowerCase().includes(searchValue?.value.toLocaleLowerCase())) {
              //check if the vehicle is already in the results
              if(!results1.some(x => x.id === vehicle.id)) {
                vehicle.simulations = [simulation];
                results1.push(vehicle);
              }
              else{
                results1[index].simulations.push(simulation);                
              }
            }
          });
        }

        
      });
      // let results = vehicleDataTable.filter(vehicle => { 
      //   return vehicle.simulations.filter(simulation => { return simulation.no.toLocaleLowerCase().includes(searchValue?.value.toLocaleLowerCase()) }) 
      //   || vehicle.versionDescription.toLocaleLowerCase().includes(searchValue?.label.toLowerCase() 
      //   )
      // })
      
      setSearchResults(results1);
      setClearSearch(false);
    }
    setSearchValue('');

  },[searchValue, vehicleDataTable])

  const handleExportfilterSelectOption = useCallback(() => {
    //alert("handleExportfilterSelectOption");
    if(exportSearchValue) {
      let results = exportSearchResults.filter(vehicle =>  
          vehicle.simulationID.toLocaleLowerCase().includes(exportSearchValue?.value.toLocaleLowerCase()) || 
          vehicle.vehicle.version.toLocaleLowerCase().includes(exportSearchValue?.label.toLowerCase()));   
      setExportSearchResults(results);
      setClearExportSearch(false);
    }
    //setExportSearchValue('');

  },[exportSearchValue, exportSearchResults])

  const handleOnInputExportSearchOptions = useCallback((e:React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    if(value !== 'undefined') {
      //TODO validar porque e que o valor aqui é limpo no input
      let option: Option = {
        label: value,
        value: value,
      }
      setExportSearchValue(option);
    }
  },[])

  const loadSelectedSimulations = useCallback(() => {
    var vehicles:any = vehicleDataTable.filter((vehicle, index) => 
      contractSelectedRows.some(x => x.vehicleIndex === index))
      .flatMap((vehicle, index) => {          
        return vehicle.simulations
          .filter((simulation, simIndex) => 
            contractSelectedRows.some(x => x.simulationIndex === simIndex))
          .map((sim, simIndex) => {
            return {
              header: {
                characteristics: vehicle.caracteristics,
                icons: [PumpDieselIcon, VehicleFrontIcon],
                lot: `Lote ${vehicle.lotId}`,
                model: vehicle.versionDescription,
              },
              simulationID: sim.no,
              residualValue: sim.vrEurotax?.toString(),
              clientPrice: sim.consumersPrice?.toString(),
              status: {
                color: 'info',
                label: vehicle.status,
              },
              vehicle: {
                characteristics: vehicle.caracteristics,
                icons: [PumpDieselIcon, VehicleFrontIcon],
                lot: `Lote ${vehicle.lotId}`,
                model: vehicle.versionDescription ?? '',
                brand: vehicle.makeId ?? '',
                segment: vehicle.segment ?? '',
                version: vehicle.versionDescription ?? '',
              },
            };
          });
    });
    setExportSearchResults(vehicles);
    setExportSearchOptions(vehicles.map((vehicle: { header: { model: any; }; simulationID: any; }) => {
      return {label: vehicle.header.model, value: vehicle.simulationID};
    }));

  },[ contractSelectedRows, vehicleDataTable])

  const handleAddVehicleClick = useCallback((proposalId:number) => () => {
    setVehicleDataTable((rows) => {
        let vehicleToAdd = {...newVehicle, 
          proposalId: proposalId,
          typeofRental: currentUser?.typeofRental ? currentUser?.typeofRental : '',
          commercialConditions: currentUser?.commercialConditions ? currentUser?.commercialConditions : '',
          typeofIncome: currentUser?.typeofIncome ? currentUser.typeofIncome : '',
        };
        return [...rows, vehicleToAdd];
      }
    );          
  }, [currentUser, setVehicleDataTable]);

  const getFuelAndViaVerdeOptions:any = useCallback( async (comercialCondition: string) => {
    const viaVerdeResponse = await vehicleService.getViaVerde(comercialCondition)
    const fuelResponse = await vehicleService.getFuelPrice(comercialCondition)
    const viaVerdeOptions:Option[] = !viaVerdeResponse.succeeded ? [] : viaVerdeResponse.value.map((item:any) => ({label: item.description, value: item.code}));
    const fuelOptions:Option[] = !fuelResponse.succeeded ? [] : fuelResponse.value.map((item:any) => ({label: item.description, value: item.code}));
    return {
      viaVerdeOptions: viaVerdeOptions,
      fuelOptions: fuelOptions
    };
  }, []);

  const getInsuranceOptions:any = useCallback( async (comercialCondition: string) => {
    const response = await vehicleService.getInsurancePackage(comercialCondition);
    const insuranceOptions:Option[] = !(response && response.length > 0) ? [] : response.map((item:any) => ({value: item.value, label: item.label}));
    return insuranceOptions;    
  },[]);

  const getCommercialConditionsRelatedOptions = useCallback( async (vehicles: IVehicle[]) => {
    
    let searchOptionsDTO: {
      vehicleIndex: number;
      fuelOptions: Option[];
      viaVerdeOptions: Option[];
      insurancePackageOptions: Option[];
    }[] = [];

    for (let i = 0; i < vehicles.length; i++) {
      const fuelAndVVResponse = await getFuelAndViaVerdeOptions(vehicles[i].commercialConditions);
      const insuranceOptions = await getInsuranceOptions(vehicles[i].commercialConditions);
      searchOptionsDTO.push({
        vehicleIndex: i,
        fuelOptions: fuelAndVVResponse.fuelOptions,
        viaVerdeOptions: fuelAndVVResponse.viaVerdeOptions,
        insurancePackageOptions: insuranceOptions,
      });
    }
    return searchOptionsDTO;
  }, [getFuelAndViaVerdeOptions, getInsuranceOptions]);

  const onExportEraseLink_Click = useCallback (() => {
    //setExportSearchResults([]);
    loadSelectedSimulations();
    setExportSearchValue({
        label: '',
        value: '',
      });
    setClearExportSearch(true);
  },[loadSelectedSimulations])
  
  useEffect(() => {
    proposalService.get(Number(params.proposalId)).then(data => {
      if(data && data.succeeded && data.value) {
        setProposal(data.value);
        
        let searchOptionsDTO: Option[] = [];
        if(data.value.vehicles) {
          data.value.vehicles.forEach((vehicle:IVehicle) => {
            searchOptionsDTO.push({
              label: `${vehicle.versionDescription}` ?? '',
              value: vehicle.id.toString(),
            });    
          });
        }
        setSearchOptions(searchOptionsDTO);

      } else {
        //alert('an error ocurred')
        openAlert({
          variant: "error",
          text: data.message,
          title: "Atenção",
        });
      }
    })
  },[params.proposalId, setVehicleDataTable])

  useEffect(() => {
    if(proposal.webPortalContactId !== '' && proposal.webPortalContactId !== null){
      contactService.getContactsByNo(proposal.webPortalContactId).then(data => {
        if(data && data.succeeded && data.value) {
          setProposalContactName(data.name)
        }
      });
    }
    //setAnnualKmOptions(rows => [...rows, {label: 'XXXX', value: '10000000'}]);
    setAnnualKmOptions(rows => {
      // insert options from vehicles simulations that doesn't exist in annualKmOptions
      let simulations = proposal.vehicles.flatMap(vehicle => vehicle.simulations);
      let results = simulations.filter(simulation => !rows.some(item => item.value.includes((simulation.miles/(simulation.deadline/12)).toString())));
      results.forEach(item=> rows.push({label:(item.miles/(item.deadline/12)).toString() + ' km/ano',value:(item.miles/(item.deadline/12)).toString()}));
      return rows;
    });
    
  //   let simulations = proposal.vehicles.flatMap(vehicle => vehicle.simulations);
  //   let results = simulations.filter(simulation => !annualKmOptions.filter(item => item.value.includes(simulation.deadline.toString())));
  // // results.forEach(item=> annualKmOptions.push({label:item.deadline.toString(),value:item.deadline.toString()}));

    getCommercialConditionsRelatedOptions(proposal.vehicles)
    .then((commConditionsRelatedOptions)=>{
      setVehicleDataTable((rows) => {
        rows = proposal.vehicles;
        rows.map(async (vehicle, index) => {
          let extrasSearch: Option[] = [];
          vehicle.extraStandard.map((element: any )=> element.visible = true);
          vehicle.extraStandard.map((element: { description: any; optionTypeId: any; manufactor_Code: any}) => {
            extrasSearch.push({
              label:`${element.description} - ${element.manufactor_Code} - ${element.optionTypeId}`,
              value: element.optionTypeId,
            });
            return element;
          });
          vehicle.extraOptionsSearch = extrasSearch;
          vehicle.simulations.forEach((simulation) => {
            const options = commConditionsRelatedOptions.find(x => x.vehicleIndex === index);
            if(options) {
              simulation.fuelOptions = options.fuelOptions;
              simulation.viaVerdeOptions = options.viaVerdeOptions;
              simulation.insurancePackageOptions = options.insurancePackageOptions;
            }
          });
          return vehicle;
        });
        return rows;
      });
    });
  },[proposal, setVehicleDataTable, getCommercialConditionsRelatedOptions, setAnnualKmOptions])

  const { asyncResponse, setAsyncResponse } = useAsyncRequest();

  const syncQuote = useCallback(async (vIndex: number, sIndex: number) => {
    let vehicleList:IVehicle[] = JSON.parse(JSON.stringify(vehicleDataTable));
    proposal.vehicles = vehicleDataTable;
    let proposalRequest: UpsertProposalRequest = {
      proposal: proposal,
      rentalTypeId: "",
      commercialConditionsId: "",
      publish: true,
    };
    if (vehicleList && vehicleList.length > 0) {
      proposalRequest.rentalTypeId = vehicleList[0].typeofRental;
      proposalRequest.commercialConditionsId = vehicleList[0].commercialConditions;
    }

    let upsertResponse = await proposalService.upsert(proposalRequest);
    // let leaseQuoteNo = proposal.leaseQuoteNo;

    if (upsertResponse.succeeded) {
      setProposal(upsertResponse.value);
      let outPutMessage = "";
      let asyncResponseObject: {
        result: string,
        errors: string
      } = { 
        result: '',
        errors: outPutMessage
      }

      let vehicle = vehicleDataTable.find((vehicle, index) => index === vIndex); // estado atual da viatura no porta
      const proposalUpdatedVehicles = upsertResponse.value.vehicles; // lista das viaturas atualizadas via criação/atualização do cabeçalho da proposta
      const updatedVehicle = proposalUpdatedVehicles.find((vehicle: any, index: number) => index === vIndex); // estado atual da viatura após atualização no BC

      if (vehicle && Object.keys(vehicle).length > 0) {
        vehicle.leaseQuoteNo = updatedVehicle.leaseQuoteNo;
        vehicle.id = updatedVehicle.id;
        vehicle.accessories.map((accessory,accessoryIndex) => {
          accessory.id = updatedVehicle.accessories[accessoryIndex].id;
          return accessory
        })

        vehicle.simulations.map((simulation,simulationIndex) => {
          simulation.no = updatedVehicle.simulations[simulationIndex].no;
          simulation.id = updatedVehicle.simulations[simulationIndex].id;
          return simulation;
        })

        await vehicleService.upsertVehicle1(vehicle, vehicle.leaseQuoteNo).then((resp) => {
          let currentVehicleIndex = -1;
          let setVehicleDataTableState = false;

          if (
            resp.value !== null &&
            resp.value !== undefined &&
            Object.keys(resp.value).length > 0
          ) {

            currentVehicleIndex = vIndex; 
            if (
              //resp.succeeded &&
              resp.value !== null &&
              resp.value !== undefined &&
              Object.keys(resp.value).length > 0 &&
              currentVehicleIndex > -1
            ) {
              // careega as opções de selecção referentes a cada simulação para o objecto devolvido após a atualização no BC 
              // para evitar novas chamadas a seviços para recarregar os valores 
              vehicle!.simulations.forEach((simulate,simulationIndex) => {
                resp.value.simulations[simulationIndex].insurancePackageOptions = simulate.insurancePackageOptions;
                resp.value.simulations[simulationIndex].vehicleDamageOptions = simulate.vehicleDamageOptions;
                resp.value.simulations[simulationIndex].occupiersOptions = simulate.occupiersOptions;
                resp.value.simulations[simulationIndex].roadAssistanceOptions = simulate.roadAssistanceOptions;
                resp.value.simulations[simulationIndex].liabilityOptions = simulate.liabilityOptions;
                resp.value.simulations[simulationIndex].isolatedGlassBreakOptions = simulate.isolatedGlassBreakOptions;
                resp.value.simulations[simulationIndex].fuelOptions = simulate.fuelOptions;
                resp.value.simulations[simulationIndex].viaVerdeOptions = simulate.viaVerdeOptions;
                resp.value.simulations[simulationIndex].vehicleTypeOptions = simulate.vehicleTypeOptions;
                resp.value.simulations[simulationIndex].vsAssistanceVehicleOptions = simulate.vsAssistanceVehicleOptions;
                resp.value.simulations[simulationIndex].vsAssistanceVehicleTypeOptions = simulate.vsAssistanceVehicleTypeOptions;
                resp.value.simulations[simulationIndex].vsAccidentVehicleOptions = simulate.vsAccidentVehicleOptions;
                resp.value.simulations[simulationIndex].vsAccidentVehicleTypeOptions = simulate.vsAccidentVehicleTypeOptions;
                resp.value.simulations[simulationIndex].vsTheftVehicleOptions = simulate.vsTheftVehicleOptions;
                resp.value.simulations[simulationIndex].vsTheftVehicleTypeOptions = simulate.vsTheftVehicleTypeOptions;
                resp.value.simulations[simulationIndex].vsOverhaulVehicleOptions = simulate.vsOverhaulVehicleOptions;
                resp.value.simulations[simulationIndex].vsOverhaulVehicleTypeOptions = simulate.vsOverhaulVehicleTypeOptions;
              });  
              
              vehicle!.simulations = resp.value.simulations;

              const simulations = resp.value.simulations;

              // FIXME este código aparenta estar duplicado. validar e se estiver remover 
              for (let pos = 0; pos < vehicle!.simulations.length; pos++ ) {
                for (let j = 0; j < simulations.length; j++) {
                  vehicle!.simulations[j].no = simulations[j].no;
                  vehicle!.simulations[j].id = simulations[j].id;
                }
              }
              setVehicleDataTableState = true;
            }

            if (setVehicleDataTableState) {
              vehicleDataTable[currentVehicleIndex] = vehicle!;
              setVehicleDataTable([...vehicleDataTable]);
            }
          }

          if(!resp.succeeded) {
            
            let errorDetails = "";
            const message = resp.message ? resp.message : "";
            if (resp.errors.length > 0) {
              errorDetails += "<ul>";
              if (currentVehicleIndex > -1)
                errorDetails +=
                  "<b>" +
                  vehicleDataTable[currentVehicleIndex].versionDescription +
                  "</b>: " +
                  message;
              resp.errors.forEach(
                (error) => (errorDetails += "<li>" + error + "</li>")
              );
              errorDetails += "</ul>";
            }
            if(errorDetails.length > 0 )
              outPutMessage += errorDetails;
            else if(message.length > 0)
              outPutMessage += message;
            else
              outPutMessage += "Ocorreu um erro.";
          }
          return resp;
        }).finally(()=>{

          asyncResponseObject = { 
            errors: outPutMessage,
            result:`proposal/edit/${proposal.id}`
          }
          setAsyncResponse(asyncResponseObject)

          if (outPutMessage.length > 0) {
            openAlert({
              variant: "error",
              text: outPutMessage,
              title: "Atenção",
              autoHideDuration: 3000,
            });
          } else {
            // setAsyncResponse(`/proposal/edit/${proposal.id}`);
            openAlert({
              variant: "success",
              text: "Calculo efetuado com sucesso",
              title: "Atenção",
              autoHideDuration: 3000,
            });
          }
        });  
        

      }

    } else {
      let errorDetails = "";
      const message = upsertResponse.message ? upsertResponse.message : "";

      if (upsertResponse.errors.length > 0) {
        errorDetails += "<ul>";
        upsertResponse.errors.forEach(
          (error) => (errorDetails += "<li>" + error + "</li>")
        );
        errorDetails += "</ul>";
      }

      openAlert({
        variant: "error",
        text: message + errorDetails,
        title: "Atenção",
        autoHideDuration: 3000,
      });
    }
  
  },[proposal, setAsyncResponse, vehicleDataTable, setVehicleDataTable])

  const { setRentalTypes, rentalTypesMemo } = useRentalTypesCustomHook();
  const { convertToExpandableMakesOptions,
    setBrandsSectionProps, 
    handleAllBrandsCheckBoxProps, 
    handleVehicleBrandTextFieldPropsCallback, 
    brandsMemo
  } = useVehicleBrandsCustomHook();
  const { handleDriverNameTextFieldPropsCallback, driversMemo} = useDriverNamesCustomHook(); 
  const { durationMemo, setDuration, handleToggleSelectDurationsCallback, setNewMonthValue, newMonthValue } = useDurationCustomHook();  
  const { mileageMemo, setMileage, handleToggleSelectMileageCallback, setNewMileageValue, newMileageValue  } = useDistanceCustomHook();

  //TODO validar a necessidade de utilizar este useEffect
  //MESSAGEM PERSISTENTE DE CRIAÇÃO/ATUALIZAÇÃO CALCULO
  useEffect(() => {  
    // persiste mensagens de calculo para outras páginas ( quer de sucesso ou nao)
    if (asyncResponse) {
      let id = asyncResponse.result.toString().split('/').pop() ;
      // Display the openAlert message with the response data.
      if(asyncResponse.errors !== "") {
        openAlert({
          variant: "error",
          text: asyncResponse.errors,
          title: "Atenção",
          // autoHideDuration: 3000,
          button:{
            props:{
              onClick: handleCardClick(asyncResponse.result)
            },
            text: `Voltar à proposta ${id}.`
          }
        })
      } else {
        openAlert({
          variant: "success",
          text: "Calculo efetuado com sucesso",
          title: "Atenção",
          autoHideDuration: 3000,
          button:{
            props:{
              onClick: handleCardClick(asyncResponse.result)
            },
            text: `Voltar à proposta ${id}.`
          }
        })
      }
      // Reset the asyncResponse in the context to avoid repeated alerts.
    }

     //INICIO SERVIÇOS FILTROS
     vehicleService.getBrands().then((data) => {
      const items = convertToExpandableMakesOptions(data);
      setBrandsSectionProps(items);
    });
  }, [ 
    asyncResponse, handleCardClick,
    setBrandsSectionProps, 
    convertToExpandableMakesOptions,
  ]);


  const handleClearFilters: EditProposalPageProps['filterDrawerProps']['applyButtonProps']['onClick'] = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setRentalTypes((rows) => 
      rows.map(x => {
        x.checked = false;
        return x
      })
    )
    brandsMemo?.map(x => {
      if (x.checkBoxProps.checked === true ) {
        x.checkBoxProps.checked = false;
        x.models.map(model => {
          if (model.checkBoxProps.checked === true) {
            model.checkBoxProps.checked = false;
          }
          return model;
        })
      }
      return x;
    });
    driversMemo?.map((x:any) => x.checked = false );
    durationMemo?.map((x:any) => x.checked = false );
    mileageMemo?.map((x:any) => x.checked = false );

    setSearchResults([]);
    // Código para resetar as propostas. Removido para limitar chamadas à API e subsquentes renderizações
    // Ativar se necessário
    
    // let dataFilters: { type: AdvancedFilterProposalOptions; value: any[] }[] = [];
    // dataFilters.push({
    //   type: AdvancedFilterProposalOptions.RentalType,
    //   value: rentalTypesMemo?.filter(x => x.checked === true) ?? [],
    // });
    // dataFilters.push({
    //   type: AdvancedFilterProposalOptions.ContractNumber,
    //   value: [],
    // });
    // dataFilters.push({
    //   type: AdvancedFilterProposalOptions.LicencePlate,
    //   value: licensePlatesMemo?.filter(x => x.checked === true) ?? [],
    // });
    // dataFilters.push({
    //   type: AdvancedFilterProposalOptions.VehicleBrand,
    //   value: brandsMemo?.filter(x => x.checkBoxProps.checked === true && x.models?.filter(model => model.checkBoxProps.checked === true)) ?? [],
    // });
    // dataFilters.push({
    //   type: AdvancedFilterProposalOptions.ClientName,
    //   value: clientNameMemo?.filter(x => x.checked === true) ?? [],
    // });
    // dataFilters.push({
    //   type: AdvancedFilterProposalOptions.Date,
    //   value: [],
    // });
    // dataFilters.push({
    //   type: AdvancedFilterProposalOptions.DriverName,
    //   value: driversMemo.filter((x :any)=> x.checked === true) ?? [],
    // });
    // dataFilters.push({
    //   type: AdvancedFilterProposalOptions.Duration,
    //   value: durationMemo?.filter((x: any) => x.checked) ?? [],
    // });
    // dataFilters.push({
    //   type: AdvancedFilterProposalOptions.Distance,
    //   value: mileageMemo?.filter((x: any) => x.checked) ?? [],
    // });
    // dataFilters.push({
    //   type: AdvancedFilterProposalOptions.SellerName,
    //   value: sellerNameMemo?.filter(x => x.checked === true) ?? [],
    // });
    // dataFilters.push({
    //   type: AdvancedFilterProposalOptions.FrameWorkContractNumber,
    //   value: frameworkContractNumberMemo.filter(x => x.checked === true) ?? [],
    // });
    
    // proposalService.getFilteredProposals(dataFilters).then(data => {
    //   if(data && data.succeeded)
    //   {
    //     handleProposal(data.value)
    //     return data.value;
    //   }
    // })
  },[
    brandsMemo, driversMemo, durationMemo, mileageMemo, 
    setRentalTypes
  ]);

  const handleApplyFilters: EditProposalPageProps['filterDrawerProps']['applyButtonProps']['onClick'] =
  useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let dataFilters: { type: AdvancedFilterProposalOptions; value: any[] }[] = [];

    dataFilters.push({
      type: AdvancedFilterProposalOptions.RentalType,
      value: rentalTypesMemo?.filter(x => x.checked === true) ?? [],
    });
    // dataFilters.push({
    //   type: AdvancedFilterProposalOptions.VehicleBrand,
    //   value: brandsMemo?.filter(x => x.checkBoxProps.checked === true && x.models?.filter(model => model.checkBoxProps.checked === true)) ?? [],
    // });
    // dataFilters.push({
    //   type: AdvancedFilterProposalOptions.VehicleBrand,
    //   value: brandsMemo?.filter(x => x.checkBoxProps.checked === false && x.models?.filter(model => model.checkBoxProps.checked === true)) ?? [],
    // });

    dataFilters.push({
      type: AdvancedFilterProposalOptions.VehicleBrand,
      value: brandsMemo?.filter(x => x.checkBoxProps.checked === true || x.models?.filter(model => model.checkBoxProps.checked === true)) ?? [],
    });

    dataFilters.push({
      type: AdvancedFilterProposalOptions.DriverName,
      value: driversMemo.filter((x :any)=> x.checked === true) ?? [],
    });
    dataFilters.push({
      type: AdvancedFilterProposalOptions.Duration,
      value: durationMemo?.filter((x: any) => x.checked) ?? [],
    });
    dataFilters.push({
      type: AdvancedFilterProposalOptions.Distance,
      value: mileageMemo?.filter((x: any) => x.checked) ?? [],
    });

    let filteredVehicles: IVehicle[] = [...vehicleDataTable];
    
    dataFilters.forEach((filter) => {
      switch (filter.type) {
        case AdvancedFilterProposalOptions.RentalType:
          if(filter.value.length > 0) { 
              filteredVehicles = [...filteredVehicles.filter(vehicle => {
                return filter.value.some((rentalType) => rentalType.label === vehicle.typeofRental)
              })];
          }
          break;
        case AdvancedFilterProposalOptions.VehicleBrand:
          if(filter.value.length > 0) { 
            filteredVehicles = [...filteredVehicles.filter(vehicle => {
              return filter.value.some((brand) =>
                (brand.checkBoxProps.checked === true &&  
                brand.label === vehicle.makeId) ||
                (brand.models.some((model: any) => model.checkBoxProps.checked === true && model.id === vehicle.modelId))
              )
            })];
          }
          break;
        case AdvancedFilterProposalOptions.DriverName:
          if(filter.value.length > 0) {
            filteredVehicles = [...filteredVehicles.filter(vehicle => {
              return filter.value.some((driver) => driver.label === vehicle.driverName)
            })];
          }
          break;
        case AdvancedFilterProposalOptions.Duration:
          if(filter.value.length > 0) { 
            filteredVehicles = [...filteredVehicles.filter(vehicle => {
              return filter.value.some((duration) => duration.label === vehicle.duration)
            })];
          }
          break;
        case AdvancedFilterProposalOptions.Distance:
          if(filter.value.length > 0) {
            filteredVehicles = [...filteredVehicles.filter(vehicle => {
              return filter.value.some((distance) => distance.label === vehicle.mileage)
            })];
          }
          break;
      
        default:
          break;
      }
    })
    if(filteredVehicles.length === 0) {
      openAlert({
        variant: "info",
        text: "Não foram encontradas viaturas com os critérios selecionados.",
        title: "Atenção",
        autoHideDuration: 3000,
      });
    } else {
      setClearSearch(false);
      const mockEvent: React.MouseEvent<HTMLButtonElement> = {} as React.MouseEvent<HTMLButtonElement>;
      handleClearFilters(mockEvent)
      setSearchResults(filteredVehicles);
    }
  },[ brandsMemo, driversMemo,  
    rentalTypesMemo, durationMemo, 
    // setDuration,
    mileageMemo,
    // setMileage, 
    vehicleDataTable, handleClearFilters
  ]);

  const handleErase = useCallback (() => {
    setSearchResults([]);
    setSearchValue('');
    setClearSearch(true);
  },[])

  const handleStateChange = useCallback((popoverOptions: string) => {
    setProposal((prev) => {
      let value = ''
      switch (popoverOptions) {
        case 'Na Comercial':
            value = 'Open';
          break;
        case 'No Procurement':
            value = 'On Procurement';
          break;
        case 'Na Aprovação Comercial':
            value = 'In Comercial Approving';
          break;
        case 'No Cliente':
            value = 'On Customer';
          break;
        case 'Fechado':
            value = 'Cancelled';
          break;
        case 'Na Análise de Risco':
            value = 'On Risk Analysis';
          break;
        default:
          break;
      }
      let updatedStatus = { ...prev, state: value }
      // atualiza quote header no bc e na api
      let proposalRequest: UpsertProposalRequest = {
        proposal: updatedStatus,
        rentalTypeId: "",
        commercialConditionsId: "",
        publish: true,
      };
      if (updatedStatus.vehicles && updatedStatus.vehicles.length > 0) {
        proposalRequest.rentalTypeId = updatedStatus.vehicles[0].typeofRental;
        proposalRequest.commercialConditionsId = updatedStatus.vehicles[0].commercialConditions;
      }
      proposalService.upsert(proposalRequest).then(data => {
        if (data.succeeded) {
          setProposal(data.value);
          openAlert({
            variant: "success",
            text: "Estado da proposta atualizado com sucesso.",
            title: "Atenção",
            autoHideDuration: 3000,
          });
          prev = {...updatedStatus};
        } else {
          openAlert({
            variant: "error",
            text: `${data.message} A proposta deve estar no estado 'Na Comercial' antes de ser mudada para outro estado.`,
            title: "Atenção",
          });
        }
      });

      return prev;
    });

  },[])
  const { proposalStateOptions, handleState } = useProposalStatesHook(proposal.state, proposal.description);
  
  return (
    <>
    <EditProposalPage 
      topBarProps={ topBarPropsMemo }
      dashboardMenuButtonProps={ dashboardMenuButtonPropsMemo }
      proposalMenuButtonProps={ proposalMenuButtonPropsMemo }
      clientsMenuButtonProps={ clientsMenuButtonPropsMemo }
      contractsMenuButtonProps={ contractsMenuButtonPropsMemo }
      performanceMenuButtonProps={ performanceMenuButtonPropsMemo}
      backofficeMenuButtonProps={ backofficeMenuButtonPropsMemo }

      breadCrumbsLinks={[    
        {
          label: 'Propostas',
          onClick: handleCardClick('proposal'),
        },  
        { 
          label: `Proposta Nº:${proposal.leaseQuoteNo}`
        }
      ]}
      closeExpandableText="fechar detalhes"
      editButtonText="editar"
    
      editDetailsButtonText = "editar"
      handleSelected = {(selected)=> setContractSelectedRows(selected)}

      // cabeçalho da página
      proposalInfo={{
        state: {
          tagColor: (handleState(proposal.state, proposal.description).color as 'error' | 'success' | 'warning') ,
          tagLabel: handleState(proposal.state, proposal.description).tagLabel,
          value: handleState(proposal.state, proposal.description).description
        },
        popoverOptions: proposalStateOptions,
        onPopoverOptionsClick: handleStateChange,

        rentalType: {
          label: 'Tipo de aluguer',
          value: vehicleDataTable?.length > 0 ? vehicleDataTable[0].typeofRental : '' 
        },
        commercialConditions: {
          label: 'Condições comerciais',
          value: vehicleDataTable?.length > 0 ? 
            [vehicleDataTable[0]?.commercialConditions, vehicleDataTable[1]?.commercialConditions].join(' e ') :
            [].join(' e '),
          // value: [proposal?.vehicles[0].commercialConditions, proposal?.vehicles[1].commercialConditions].join(' e '),
        },
        clientType: settings.Data.Vehicles_ClientServicesTypology[1].results.find((x) => { return x.value.toLowerCase() === proposal.clientType.toLowerCase() })?.label
          ? settings.Data.Vehicles_ClientServicesTypology[1].results.find((x) => { return x.value.toLowerCase() === proposal.clientType.toLowerCase() })!.label
          : proposal.clientType, 
          
        // Empresa / Particular / ENI
        //'Grandes empresas - Frota > 30 veículos',
        inputChannel: {
          label: 'Canal de entrada', 
          value: settings.Data.Vehicles_InputChannel.find((x) => { return x.value === proposal.inputChannel })?.label !== (null || undefined)
            ? settings.Data.Vehicles_InputChannel.find((x) => { return x.value === proposal.inputChannel })!.label
            : 'N/A', 
        },

        // deve refletir o total por simulação e não por viatura por forma a atualizar o valor quando é alterada uma quantidade nesta página
        // `${vehicleDataTable.reduce((acc, cur) => acc + cur.quantity, 0).toString()} veículos.` :
        underNegotiation: { 
          label: 'Em negociação', 
          value: vehicleDataTable?.length > 0 ? 
            `${vehicleDataTable.reduce((acc, cur) => acc + cur.simulations.reduce((acc2, sim) => acc2 + sim.quantity, 0), 0).toString()} veículos` :
            '0 veículos.'
        },
        expiryDate: {
          // mapear com as datas da proposta
          label: 'Validade',
          from: vehicleDataTable.length > 0 ? `Criada a ${formatDateWithTimeZone(vehicleDataTable[0].createdOn, timeZone)}`  : '',
          to: vehicleDataTable.length > 0 ? `VÁLIDA até ${formatDateWithTimeZone(vehicleDataTable[0].createdOn, timeZone, "years", 1)}`  : '',
        },
        Responsible: { label: 'Responsável', value: proposal.salesPersonName }
      }}
      proposalNumber={ proposal.leaseQuoteNo }
      proposalText="Proposta Nº:"
      contractNumber="Contrato Nº CA10485074"
      // DADOS DO REQUERENTE
      requesterInfo={{
        showEdit: false, // ocultar secção
        showAbout: false, // ocultar secção
        // cabeçalho dos dados do requerente
        requester: {
          label: 'Requerente',
          value: proposal.clientName,
          id: `Cliente Nº ${proposal.contactId}`,
        },
        contactName: { 
          label: 'Nome do contacto', 
          value:  proposalContactName,// proposalContact,
        },
        dateTime: { //Não está mapeado correctamente. necessário validar com a hi-interactive
          label: 'Data e hora do pedido',
          value: vehicleDataTable.length ? `${formatDateWithTimeZone(vehicleDataTable[0].createdOn, timeZone)}` : '', // '17 jun. 2020 às 12:54',
        },


        // PARTE A OCULTAR
        editText: 'Editar',
        onEditClick: () => {
          // fazer get ao cliente para saber qual o id 
          // ou então redireccionar com base no contactId da proposta
          //navigate(`/clients/${proposal.contactId}`);
          alert('Funcionalidade não implementada.');
          // se for para redireccionar para a pagina do cliente conforme aparenta ser o caso
          // utilizar o onEditClick comentado em deterimento deste
        },
        // onEditClick: handleCardClick(`clients/${proposal.contactId}`),

        aboutText: 'Sobre o Cliente',
        warning: true,
        warningText: '2 contractos a terminar',
        expandText: 'Abrir todos os detalhes',
        collapseText: 'Fechar detalhes',

        // detalhes do requerente - é necessário validar como é que isto vai ser mapeado no futuro
        clientDetailsArea: {
          clientVehiclesFleet: {
            value: '40',
            label: 'Viaturas na frota do Cliente',
          },
          contractedVehiclesVsTotalFleet: {
            value: '35%',
            label: 'Viaturas contratadas vs total da frota',
          },
          inProposalArea: {
            title: 'Em proposta',
            vehiclesNumber: {
              label: 'Nº de viaturas',
              value: '5',
            },
            averageValueNumber: {
              label: 'Valor médio das viaturas',
              value: '33.436,87 €',
            },
          },
          contractedArea: {
            title: 'Contratado',
            vehiclesNumber: {
              label: 'Nº de viaturas',
              value: '6',
            },
            averageValueNumber: {
              label: 'Valor médio das viaturas',
              value: '63.436,56 €',
            },
          },
          rentabilityArea: {
            title: 'Rentabilidade',
            totalValueContracted: {
              label: 'Valor total contratado',
              value: '250.436,87 €',
            },
            debtValue: {
              label: 'Valor em dívida',
              value: '11.436,87 €',
            },
          },
        },
        
      }}

      // input de parametros de pesquisa
      vehicleSearchResultProps={{
        options: searchOptions,
        label: 'Pesquise por CA ou veículo',
        placeholder: 'Pesquisar',
        value: searchValue,
        onInputChange: handleOnInputSearchOptions,
        freeSolo: true,
        disableClearable: false
        
      }}
      // botão para efetuar pesquisa
      searchButtonProps={{
        onClick: handleSelectOption
      }}
      searchButtonText="Pesquisar"

      // botão para limpar parametros de pesquisa
      eraseLinkText="limpar"
      eraseLinkProps={{ 
        onClick: handleErase,
        disabled: clearSearch
      }}

      // TODO VALIDAR ALTERAÇÕES NAS DUAS PROPS SEGUINTES

      // botão/modal de filtros avançados
      filterDrawerProps={{
        headerTitle: 'Filtros Avançados',
       
        rentTypeSectionProps: {
          title: 'Tipo de Aluguer',
          rentTypeCheckboxPropsArray: rentalTypesMemo, 
          // rentTypeCheckboxPropsArray: handleRentType(), 
        },
        vehicleBrandSectionProps: {
          title: 'Marca da viatura',
          vehicleBrandTextFieldProps: {
            onChange: handleVehicleBrandTextFieldPropsCallback
          },
          // onAllBrandsClick: () => alert('todas as marcas'),
          allBrandsCheckBoxProps: {
            onClick: handleAllBrandsCheckBoxProps
          },
          allBrandsText: 'Todas',
          brands: brandsMemo, 
          // handleVehicleBrand(),
        },
        driverNameSectionProps: {
          title: 'Nome do Condutor',
          driverNameTextFieldProps: {
            onChange: handleDriverNameTextFieldPropsCallback
          },
          resultsCheckboxPropsArray: driversMemo,
          // handleDriverName(),
        },
        durationSectionProps: {
          title: 'Duração em meses',
          durationCheckboxPropsArray: durationMemo,
          addValueButtonProps: {
            onClick:() => {
              setDuration(rows => [
                ...rows,
                {
                  label: `${newMonthValue} meses`,
                  checked:false,
                  value: newMonthValue,
                  onClick: handleToggleSelectDurationsCallback(newMonthValue),
                }
              ])
              setNewMonthValue('');
            },
          },
          addValueButtonText: 'Adicionar',
          otherValueTextFieldProps: { 
            label: 'Adicionar outros valores',
            value: newMonthValue,
            onChange: (e) => setNewMonthValue(e.target.value)
          },
        },
        kmSectionProps: {
          title: 'Km por ano',
          kmCheckboxPropsArray: mileageMemo,
          addValueButtonProps: {
            onClick:() => {
              setMileage(rows => [
                ...rows,
                {
                  label: `${newMileageValue} km/ano`,
                  checked:false,
                  value: newMileageValue,
                  onClick: handleToggleSelectMileageCallback(newMileageValue),
                }
              ])
              setNewMileageValue('');
            },
          },
          addValueButtonText: 'Adicionar',
          otherValueTextFieldProps: { 
            label: 'Adicionar outros valores',
            value: newMileageValue,
            onChange: (e) => setNewMileageValue(e.target.value)
          },
        },
        applyButtonText: 'Aplicar',
        applyButtonProps: {
          onClick:handleApplyFilters,
        },
        
        clearAllButtonText: 'Limpar todos os filtros',
        clearAllButtonProps: {
          onClick: handleClearFilters,
        },
        expandAllButtonText: 'Expandir tudo',
        collapseAllButtonText: 'Diminuir tudo',
      }}
      // modal de editar em grupo
      groupEditModalProps={{
        title: "Alterar em grupo para os items selecionados",
        cancelButtonText: "Cancelar",
        handleClose: () => {
          setVehicleGroupEditModalValues({
            incomeWithoutTax: 0,
            incomeWithTax: 0,
            collateral: 0,
            margin: 0,
            increaseVr:0,
            initialEntry: 0,
            expenses: 0,
            marginCheck: false,
            commercialEffortCheck: false,
            vrCheck: false,
            discountWithTax: 0,
            discountWithoutTax: 0,
            discountWithTaxPercentage: 0,
            campaignWithTax: 0,
            campaignWithTaxPercentage: 0,
            campaignWithoutTax: 0,
            rappelWithTax: 0,
            rappelWithTaxPercentage: 0,
            rappelWithoutTax: 0,
            extraRappel: 0,
            lotId: "",
            extraAccessories: [],
          });
        },
        confirmButtonText: "Aplicar",
        confirmButtonProps: {
          onClick: handleCloseGroupEditModal,
        },
        targetIncome: {
          title: "Definir renda target",
          intendedIncome: {
            label: "Renda pretendida sem e com iva",
            withoutTaxTextFieldProps: {
              value: vehicleGroupEditModalValues?.incomeWithoutTax,
              name: "incomeWithoutTax",
              onChange: handleGroupEditModalChange,
              endAdornment: "€",
              disabled: propertyDisabled('Renda sem IVA','Cotação'),
              hidden: propertyHidden('Renda sem IVA','Cotação')
            },
            withTaxTextFieldProps: {
              value: vehicleGroupEditModalValues?.incomeWithTax,
              name: "incomeWithTax",
              onChange: handleGroupEditModalChange,
              endAdornment: "€",
              disabled: propertyDisabled('Renda com IVA','Cotação'),
              hidden: propertyHidden('Renda com IVA','Cotação')
            },
          },
        },
        influencedFields: {
          title: "Definir os campos que a renda target deve influenciar",
          marginCheckboxProps: {
            label: "Margem",
            checked: vehicleGroupEditModalValues?.marginCheck,
            name: "marginCheck",
            onChange: handleGroupEditModalChange,
            disabled: propertyDisabled('Margem','Cotação'),
            hidden: propertyHidden('Margem','Cotação')
          },
          vrCheckboxProps: {
            label: "VR%",
            checked: vehicleGroupEditModalValues?.vrCheck,
            name: "vrCheck",
            onChange: handleGroupEditModalChange,
            disabled: propertyDisabled('Aumento de VR','Cotação'),
            hidden: propertyHidden('Aumento de VR','Cotação')
          },
          //ocultado da modal
          // neste campo não são aplicadas permissões
          commercialEffortCheckboxProps: {
            label: "Esforço Cocmercial",
            checked: vehicleGroupEditModalValues?.commercialEffortCheck,
            name: "commercialEffortCheck",
            onChange: handleGroupEditModalChange,
            disabled: true,
            hidden: true
            // disabled: propertyDisabled(''),
            // hidden: propertyHidden('')
          },  
        },
        changeConfigurations: {
          title: "Alterar a configuração de:",
          vrTextFieldProps: {
            label: 'VR',
            value: vehicleGroupEditModalValues?.increaseVr,
            name: "increaseVr",
            onChange: handleGroupEditModalChange,
            endAdornment: "%",
            disabled: propertyDisabled('Aumento de VR','Cotação'),
            hidden: propertyHidden('Aumento de VR','Cotação')
          },
          marginTextFieldProps: {
            label: "%Margem",
            value: vehicleGroupEditModalValues?.margin,
            name: "margin",
            onChange: handleGroupEditModalChange,
            endAdornment: "%",
            disabled: propertyDisabled('Margem','Cotação'),
            hidden: propertyHidden('Margem','Cotação')
          },
          initialEntryTextFieldProps: {
            label: "Entrada inicial",
            value: vehicleGroupEditModalValues?.initialEntry,
            name: "initialEntry",
            onChange: handleGroupEditModalChange,
            endAdornment: "€",
            disabled: propertyDisabled('Entrada Inicial s/IVA','Cotação'),
            hidden: propertyHidden('Entrada Inicial s/IVA','Cotação')
          },
          collateralTextFieldProps: {
            label: "Caução",
            value: vehicleGroupEditModalValues?.collateral,
            name: "collateral",
            onChange: handleGroupEditModalChange,
            endAdornment: "€",
            disabled: propertyDisabled('Caução','Cotação'),
            hidden: propertyHidden('Caução','Cotação')
          },
          contractExpensesTextFieldProps: {
            label: "Despesas de contrato",
            value: vehicleGroupEditModalValues?.expenses,
            name: "expenses",
            onChange: handleGroupEditModalChange,
            endAdornment: "€",
            // disabled: propertyDisabled(''),
            // hidden: propertyHidden('')
          },
        },
        lot: {
          title: "Associar lote",
          lotTextFieldProps: {
            label: "Nome Identificativo do lote",
            placeholder: "Inserir nome do lote",
            value: vehicleGroupEditModalValues?.lotId,
            name: "lotId",
            onChange: handleGroupEditModalChange,
          },
        },
      }}
      // modal de aprovação
      approvalModalProps={{
        variant: 'approve',
        feedbackText: 'Qual o feedback ao pedido enviado?',
        approve: { label: 'Approvado', value: '' },
        reject: { label: 'Recusado', value: '' },
        needsAdjustment: { label: 'Precisa de ajuste', value: '' },
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'ENVIAR PEDIDO',
        confirmButtonProps: { onClick: () => alert('confirmar') },
        seeProposalsButtonText: 'Ver propostas',
        seeProposalsButtonProps: { onClick: () => alert('ver propostas') },
        title: 'Pedir validação',
        subTitle:
          'Enviar pedido de aprovação para as seguintes propostas comerciais:',
        reasonSearchResultProps: {
          label: 'Motivo de pedido de aprovação',
          value: [
            { label: 'Valor Residual', value: 'vr' },
            { label: 'Rappel Extra', value: 're' },
          ],
          options: [
            { label: 'Valor Residual', value: 'vr' },
            { label: 'Rappel Extra', value: 're' },
            { label: 'Prazo (12 meses)', value: 'p12' },
            { label: 'Prazo de pagamento', value: 'pp' },
            { label: 'Seguro (franquia zero)', value: 'sf0' },
            { label: 'Valor inferior a 100K', value: 'vi100' },
            { label: 'Outros', value: 'other' },
          ],
          placeholder: 'Escolher motivo de recusa',
        },
        proposalVersionText: 'Versão 2 da proposta',
        proposalClientText: 'EGEO Pressão, S.A.',
        proposalNumberText: 'Proposta Nº C010089803',
        state: { tagColor: 'warning', tagLabel: 'Pendente' },
        businessPrice: '25.160,32€',
        seeAllNotesText: 'Ver todas as notas',
        notes: [
          {
            avatarSrc: AccountIcon,
            name: 'Ana Antunes',
            text: `Conceitos <em><strong>importantes</strong></em> a rever: <li>Item 1</li><li>Item 2</li>
            <li>Item 1</li><li>Item 2</li><li>Item 1</li><li>Item 2</li><li>Item 1</li><li>Item 2</li>
            <li>Item 1</li><li>Item 2</li><li>Item 1</li><li>Item 2</li><li>Item 1</li><li>Item 2</li>
            <li>Item 1</li><li>Item 2</li><li>Item 1</li><li>Item 2</li><li>Item 1</li><li>Item 2</li>`,
            date: `Ontem às 16:32`,
            dateValue: new Date('2022-09-01T16:32:00'),
          },
          {
            avatarSrc: 'assets/UserImage.png',
            name: 'Luís Brazão',
            text: `Boa tarde Pedro,<br/><br/> praesent faucibus odio vitae ornare ullamcorper.
          Praesent ultrices risus sit amet enim ultrices bibendum. Faucibus
          odio vitae ornare ullamcorper. Praesent ultrices risus sit amet
          enim ultrices bibendum.<br/>Boa tarde Pedro, praesent faucibus odio vitae ornare ullamcorper.
          Praesent ultrices risus sit amet enim ultrices bibendum. Faucibus
          odio vitae ornare ullamcorper. Praesent ultrices risus sit amet
          enim ultrices bibendum. Faucibus
          odio vitae ornare ullamcorper. Praesent ultrices risus sit amet
          enim ultrices bibendum.`,
            date: `Ontem às 17:32`,
            dateValue: new Date('2022-09-01T17:32:00'),
            files: [
              {
                file: new File([], 'nomedoficheiro.pdf'),
                onClick: () => alert('do something with this file'),
              },
              { file: new File([], 'test.txt') },
            ],
          },
        ].sort((a, b) => {
          if (a.dateValue > b.dateValue) return -1;
          if (a.dateValue < b.dateValue) return 1;
          return 0;
        }),
        newNotePlaceholder: 'Insira aqui a mensagem.',
        addNoteText: 'Comentários',
        newNote: newNote,
        onNewNoteChange: handleNewNoteChange,
        onAttachFileChange: (e) => {
          if (e.target.files)
            setFilesProgressBarProps([
              {
                progress: 50,
                title: e.target.files[0].name,
                deleteIcon: true,
                deleteTooltipText: 'Cancelar',
                onDeleteClick: () => alert('remover/cancelar este ficheiro'),
                size: 'small',
                label: '2.3MB/8.4MB',
              },
            ]);
        },
        attachFileText: 'Anexar ficheiro',
        filesTypes:
          'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx. O tamanho máximo dos ficheiros é 2 MB.',
        filesProgressBarProps: filesProgressBarProps,
      }}
      // FIM TODO
      
 

      // campos de ordenação
      orderByDropDownText="Ordenar por: "
      orderByDropDownOptions={{
        brandLabel: 'Marca',
        modelLabel: 'Modelo',
        versionLabel: 'Versão',
        MKmLabel: 'Meses/Km',
        segmentLabel: 'Segmento',
        statusLabel: 'Estado',
        lotLabel: 'Lote',
      }}
      // botão da modal de editar em grupo
      groupEditButtonText="Editar em grupo"
      groupEditButtonProps={{
        disabled: contractSelectedRows.length === 0 || propertyDisabled('Propostas'),
        hidden: propertyHidden('Propostas')
      }}
      // botão para pedido de aprovação
      requestValidationButtonText="Pedir validação"
      requestValidationButtonProps={{
        // validar quais as outras condições que precisam de ser validadas para garantir o funcionamento correto do botão 
        disabled: contractSelectedRows.length === 0 || propertyDisabled('Propostas'),
        hidden: propertyHidden('Propostas')
      }}
      // botão de acções extra
      actions={[
        {
          label: 'Exportar',
        },
        {
          label: 'Enviar ao cliente',
        },
        {
          label: 'Comentários',
        },
        {
          label: 'Ver histórico',
        },
        {
          label: 'Eliminar viaturas',
        }
      ]}


      // lista das viaturas da proposta
      headCells={[
        { label: 'Simulação' },
        { label: 'Qtd.' },
        { label: 'Viatura' },
        { label: 'Prazo' },
        { label: 'km' },
        { label: 'P. Proforma' },
        { label: 'V.Negócio c/IVA' },
        { label: 'VR Negócio C/IVA' },
        { label: 'Aumento VR' },
        { label: 'VR Tabela C/IVA' },
        { label: 'VR Eurotax Original C/IVA' },
        { label: 'Rappel' },
        { label: '%Margem' },
        { label: 'Estado' },
        { label: 'Renda sem e com IVA' },
      ]}
      editProposalTableData = {vehiclesMemo}
      // botão para guardar a viatura atualizada
      saveEditDetailsButtonText={'Guardar alterações'}
      saveEditDetailsButtonClick={(vehicleIndex, simulationIndex) => syncQuote(vehicleIndex, simulationIndex)}

      // botão para adicionar viatura
      addVehicleButtonText="Adicionar veículo"
      addVehicleButtonProps={{
         onClick: handleAddVehicleClick(Number(params.proposalId)),
      }}

      // modais de ações extra

      exportSimulationsModalProps={{
        onLoad: loadSelectedSimulations,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Exportar',
        confirmButtonProps: { onClick: () => {
          try {
            const proposalCopy = { ...proposal }; 
            proposalCopy.vehicles = proposalCopy.vehicles.reduce((acc: typeof proposal.vehicles, vehicle, vehicleIndex) => {
              const selectedRows = contractSelectedRows.filter(row => row.vehicleIndex === vehicleIndex);
              if (selectedRows.length > 0) {
                vehicle.simulations = vehicle.simulations.filter((simulation, simulationIndex) =>
                  selectedRows.some(row => row.simulationIndex === simulationIndex)
                );
                acc.push(vehicle);
              }
              return acc;
            }, []);
            const leaseQuoteNos:string[] = [];
            const simulationIds:string[] = [];
            proposalCopy.vehicles.forEach(row => {
              leaseQuoteNos.push(row.leaseQuoteNo);
              row.simulations.forEach(sim => simulationIds.push(sim.no));
            });
                          
            const exportFormat = convertStringToEnum(option);
            if(exportFormat === undefined){
              openAlert({
                variant: "error",
                text: "Formato de exportação inválido.",
                title: "Atenção",
              });
              return;
            }
            proposalService.exportSimulations(leaseQuoteNos, simulationIds, exportFormat).then(response => { 
              if(response.succeeded){
                downloadFile(response.value.fileContent, response.value.fileName, exportFormat);
              }
              else{
                openAlert({
                  variant: "error",
                  text: "Não foi possivel exportar o ficheiro",
                  title: "Atenção",
                });
              }
            });
            console.log(contractSelectedRows);
          } catch (error) {
            openAlert({
              variant: "error",
              text: "Não foi possivel exportar o ficheiro",
              title: "Atenção",
            });
            console.log(error);
          }
        } },
        eraseLinkText: 'Limpar',
        eraseLinkProps: {
          onClick: onExportEraseLink_Click,
          disabled: clearExportSearch,
        },
        headCells: [
          {
            label: 'Simulação',
            id: 'sim',
          },
          {
            label: 'Viatura',
            id: 'v',
          },
          {
            label: 'Valor residual',
            id: 'vr',
          },
          {
            label: 'Estado',
            id: 'status',
          },
          {
            label: 'Preço cliente',
            id: 'cp',
          },
        ],

        // radio button
        exportOptions: [
          {
            icon: DocumentPDFIcon,
            label: 'Exportar para PDF',
            value: ExportFormat.PDF,
          },

          // {
          //   icon: DocumentAndImageIcon,
          //   label: 'Exportar para Excel',
          //   value: ExportFormat.Excel,
          // },
        ],
        exportRadioGroupProps: {
          value: option,
          // hidden: true,
          onChange: handleExportOptionChange,
        },

        // checkboxes
        handleSelectedOption: (selected) =>
          alert('Opção selecionada => ' + selected),

        orderByDropDownOptions: {
          brandLabel: 'Marca',
          lotLabel: 'Lote',
          modelLabel: 'Modelo',
          segmentLabel: 'Segmento',
          statusLabel: 'Estado',
          versionLabel: 'Versão',
        },
        orderByDropDownText: 'Ordenar por: ',
        searchButtonProps: { 
          onClick: handleExportfilterSelectOption
        },
        searchButtonText: 'Pesquisar',
        simulationSearchResultProps: {
          label: 'Pesquisar por CA ou veículo',
         
          options:  exportSearchOptions,
          onInputChange: handleOnInputExportSearchOptions,

          value: exportSearchValue,
          freeSolo: true,
          disableClearable: false,
          placeholder: 'Pesquisar',
        },
        simulationTableData: exportSearchResults,
        title: 'Exportar',
      }}
      shareModalProps={{
        filesProgressBarProps: [
          {
            progress: 50,
            title: 'nomedoficheiro.pdf',
            deleteIcon: true,
            label: '2.3MB/8.4MB',
            deleteTooltipText: 'Apagar',
            onDeleteClick: () => alert('remover'),
          },
        ],
        handleClose: () => alert(''),
        companyIcon: DocumentIcon,
        title: 'Partilhar link',
        numberOfProposal: '08',
        descOfProposal: 'Propostas a enviar ao cliente',
        numberOfCalc: '25',
        descOfCalc: 'Calculos criados',
        email: {
          label: 'Email para envio',
          defaultValue: 'ri.miranda@egeo.pt',
        },
        ccTextFieldProps: {
          label: 'CC',
          defaultValue: 'cc@egeo.pt',
        },
        bccTextFieldProps: {
          label: 'Bcc',
          defaultValue: 'bcc@egeo.pt',
        },
        comments: {
          label: 'Adicionar comentário',
          placeholder: 'Incluir aqui os comentários',
        },
        attachOnClick: () => alert('Anexar ficheiro'),
        copyOnClick: () => alert('Copiar Link'),
        copyLinkText: 'Copiar Link',
        attachFileText: 'Anexar ficheiro',
        seeAllListText: 'Ver toda a lista',
        onSeeAllList: () => alert('Ver toda a lista!'),
        filesTypes:
          'Tipos de ficheiros permitidos: jpg, jpeg, png, doc, pdf, gif, zip, rar, txt, xls, docx, xlsx. O tamanho máximo dos ficheiros é 2 MB',
        cancelButtonText: 'Cancelar',
        cancelButtonProps: {
          onClick: () => alert('Cancelar'),
        },
        sendButtonText: 'Partilhar',
        sendButtonProps: {
          onClick: () => alert('Partilhar'),
        },
        editCheckboxProps: { defaultChecked: true, label: 'Permitir edição' },
        ccText: 'CC',
        bccText: 'BCC',
      }}
    /><Alert open={open} onClose={handleClose} {...alertProps} />
    </>
  );
};

export default React.memo(EditProposalPageDemo);