import { Workbox } from 'workbox-window';

export function register(config?: any) {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox(`${process.env.PUBLIC_URL}/service-worker.js`);

    wb.addEventListener('waiting', () => {
      if (config && config.onUpdate) {
        config.onUpdate(wb);
      }
    });

    wb.addEventListener('controlling', () => {
      window.location.reload();
    });
    
    wb.register();
  }
}