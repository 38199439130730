import {
  //AccountIcon,
  AddSmallIcon,
  Alert,
  AlertProps,
  BackofficePreconfiguredVehiclesPage,
  ButtonProps,
  // ProgressBarProps,
  SearchResultProps,
  // TechnicalCharacteristics,
  TextFieldProps,
  VehicleDetailsProps,
  //VehiclesIcon,
} from '@hi-interactive-admin/simulador-kinto';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
//import { vehicleDetails } from './vehicleDetails';
import useNavbarHook from '../../customCallbacks/navbarHook';
import { KintoRootProps } from '../../model/KintoComponentProps';
//import { set } from 'date-fns';
import { VehicleService } from '../../services/VehicleService';
import { APIResponse } from '../../model/APIResponse';
import { Option } from "../../model/interfaces";
import { IModel, IVersion } from '../../model/Vehicle';
import { AdvancedFilterVersionsOptions, PreConfiguredVehicleState, VehicleTypeStatus, VehicleTypeStatusHelper } from '../../model/Enums';
import { convertNumber, validaStringFormatDecimal } from '../../Utilities/Utils';
import usePermissionsHook from '../../customCallbacks/permissions/permissionsHook';
import useAdvVehicleFilterHook from '../../customCallbacks/advVehicleFilterHook';
import { IAccessory } from '../../model/Accessory';
import { IPreConfiguredExtraStandard, IPreConfiguredVehicle } from '../../model/PreConfiguredVehicle';
import { AppSettings, EmptyAppSettings } from '../../model/Settings';
import { SettingsService } from '../../services/SettingsService';
import useVehicleGroupEditModalHook from '../../customCallbacks/vehicleGroupEditModalHook';
import { ProformaFile } from '@hi-interactive-admin/simulador-kinto/pages/proposal/modals/DownloadProformaModal';
import { FunctionalGroupsVersionType, FunctionalGroupVehicle } from '../../model/Profile';
import { ProfileServices } from '../../services/ProfileServices';

const vehiclesService = new VehicleService();
const profileService = new ProfileServices();

const BackofficePreconfigured : React.FC<KintoRootProps> = ({ onLogout }) =>{
  const [settings, setSettings] = useState<AppSettings>(new EmptyAppSettings());
  const {
    currentUser, 
    setCurrentUser, 
    propertyDisabled, 
    propertyHidden, 
    allowedValues
  } = usePermissionsHook();
  const { 
    //navigate,
    handleCardClick, 
    //handleLogout,
    topBarPropsMemo,
    dashboardMenuButtonPropsMemo, 
    proposalMenuButtonPropsMemo, 
    clientsMenuButtonPropsMemo, 
    contractsMenuButtonPropsMemo,
    performanceMenuButtonPropsMemo, 
    backofficeMenuButtonPropsMemo
  } = useNavbarHook(onLogout);
  const [open, setOpen] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<Omit<AlertProps, "open" | "onClose">>({
    variant: "info",
    text: "",
    title: "",
    autoHideDuration: 3000,
  });
  const openAlert = (alertProps: Omit<AlertProps, "open" | "onClose">) => {
    setAlertProps(alertProps);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSetBrand = (makesSearchOptionsTmp: any[]) => {
    return makesSearchOptionsTmp.map((item: { label: string }) => {
      return {
        label: item.label,
        value: item.label,
        onChange:()=>{alert('Funcionalidade não implementada');}
      };
    });
  };
  //const [settings, setSettings] = useState<AppSettings>(new EmptyAppSettings());
  const [vehicles, setVehicles] = useState<IPreConfiguredVehicle[]>([]);
  const [makesSearchOptions, setMakesSearchOptions] = useState<any[]>([]);
  const brandOptions = useMemo(() => handleSetBrand(makesSearchOptions),[makesSearchOptions]);
  
  const [versionsSearchOptions, setVersionsSearchOptions] = useState<IVersion[]>([{
    makeId: "",
    modelId:"",
    versions: [{ label: "", value: "", isInactive: false}],
  }]);

  const [modelSearchOptions, setModelsSearchOptions] = useState<IModel[]>([{
    makeId: "",
    models: [{ label: "", value: "" }],
  }]);

  const [vehicleSearchOptions, setVehicleSearchOptions] = useState<Option[]>([]);
  //const [brandsSectionProps, setBrandsSectionProps] = useState<any>([]);
  const [brandInput, setBrandInput] = useState<Option>({
    value: "",
    label: "",
  });
  const [toggleCollapseTechnicalCharacteristics, setToggleCollapseTechnicalCharacteristics] = useState<boolean>(true);
  const [toggleCollapseSpaceAndDimensions, setToggleCollapseSpaceAndDimensions] = useState<boolean>(true);
  const [toggleCollapseEngineSpecifications, setToggleCollapseEngineSpecifications] = useState<boolean>(true);
  const [vendors, setVendors] = useState<Option[]>([]);
  const [resetSearchDisabled, setResetSearchDisabled] = useState<boolean>(true);

  //FUNCTIONAL GROUPS VEHICLES DATA - START
  const [isFilterByFunctionalGroups, setIsFilterByFunctionalGroups] = useState<boolean>(false);
  const [functionalGroupsMakes, setFunctionalGroupsMakes] = useState<string[]>([]);
  const [functionalGroupsModels, setFunctionalGroupsModels] = useState<string[]>([]);
  const [functionalGroupsVersionTypes, setFunctionalGroupsVersionTypes] = useState<FunctionalGroupsVersionType[]>([]);
  //FUNCTIONAL GROUPS VEHICLES DATA - END

  const handleVendorsCallback = useCallback(() => {
    let results: Option[] = vendors;
    vehiclesService.getVendors().then((data) => {
      if (data && data.succeeded) {
        data.value.forEach((x: any) => {
          results.push({
            label: x.name,
            value: x.no,
          });
        });
        setVendors((rows) => {
          rows = results;
          return rows;
        });
      }
    });
    return results;
  }, [vendors ]);

  const otherChaDropdownOptions = useMemo(() => settings.Data.Vehicles_UseProps, [settings.Data.Vehicles_UseProps]);
  const vendorsMemo = useMemo(
    () => handleVendorsCallback(),
    [handleVendorsCallback]
  );

  const updateSearchOptions = useCallback((item: Option, no: string, vehicles: IPreConfiguredVehicle[]) => {

    // let options:Option[] = [];
    // if(vehicleSearchOptions) = [];
    // vehicles.forEach((vehicle) => {
    //   if(vehicle.versionId.length > 0){
    //     let searchIndex = options.findIndex((x) => {
    //       return x.value === vehicle.versionId;
    //     });
    //     if (searchIndex === -1) {
    //       options.push({
    //         label: `${vehicle.make}; ${vehicle.description}; ${vehicle.caracteristics}`,
    //         value: vehicle.versionId,
    //       });
    //     }
    //   }
    // });
    // setVehicleSearchOptions(options);

    //ADICIONA
    if(item.value !== ""){
      var searchIndex = vehicleSearchOptions.findIndex((x) => {
        return x.value === item.value;
      });
      var dataIndex = vehicles.findIndex((d) => {
        return d.versionId === item.value;
      });
      if (searchIndex === -1 && dataIndex > -1) vehicleSearchOptions.push(item);
    }

    //REMOVE
    if (no) {
      let indexRemove = vehicleSearchOptions.findIndex((x) => x.value === no);
      var vehicleCont = vehicles.filter((x) => x.versionId === no).length;

      if (indexRemove > -1 && vehicleCont === 0) {
        vehicleSearchOptions.splice(indexRemove, 1);
      }
      if (vehicles.length === 0) {
        setVehicleSearchOptions([]);
      }
    }
  }, [vehicleSearchOptions]);

  const convertToExpandableMakesOptions = useCallback((items: any) => {
    let convertedItems = items.map((brand: any, index:number) => {
      let newItem = {
        label: brand.id,
        hidden: false,
        open: false,
        checkBoxProps: {
          checked: false,
        },
        models: brand.models.map(
          (model: { id: string; description: string }) => {
            return {
              id: model.id,
              label: model.description,
              checkBoxProps: {
                checked: false,
              },
            };
          }
        ),
      };
      return newItem;
    });
    return convertedItems;
  },[]);

  const handleExtraSearchPropsChange = useCallback(
    (rowIndex: Number) => (e: any, option: any) => {
      setVehicles((rows) =>
        rows.map((row, index) => {
          if (index !== rowIndex) {
            return row;
          }
          if (option === null) {
            row.extraStandard.forEach((element) => {
              element.visible = true;
            });
          }
          row.extraOptionSelect = option;

          updateValuesVehicle(row,"");
          return row;
        })
      );
  }, []);
  
  const getExtraSearchForVehicles = useCallback((index:number) => {
    let searchResult:SearchResultProps={
      label: 'Pesquisar por extra',
      placeholder: 'Pesquisar',
      onChange: handleExtraSearchPropsChange(index),
      value:vehicles[index].extraOptionSelect,
      options:vehicles[index].extraOptionsSearch,
    };
    return searchResult;
  }, [vehicles,handleExtraSearchPropsChange]);

  const handleExtraStandardTextFieldChange = useCallback(( e:any) => {
    let splits = e.target.name.split("-");
    //const name = splits[0];
    const nameVat= splits[1];
    const rowIndex =convertNumber(splits[2]);
    const indexExtr =convertNumber(splits[3]);

    setVehicles((rows) =>
      rows.map((row, index) => {
        if (index !== rowIndex) {
          return row;
        }
        let vehicle = row;
        let acce = vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr];
      
      let priceVat=convertNumber(validaStringFormatDecimal(e.target.value,acce['price_Excl_VAT']));
      if(isNaN(priceVat)){
          acce={...acce,[nameVat]: validaStringFormatDecimal(e.target.value,acce['price_Excl_VAT'])};
          acce={...acce,[nameVat]: validaStringFormatDecimal(e.target.value,acce['price'])};
          vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr].price_Excl_VAT = acce.price_Excl_VAT;
      } else {
        acce={...acce,[nameVat]: validaStringFormatDecimal(e.target.value,acce['price_Excl_VAT'])}

        // acce={...acce,[name]:Number(validaStringFormatDecimal(
        //   (Number(priceVat) * (1+row.vat/100)).toString(),
        //   acce['price']))}
        acce.price = Math.round(( Number(priceVat) * (1+row.vat/100)) * 100) / 100;// ( Number(priceVat) * (1+row.vat/100)).toFixed(2)

        vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr].price_Excl_VAT = acce.price_Excl_VAT;
        vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr].price = acce.price;
        vehicle.extraStandard.filter(x=>x.visible===true)[indexExtr].valueTextFieldProps.value = acce.price_Excl_VAT;

        let totalextraStandard = 0;
        let totalextraStandardVat = 0;

        if (vehicle.extraStandard.length > 0) {
          vehicle.extraStandard.forEach((item) => {
            if (item.checkedExtra === true){
              totalextraStandard +=convertNumber(item.price_Excl_VAT);
            }
          });

          if (totalextraStandard !== 0)
            totalextraStandardVat =convertNumber(
              (totalextraStandard * (1 + vehicle.vat / 100)).toFixed(2)
            );
          } else {
            totalextraStandard = vehicle.totalExtraWithoutTax;
            totalextraStandardVat = vehicle.totalExtraWithTax;
          }
          vehicle.totalExtraWithoutTax = Number(totalextraStandard.toFixed(2));
          vehicle.totalExtraWithTax = Number(totalextraStandardVat.toFixed(2));
          updateValuesVehicle(vehicle,"");
        }
        return { ...row, vehicle };
      })
    );
  }, []);
  

  const getExtraStandard = useCallback((index:number) => {
    const aux: VehicleDetailsProps['extraStandard'] = [];
    let vehicle = vehicles[index];
    vehicle?.extraStandard?.filter(x => x.visible === true).forEach((item,indexExtr) =>{
      let disable = vehicle.licensePlate ? true :false;

      aux.push({
        group: item.main_Group_Allocation ? item.main_Group_Allocation : "",
        groupLabel: item.descGroup ? item.descGroup :"",
        code: item.optionTypeId,
        label: item.description,
        valueTextFieldProps: {
          name: "price-price_Excl_VAT-" + index + "-" + indexExtr, //name: `price-${index}-${indexExtr}`,
          value: item.price_Excl_VAT, 
          //onChange: handleExtraStandardTextFieldChange,
          disabled: propertyDisabled('Extras','Detalhes Viatura') || disable,
          hidden: propertyHidden('Extras','Detalhes Viatura') || disable,
          onInput: handleExtraStandardTextFieldChange,
          
        },
        checkboxProps: {
          checked: item.checkedExtra,
          name:item.optionTypeId,
          disabled: propertyDisabled('Extras','Detalhes Viatura') || disable,
          hidden: propertyHidden('Extras','Detalhes Viatura') || disable,
        },
        lazyload: true,
        requiredCodes: [], // => obrigatórios
        incompatibilityCodes: [], // incompatibilidade de extras
        includes: [], // => packs de extras
      });
      // Inclusion Type
      switch (item.inclusion_Type) {
        case 'Obligatory':
          aux[aux.length-1].requiredCodes = item.compatibleExtrasList
          break;
        case 'Inclusion':
          aux[aux.length-1].requiredCodes = item.compatibleExtrasList
          break;
        case 'Exclusion':
          // temp fix
          let incompatibleExtrasTmp:string[] = []
          item.incompatibleExtrasList.forEach(element => {
            element.forEach(value => {
              incompatibleExtrasTmp.push(value)
            });
          });
          aux[aux.length-1].incompatibilityCodes = incompatibleExtrasTmp
          // end temp fix
          // aux[aux.length-1].incompatibilityCodes = item.incompatibleExtrasList
          break;
      
        default:
          break;
      }
      // Package
      if(item.package === 'Yes') {
        let packageExtras:{
          label:string,
          code:string
        }[] = [];

        //não consigo resolver isto. retorna sempre uma promise
        // let data = await vehiclesService.getIncompatibilityExtraPackageDescription(vehicleDataTable[index].versionId,item.compatibleExtrasList)//.then(data => {
        // if(data.succeeded) {
        //   data.value.forEach((element:{label:string, code:string}) => {
        //     packageExtras.push(element)
        //   });
        //   aux[aux.length-1].includes = packageExtras;
        //   aux[aux.length-1].requiredCodes = []
        // }
        // // });

        item.compatibleExtrasList.forEach(value => {

          packageExtras.push({
            label:value,
            code:value
          })
        })
        aux[aux.length-1].includes = packageExtras;
        aux[aux.length-1].requiredCodes = []

      } else {
        aux[aux.length-1].includes = []
      }
    })
    return aux;
  }, [vehicles, handleExtraStandardTextFieldChange, propertyDisabled, propertyHidden]);

  const handleOnChange: TextFieldProps["onChange"] = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      let splits = e.target.name.split("-");
      const name = splits[1];
      const rowIndex = convertNumber(splits[0]);
      //setChangedProposal(true);
      setVehicles((rows) =>
        rows.map((row, index) => {
          if (index !== rowIndex) {
            return row;
          }
          try {
            if (
              name === "lotId" ||
              name === "licensePlate" ||
              name === "use"
            ) {
              // let value = 0;
              //if(e.target.value!="" && !isNaN(e.target.value))
              //value =e.target.value;
              row[name] = e.target.value;
            }
            //FIXME Alexandre: VALIDAR ALTERAÇÕES quando estiverem definidas as regras das cotações
            else if (name === "quantity") {
              row.quantity = convertNumber(e.target.value);
              if (row.simulations.length > 0) {
                //TODO Alexandre: validar comportamento. altera so a quantidade na primeira ou em todas as simulações?
                row.simulations[0].quantity =convertNumber(e.target.value);
              }
            } 
                  
            else {
              row[name] = validaStringFormatDecimal(e.target.value,row[name]);
            
            }
            if (name === "businessValueWithoutTax") {
              let valueP =validaStringFormatDecimal(e.target.value,row.businessValueWithoutTax);
              let busissValue=convertNumber((
                  convertNumber(row.priceexclVAT) +

                  convertNumber(row.totalAccessoriesWithout) +
                  convertNumber(row.totalExtraWithoutTax) +

                  convertNumber(row.luxury_tax) +
                  convertNumber(row.transport_x0026_immatriculation_cost) +
                  convertNumber(row.recycling_charge) -

                  convertNumber(row.rappelWithTaxTotal) -
                  convertNumber(row.extraRappel) -

                  convertNumber(row.campaignWithTaxTotal)
              ).toFixed(2));
                //TODO Alexandre: validar comportamento. altera so a quantidade na primeira ou em todas as simulações?
              let discount=convertNumber((convertNumber(busissValue)-convertNumber(valueP)).toFixed(2));
              if(discount>=0){
                row.discountWithoutTax =discount;

              }
              else
               row.discountWithoutTax=0;
               updateValuesVehicle(row,"");

            } 

            if(name === "consumersPrice") {
              row.priceexclVAT=convertNumber(
                  (convertNumber(row[name]) / (1 + row.vat / 100)).toFixed(2)
                );
               updateValuesVehicle(row,"consumersPrice");
            }
            else{
               updateValuesVehicle(row,"");
            }
            return row;
          } catch (ex) {
            console.log(ex);
            return row;
          }
        })
      );
    },
    []
  );

  const handleAddAccessoryButtonPropsClick: ButtonProps["onClick"] = useCallback((e: any) => {
    let splits = e.currentTarget.name.split("-");
    const rowIndex =convertNumber(splits[0]);
    const id =convertNumber(splits[1]);
    //setChangedProposal(true);
    setVehicles((rows) =>
      rows.map((row, index) => {
        if (index !== rowIndex) {
          return row;
        }
        let vehicle = row;
        vehicle.accessories.unshift({
          id: 0,
          optionTypeId: "",
          description: "",
          vehicleId: id,
          price: 0,
          price_Excl_VAT:0,
          responsable: "",
        });

        return { ...row, vehicle };
      })
    );
  }, []);

  const handleExtraSearchPropsClick: ButtonProps["onClick"] = useCallback(
    (e: any) => {
      let rowIndex =convertNumber(e.currentTarget.name);
      setVehicles((rows) =>
        rows.map((row, index) => {
          if (index !== rowIndex) {
            return row;
          }
          let searchResults = row.extraOptionsSearch.filter(
            (b) => b.label === row.extraOptionSelect?.label
          )[0]; // [0 temporary solution]

          if (searchResults !== null) {
            row.extraStandard.forEach((element) => {
              if (element.optionTypeId === searchResults?.value) {
                element.visible = true;
              } else {
                element.visible = false;
              }
            });
            updateValuesVehicle(row,"");
          }
          return row;
        })
      );
    },
    []);

  const handleAccessoryDeleteButtonPropsClick : ButtonProps["onClick"] =  useCallback((e: any) => {
    let splits = e.currentTarget.name.split("-");
    const rowIndex =convertNumber(splits[0]);
    const indexAcc =convertNumber(splits[1])
  
    setVehicles((rows) =>
      rows.map((row, index) => {
        if(index !== rowIndex){
          return row;
        }
        let vehicle = row;
        let accessoryToDelete:IAccessory = vehicle.accessories.splice(indexAcc, 1)[0];
        if (vehicle.accessories.length > 0) {
          vehicle.totalAccessoriesWithout = 0;
          vehicle.accessories.forEach((item: { price: number; })=>{
            vehicle.totalAccessoriesWithout +=convertNumber(item.price);
          });
          vehicle.totalAccessoriesWith = vehicle.totalAccessoriesWithout  * (100 + vehicle.vat) / 100;
        } else {
          vehicle.totalAccessoriesWith=0;
          vehicle.totalAccessoriesWithout=0;
        }
        console.log(accessoryToDelete)
        if(accessoryToDelete?.id > 0){
          vehiclesService.deleteAccessory(accessoryToDelete?.id)
        }

        vehicle = updateValuesVehicle(vehicle,"");
        return {...row,vehicle};
      }
    ));
  },[])

  const handleAccessoryTextFieldChange = useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let splits = e.target.name.split("-");
    const name = splits[0];
    const rowIndex =convertNumber(splits[1]);
    const indexAcc =convertNumber(splits[2]);
    setVehicles((rows) =>
      rows.map((row, index) => {
        if (index !== rowIndex) {
          return row;
        }
        let totalAccessoriesWithoutVat = 0;
        let updatedAccessories = row.accessories.map((accessory, accessoryIndex) => {
          if(accessoryIndex !== indexAcc) {
            return accessory
          }
          if (name === "description" || name === "responsable") {
            accessory = { ...accessory, [name]: e.target.value };
          } else {
            accessory = { ...accessory, [name]: validaStringFormatDecimal(e.target.value,accessory['price_Excl_VAT'])};
            accessory.price = accessory.price_Excl_VAT * (1 + row.vat / 100);
          }
          return accessory
        })
        //atualiza acessórios da viatura
        row = {...row,accessories: updatedAccessories}
        // atualiza valores totais de acessórios com iva na viatura
        row.accessories.forEach(accessory => {
          totalAccessoriesWithoutVat += convertNumber(accessory.price_Excl_VAT);
        })
        row.totalAccessoriesWithout = totalAccessoriesWithoutVat
        row.totalAccessoriesWith = convertNumber(
          (row.totalAccessoriesWithout * (1 + row.vat / 100)).toFixed(2)
        );
        updateValuesVehicle(row,"");
        return row;
      })
    );
  },[]);

  const getAccessories = useCallback((index: number) => {
    const aux: VehicleDetailsProps["extraAccessories"] = [];
    let vehicle = vehicles[index];
    vehicle?.accessories?.map((item, indexAcc) => {
      let disable = vehicle.licensePlate ? true : false;
      let name = "Nome";
      let responsable = "Responsável de instalação";
      let price = "Preço sem IVA";
      aux.push({
        deleteExtraAcessoryIconButtonProps: {
          name:index + "-" + indexAcc,
          disabled: propertyDisabled('Acessórios','Detalhes Viatura') || item.optionTypeId !== '', 
          hidden: propertyHidden('Acessórios','Detalhes Viatura'),
          onClick:handleAccessoryDeleteButtonPropsClick,
        },
        ExtraAcessoryNameTextFieldProps: {
          label: name,
          placeholder: "Inserir nome do acessório",
          name: "description-" + index + "-" + indexAcc,
          value: item.description,
          onChange: handleAccessoryTextFieldChange,
          disabled: propertyDisabled('Acessórios','Detalhes Viatura') || disable,
          hidden: propertyHidden('Acessórios','Detalhes Viatura')
        },
        ExtraAcessoryInstallerTextFieldProps: {
          label: responsable,
          placeholder: "Inserir nome do responsável",
          value: item.responsable,
          name: "responsable-" + index + "-" + indexAcc,
          options: vendorsMemo,
          onChange: handleAccessoryTextFieldChange,
          disabled: propertyDisabled('Acessórios','Detalhes Viatura') || disable,
          hidden: propertyHidden('Acessórios','Detalhes Viatura')
        },
        ExtraAcessoryPriceTextFieldProps: {
          label: price,
          placeholder: "Inserir valor ",
          name: "price_Excl_VAT-" + index + "-" + indexAcc,
          endAdornment: "€",
          value: item.price_Excl_VAT,
          onChange: handleAccessoryTextFieldChange,
          disabled: propertyDisabled('Acessórios','Detalhes Viatura') ||disable,
          hidden: propertyHidden('Acessórios','Detalhes Viatura')
        },
        lazyload: true,
      });
      return item;
    });
    return aux;
  }, [vehicles, propertyHidden, handleAccessoryDeleteButtonPropsClick, handleAccessoryTextFieldChange, propertyDisabled, vendorsMemo]);

  const handleExtraStandarCheckChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      checked: boolean,
      index: number,
      tableIndex: number
    ) => {
      //setChangedProposal(true);
      setVehicles((rows) =>
        rows.map((row, indexR) => {
          if (indexR !== tableIndex) {
            return row;
          }
          let extra = row.extraStandard.find(
            (x) => x.optionTypeId === e.target.name
          );
          let indexExtra = row.extraStandard.findIndex(
            (ext) => ext.optionTypeId === e.target.name
          );
          if (extra){
            extra.checkedExtra = e.target.checked;
            extra.checkboxProps.checked = e.target.checked;
            row.extraStandard[indexExtra]= extra
            // row.extraStandard[indexExtra] = {
            //   ...extra,
            //   checkedExtra: e.target.checked, checkboxProps:{checked: e.target.checked}
            // };
          }

          let totalextraStandard = 0;
          let totalextraStandardVat = 0;

          if (row.extraStandard.length > 0) {
            row.extraStandard.forEach((item) => {
              if (item.checkedExtra === true) totalextraStandard +=convertNumber(item.price_Excl_VAT.toString());
            });

            if (totalextraStandard !== 0)
              totalextraStandardVat =convertNumber(
                (totalextraStandard * (1 + row.vat / 100)).toFixed(2)
              );
            } else {
              totalextraStandard = row.totalExtraWithoutTax;
              totalextraStandardVat = row.totalExtraWithTax;
            }

          row.totalExtraWithoutTax = Number(totalextraStandard.toFixed(2));
          row.totalExtraWithTax = Number(totalextraStandardVat.toFixed(2));
          updateValuesVehicle(row,"");
          return row;
        })
      );

      // const el = e.target.closest('#extra-check');
 
      // if (el) {
      //   const index: number = parseInt(el.getAttribute('data-curindex') || '-1');
      //   onExtraCheckboxChange(e, value, index, tableIndex);
      //   checkExtrasCompatibility();
      // }
    },
    []
  );

  useEffect(() => {
    SettingsService.getInstance()
      .then(x => setSettings(x.settings));
  }, []);
  
  const initExtraStandardDetails = useCallback(async (vehicle: IPreConfiguredVehicle): Promise<IPreConfiguredVehicle> => {
    //Extra Standard
    let response = await vehiclesService.getOptionsType(vehicle.versionId,false);
    if (!response.succeeded) {
      openAlert({
        variant: "error",
        text: `Não foi possível carregar os extras da viatura ${vehicle.versionDescription}! Por favor tente novamente. Se o problema persistir, contate o administrador.`,
        title: "Atenção",
      });
    } else {
      let extrasStandardTemp = vehicle.extraStandard;
      if (extrasStandardTemp.length > 0){
        vehiclesService.deleteExtrasStandard(extrasStandardTemp);
      }
      let extrasSearch: Option[];
      extrasSearch = [];
      vehicle.totalExtraWithoutTax =0;
      vehicle.totalExtraWithTax = 0;

      let index = vehicles.findIndex(x => x.id === vehicle.id)
      if(index === -1){
        index = 0;
      }
      let disable = vehicle.licensePlate ? true :false;
      response.value.forEach((element:any,indexExtr:number) => {
        //lista de pesquisa de extras
        extrasSearch.push({
          label: `${element.description} - ${element.manufactor_Code} - ${element.optionTypeId}`,
          value: element.optionTypeId,
        });
        vehicle.extraStandard.forEach((frequentExtra) => {
          if (frequentExtra.optionTypeId === element.optionTypeId) {
            element.checkedExtra = frequentExtra.checkedExtra
            element.visible = true
            if(frequentExtra.checkedExtra === true){
              vehicle.totalExtraWithTax = vehicle.totalExtraWithTax + element.price
              vehicle.totalExtraWithoutTax = vehicle.totalExtraWithoutTax + element.price_Excl_VAT
            }
          }
        })
        //esturtura ExtraStandard do portal
        element.group = element.main_Group_Allocation ? element.main_Group_Allocation : ""
        element.groupLabel = element.descGroup ? element.descGroup :""
        element.code = element.optionTypeId
        element.label = element.description
        element.valueTextFieldProps = {
          disabled: disable,
          name: "price-price_Excl_VAT-" + index + "-" + indexExtr, //name: `price-${index}-${indexExtr}`,
          value: element.price_Excl_VAT, 
          //onChange: handleExtraStandardTextFieldChange,
          onInput: handleExtraStandardTextFieldChange,
        }
        element.checkboxProps = {
          disabled: disable,
          checked: element.checkedExtra,
          name:element.optionTypeId
        }
        element.lazyload = true
        element.requiredCodes = [] // => obrigatórios
        element.incompatibilityCodes = [] // incompatibilidade de extras
        element.includes = [] // => packs de extras

        switch (element.inclusion_Type) {
          case 'Obligatory':
            element.requiredCodes = element.compatibleExtrasList
            break;
          case 'Inclusion':
            element.requiredCodes = element.compatibleExtrasList
            break;
          case 'Exclusion':
            // temp fix
            let incompatibleExtrasTmp:string[] = []
            element.incompatibleExtrasList.forEach((incompatible:any) => {
              incompatible.forEach((value:any) => {
                incompatibleExtrasTmp.push(value)
              });
            });
            element.incompatibilityCodes = incompatibleExtrasTmp
            // end temp fix
            // aux[aux.length-1].incompatibilityCodes = item.incompatibleExtrasList
            break;
        
          default:
            break;
        }
        // Package
        if(element.package === 'Yes') {
          let packageExtras:{
            label:string,
            code:string
          }[] = [];
  
          element.compatibleExtrasList.forEach((value:string) => {
            let compatibleExtra:IPreConfiguredExtraStandard = response.value.find((x:IPreConfiguredExtraStandard) => x.optionTypeId === value)
            packageExtras.push({
              label: compatibleExtra?.description ?? value ,
              code: compatibleExtra?.optionTypeId ?? value
            })
          })
          element.includes = packageExtras;
          element.requiredCodes = []
        } else {
          element.includes = []
        }
      });
      vehicle.extraStandard = response.value;
      vehicle.extraOptionsSearch = extrasSearch;
    }
    return vehicle;
  },[handleExtraStandardTextFieldChange/*, vehicles*/])
 


  const stringPreConfiguredVehicleState = (value: string): PreConfiguredVehicleState => {
    if (Object.values(PreConfiguredVehicleState).includes(value as unknown as PreConfiguredVehicleState)) {
        return value as unknown as PreConfiguredVehicleState;
    }
    return PreConfiguredVehicleState.New;
  };
  
  const handleEdit = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number
  ) => {
    const vehicleRef = e.target.name.split("-");
    const propName = vehicleRef[0];
    const vehicleIndex = convertNumber(vehicleRef[1]);

    if(propName === 'makeId'){
      handleVehicleMakeChange(vehicleIndex, e.target.value);
    } else if(propName === 'modelId'){
      handleVehicleModelChange(vehicleIndex, e.target.value);
    } else if(propName === 'versionId'){
      handleVehicleVersionChange(vehicleIndex, e.target.value);
    } else if(propName === 'state'){
      const state = stringPreConfiguredVehicleState(e.target.value);
      setVehicles((vehicles) =>
        vehicles.map((vehicle, index) =>
          index === rowIndex
            ? { ...vehicle, [propName]: state }
            : vehicle
        )
      );
    } else {
      setVehicles((vehicles) =>
        vehicles.map((vehicle, index) =>
          index === rowIndex
            ? { ...vehicle, [propName]: e.target.value }
            : vehicle
        )
      );
    }
  };

  const handleVehicleMakeChange = useCallback((rowIndex:Number, makeId:string) => {
  //useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //let splits = e.target.name.split("-");
    //const name = splits[0];
    //const rowIndex =convertNumber(splits[1]);
  
    // setVehicleDataTable((rows) =>
    //   rows.map((cur, index) =>
    //     index === rowIndex ? 
    //     { ...cur, 
    //       [name]: e.target.value,
    //       versionId:"",
    //       modelId:"" 
    //     } : cur
    //   )
    // );

    let option: Option = {
      label:"",
      value: "",
    };
    
    setVehicles((rows) =>
      rows.map((row, indexR) => {
        if (indexR === rowIndex) {
          // faz uma copia do objecto para não alterar o original
          let updatedRow = {...row}
          // atualiza os valores pretendidos em conformidade com a lógica de negócio
          //updatedRow.id = 0;
          updatedRow.makeId =  makeId;
          updatedRow.leaseQuoteNo = "";
          updatedRow.versionId =  "";
          updatedRow.modelId =  "";
          updatedRow.licensePlate =  "";
          updatedRow.priceexclVAT =  0;
          updatedRow.consumersPrice =  0;
          updatedRow.pvp = 0;
          updatedRow.recycling_charge =  0;
          updatedRow.luxury_tax =  0;
          updatedRow.transport_x0026_immatriculation_cost =  0;
          updatedRow.status =  "";
          updatedRow.use =  "";
          updatedRow.numberofDoors =  0;
          updatedRow.fuelId =  "";
          updatedRow.fuelType =  "";
          updatedRow.euroTaxInternationalSegmt =  "";
          updatedRow.quantity_of_Seats =  0;
          updatedRow.lengthmm =  0;
          updatedRow.widthmm =  0;
          updatedRow.heightmm =  0;
          updatedRow.trunkVolumel =  0;
          updatedRow.wheelBasemm =  0;
          updatedRow.trainFrontWheelsmm =  0;
          updatedRow.trainRearWheelsmm =  0;
          updatedRow.compressionratio =  0;
          updatedRow.bore =  0;
          updatedRow.powerHP =  0;
          updatedRow.powerkW =  0;
          updatedRow.cO2DischargeDecF =  0;
          updatedRow.engineCc =  0;
          updatedRow.torqueNm =  0;
          updatedRow.fuelTankCapacityl =  0;

          updatedRow.maxspeedkmh =  0;
          updatedRow.acceleration0100kmh =  0;
          updatedRow.mileage =  0;
          updatedRow.modelYear =  "";
          updatedRow.recordDate =  "";
          updatedRow.accessories =  [];
          updatedRow.extraStandard =  [];
          updatedRow.simulations =  [];
          updatedRow.no =  "";
          updatedRow.lotId =  "";
          updatedRow.totalExtraWithoutTax =  0;
          updatedRow.totalAccessoriesWithout =  0;
          updatedRow.proformaInvoicePriceWithoutTax =  0;
          updatedRow.totalExtraWithTax =  0;
          updatedRow.totalAccessoriesWith =  0;
          updatedRow.proformaInvoicePriceWithTax =  0;
          updatedRow.discountWithTaxTotal =  0;
          updatedRow.rappelWithTaxTotal =  0;
          updatedRow.campaignWithTaxTotal =  0;
          updatedRow.businessValueWithoutTax =  0;
          updatedRow.businessValueWithTax =  0;
          updatedRow.discountWithTax =  0;
          updatedRow.discountWithoutTax =  0;
          updatedRow.discountWithTaxPercentage =  0;
          updatedRow.campaignWithTax =  0;
          updatedRow.campaignWithoutTax =  0;
          updatedRow.campaignWithTaxPercentage =  0;
          updatedRow.rappelWithTax =  0;
          updatedRow.rappelWithTaxPercentage =  0;
          updatedRow.rappelWithoutTax =  0;
          updatedRow.extraRappel =  0;
          updatedRow.extraOptionsSearch =  [];
          updatedRow.extraOptionSelect =  0;
          updatedRow.versionDescription =  "";
          updatedRow.caracteristics =  "";
          updatedRow.quantity =  1;
          updatedRow.oilChange =  0;
          updatedRow.bPM =  0;
          updatedRow.luxury_tax_vat =  0;
          updatedRow.vat =  0;
          updatedRow.technMaxQuantityKm =  0;
          updatedRow.hidden =  false;
          updatedRow.commercialOptions =  [];
          updatedRow.modelDescription =  "";
          updatedRow.iuc = 0;
          updatedRow.number_of_wheels = 0;
          updatedRow.vehicleStatus =  {
            color:  "warning",
            label:  "evaluating"
          };
          updatedRow.group_Price_Map = "";
    
          updatedRow.driverId =  ""; //FIXME susbtituir por nome do contato do user quando este estiver definido no user
          updatedRow.renew =  false;
          updatedRow.driverName =  "";
          updatedRow.modelId = "";
          updatedRow.versionId = "";
          // se a marca for alterada, retira a viatura das opções de pesquisa de viaturas da proposta
          let oldVersion = row.versionId;
          updateSearchOptions(option,oldVersion,rows);
          //atualiza a linha com novo objecto
          return updatedRow;
        }
        return row
      })
    );      
  },[
    updateSearchOptions
  ]);

  const initAccessoriesDetails = useCallback(async (vehicle: IPreConfiguredVehicle): Promise<IPreConfiguredVehicle> => {
    //Adicionais
    let response = await vehiclesService.getOptionsType( vehicle.versionId, true);
    if (!response.succeeded) {
      openAlert({
        variant: "error",
        text: "Ocorreu um erro pesquisar adicionais de viaturas! Por favor tente novamente. Se o problema persistir, contate o administrador.",
        title: "Atenção",
      });
    } else {
      let accessoriesTemp = vehicle.accessories;
      if (accessoriesTemp.length > 0){
        vehiclesService.deleteAccessories(accessoriesTemp);
      }
      let accessories: IAccessory[];
      response.value.map((item: any) => {
        item.id = 0;
        item.vehicleId = 0;
        return item;
      });
      accessories = response.value;
      let totalAccessories = 0;
      let totalAccessoriesVat = 0;
      if (accessories.length > 0) {
        accessories.forEach((item) => {
          totalAccessories += item.price_Excl_VAT;
        });
      }
      if (totalAccessories > 0){
        totalAccessoriesVat = convertNumber(
          (totalAccessories * (1 + vehicle.vat / 100)).toFixed(2)
        );
      }
      vehicle.accessories = accessories;
      vehicle.totalAccessoriesWithout = totalAccessories;
      vehicle.totalAccessoriesWith = totalAccessoriesVat;
    }
    return vehicle;
  },[]);

  const handleVehicleModelChange = useCallback(async (vehicleRowIndex:number, modelId:string) => {
  //useCallback(async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // let splits = e.target.name.split("-");
    // const rowIndex =convertNumber(splits[1]);
    let filters: { type: AdvancedFilterVersionsOptions; value: string }[] = [];
    filters.push({
      type: AdvancedFilterVersionsOptions.Make,
      value: vehicles[vehicleRowIndex].makeId,
    });
    filters.push({
      type: AdvancedFilterVersionsOptions.Model,
      value: modelId,
    });
    
    let filtersBystate = VehicleTypeStatusHelper.generateAdvancedFilterVersionsOptions(true);
    // filtro para obter viaturas inactivas
    if(isFilterByFunctionalGroups){
      filtersBystate = VehicleTypeStatusHelper.generateAdvancedFilterVersionsOptions(false);
    }
    if(filtersBystate.length > 0){
      filters.push(...filtersBystate);
    }
      
    await vehiclesService.getVersionsToCombo(filters).then((data) => {
      if (!data.succeeded) {
        openAlert({
          variant: "error",
          text: "Ocorreu um erro pesquisar viaturas! Por favor tente novamente. Se o problema persistir, contate o administrador.",
          title: "Atenção",
        });
      } else {
        if (data.value && data.value.length > 0) {
          // atualiza as versões disponíveis para seleção
          setVersionsSearchOptions((rows) =>
            rows.map((cur) => {
              if (cur.makeId !== vehicles[vehicleRowIndex].makeId || cur.modelId !== modelId)  {
                return cur;
              }
                              
              if (cur.versions.length === 1 && cur.versions[0].label==='') {
                cur.versions = data.value;
              } else {
                data.value.forEach((item: { label: string; value: string; isInactive: boolean }) => {
                  if (!cur.versions.find((ver) =>{ return ver.value === item.value; })) {
                    cur.versions.push(item);
                  }
                });
              }
              return cur;
            })
          );

          let option: Option = {
            label:"",
            value: "",
          };
          let oldVersion = vehicles[vehicleRowIndex].versionId;
          vehicles[vehicleRowIndex].versionId="";
          updateSearchOptions(option,oldVersion, vehicles);
          // atualiza modelo da viatura
          setVehicles((rows) =>
            rows.map((row, indexR) => {
              if (indexR === vehicleRowIndex) {
                row.modelId = modelId;
              }
              return {...row};
            })
          );      
        } else {
          setVersionsSearchOptions((rows) =>
            rows.map((cur, index) => {
              if (cur.modelId === modelId) {
                cur.versions = [{ label: "", value: "", isInactive: false }];
              }
              return {...cur};
            })
          );

          openAlert({
            variant: "error",
            text:
              "O modelo " +
              modelSearchOptions.find(x => x.makeId === vehicles[vehicleRowIndex].makeId)?.models.find(x => x.value === modelId)?.label +
              " não contem versões disponiveis!",
            title: "Atenção",
          });
        }
      }
    });
  },[
    modelSearchOptions, 
    updateSearchOptions, 
    vehicles, 
    isFilterByFunctionalGroups
  ]);

  const handleVehicleVersionChange = useCallback(async (vehicleRowIndex:number, versionId:string) => {     
    let filters: { type: AdvancedFilterVersionsOptions; value: string }[] = [];
    filters.push({
      type: AdvancedFilterVersionsOptions.No,
      value: versionId,
    });
    vehiclesService.getVersions(filters).then(async (data) => {
      if (!data.succeeded) {
        openAlert({
          variant: "error",
          text: data.message,
          title: "Atenção",
        });
      } else {
        if (data.value.length > 0) {
          let vehicleTemp:IPreConfiguredVehicle = {
            id:0,
            proposalId: 0,
            makeId: "",
            leaseQuoteNo:"",
            versionId: "",
            modelId: "",
            licensePlate: "",
            priceexclVAT: 0,
            consumersPrice: 0,
            pvp:0,
            recycling_charge: 0,
            luxury_tax: 0,
            transport_x0026_immatriculation_cost: 0,
            status: "",
            state: PreConfiguredVehicleState.New,
            use: "",
            numberofDoors: 0,
            fuelId: "",
            fuelType: "",
            euroTaxInternationalSegmt: "",
            quantity_of_Seats: 0,
            lengthmm: 0,
            widthmm: 0,
            heightmm: 0,
            trunkVolumel: 0,
            wheelBasemm: 0,
            trainFrontWheelsmm: 0,
            trainRearWheelsmm: 0,
            compressionratio: 0,
            bore: 0,
            powerHP: 0,
            powerkW: 0,
            cO2DischargeDecF: 0,
            engineCc: 0,
            torqueNm: 0,
            fuelTankCapacityl: 0,

            maxspeedkmh: 0,
            acceleration0100kmh: 0,
            mileage: 0,
            modelYear: "",
            recordDate: "",
            accessories: [],
            extraStandard: [],
            simulations: [],
            no: "",
            lotId: "",
            totalExtraWithoutTax: 0,
            totalAccessoriesWithout: 0,
            proformaInvoicePriceWithoutTax: 0,
            totalExtraWithTax: 0,
            totalAccessoriesWith: 0,
            proformaInvoicePriceWithTax: 0,
            discountWithTaxTotal: 0,
            rappelWithTaxTotal: 0,
            campaignWithTaxTotal: 0,
            businessValueWithoutTax: 0,
            businessValueWithTax: 0,
            discountWithTax: 0,
            discountWithoutTax: 0,
            discountWithTaxPercentage: 0,
            campaignWithTax: 0,
            campaignWithoutTax: 0,
            campaignWithTaxPercentage: 0,
            rappelWithTax: 0,
            rappelWithTaxPercentage: 0,
            rappelWithoutTax: 0,
            extraRappel: 0,
            extraOptionsSearch: [],
            extraOptionSelect: 0,
            versionDescription: "",
            caracteristics: "",
            quantity: 1,
            typeofRental: currentUser?.typeofRental
              ? currentUser?.typeofRental
              : "",
            commercialConditions: currentUser?.commercialConditions
              ? currentUser?.commercialConditions
              : "",
            typeofIncome: currentUser?.typeofIncome
              ? currentUser.typeofIncome
              : "",
            oilChange: 0,
            bPM: 0,
            luxury_tax_vat: 0,
            vat: 0,
            technMaxQuantityKm: 0,
            driverId: "", //FIXME susbtituir por nome do contato do user quando este estiver definido no user
            renew: false,
            hidden: false,
            commercialOptions: [],
            modelDescription: "",
            iuc:0,
            number_of_wheels:0,
            group_Price_Map:"",
            vehicleStatus: {
              color: "warning",
              label: "evaluating",
            },
            driverName: "",
          };

          const option: Option = {
            label: `${data.value[0].make}; ${data.value[0].description}; ${data.value[0].caracteristics}`,
            value: data.value[0].no,
          };
          vehicleTemp.versionId = versionId;

          let functionalGroupVehicleValuesToApply: FunctionalGroupsVersionType | undefined = undefined;
          if(isFilterByFunctionalGroups){
            functionalGroupVehicleValuesToApply = functionalGroupsVersionTypes.find(x => x.versionType === versionId)
          }

          await initAccessoriesDetails(vehicleTemp);
          await initExtraStandardDetails(vehicleTemp); //,rowIndex);
          setVehicles((rows) =>
            rows.map((row, indexR) => {
              if (indexR !== vehicleRowIndex) {
                return row;
              }
              let oldVersion = rows[vehicleRowIndex].versionId;
              rows[vehicleRowIndex].versionId = versionId;
              updateSearchOptions(option, oldVersion, rows);

              row.versionId = versionId;
              row.priceexclVAT = data.value[0].priceexclVAT;

              row.vat = data.value[0].vat;
              row.recycling_charge = data.value[0].recycling_charge;
              row.luxury_tax = data.value[0].luxury_tax;
              row.luxury_tax_vat = data.value[0].luxury_tax + data.value[0].luxury_tax * data.value[0].vat;
             
              // mapeamento campos grupos funcionais - inicio
              // se o utilizador tiver um grupo funcional associado carrega os valores da viatura do grupo funcional para os seguintes campos.
              //taxa legalização
              row.transport_x0026_immatriculation_cost = functionalGroupVehicleValuesToApply ? 
                functionalGroupVehicleValuesToApply.transport_x0026_immatriculation_cost : 
                data.value[0].transport_x0026_immatriculation_cost;
              //desconto
              row.discountWithoutTax = 0;
              row.discountWithTaxPercentage = 0;
              if(functionalGroupVehicleValuesToApply){
                let discountWithTaxTotal = (functionalGroupVehicleValuesToApply.discountPercentage * (Number(row.priceexclVAT) + row.totalExtraWithoutTax + row.totalAccessoriesWithout))/100
                row.discountWithoutTax = discountWithTaxTotal - (
                  (convertNumber(row.priceexclVAT) + convertNumber(row.totalExtraWithoutTax) + convertNumber(row.totalAccessoriesWithout)) * 
                  (Number(row.discountWithTaxPercentage) / 100)
                ) 
              }
              //campanha
              row.campaignWithoutTax = functionalGroupVehicleValuesToApply ? 
                functionalGroupVehicleValuesToApply.campaign : 
                0;
              row.campaignWithTaxPercentage = 0;
              //rappel
              row.rappelWithoutTax = functionalGroupVehicleValuesToApply ? 
                functionalGroupVehicleValuesToApply.rappel : 
                0;
              row.rappelWithTax = 0;
              row.rappelWithTaxPercentage = 0;
              row.extraRappel = 0;
              // mapeamento campos grupos funcionais - fim

              row.status = data.value[0].status;
              row.use = data.value[0].use;
              row.numberofDoors = data.value[0].numberofDoors;
              row.fuelId = data.value[0].fuelId;
              row.fuelType = data.value[0].fuelType;
              row.euroTaxInternationalSegmt = data.value[0].euroTaxInternationalSegmt;
              row.quantity_of_Seats = data.value[0].quantity_of_Seats;
              row.lengthmm = data.value[0].lengthmm;
              row.widthmm = data.value[0].widthmm;
              row.hightmm = data.value[0].hightmm;
              row.trunkVolumel = data.value[0].trunkVolumel;
              row.wheelBasemm = data.value[0].wheelBasemm;
              row.trainFrontWheelsmm = data.value[0].trainFrontWheelsmm;
              row.trainRearWheelsmm = data.value[0].trainRearWheelsmm;
              row.compressionratio = data.value[0].compressionratio;
              row.bore = data.value[0].bore;
              row.powerHP = data.value[0].powerHP;
              row.powerkW = data.value[0].powerkW;
              row.cO2DischargeDecF = data.value[0].cO2DischargeDecF;
              row.engineCc = data.value[0].engineCc;
              row.torqueNm = data.value[0].torqueNm;
              row.fuelTankCapacityl = data.value[0].fuelTankCapacityl;
              row.fuelDescription = data.value[0].fuelDescription;
              row.maxspeedkmh = data.value[0].maxspeedkmh;
              row.acceleration0100kmh = data.value[0].acceleration0100kmh;
              row.recordDate = data.value[0].recordDate;
              row.modelYear = data.value[0].modelYear;
              row.modelId = data.value[0].modelNo;
              row.versionDescription = data.value[0].make + " - " + data.value[0].description;
              row.caracteristics = data.value[0].caracteristics;
              row.accessories = vehicleTemp?.accessories;
              row.extraStandard = vehicleTemp?.extraStandard;
              row.extraOptionsSearch = vehicleTemp?.extraOptionsSearch;
              row.iuc = data.value[0].iuc;
              row.number_of_wheels = data.value[0].number_of_wheels;
              
              row.group_Price_Map = data.value[0].group_Price_Map;
              row.vehicleStatus = {
                label: "Criado",
                color: "info",
              };

              // initAccessoriesDetails(row);
              // initExtraStandardDetails(row);

              updateValuesVehicle(row,"");
              return {...row};
            })
          );
        }
      }
    });
  },
  [
    currentUser?.commercialConditions, 
    currentUser.typeofIncome, 
    currentUser?.typeofRental, 
    initAccessoriesDetails, 
    initExtraStandardDetails, 
    updateSearchOptions,
    isFilterByFunctionalGroups,
    functionalGroupsVersionTypes
  ]);

  const handleVehicleSearchPropsChange: SearchResultProps["onChange"] = useCallback((_: any, option: any) => {
      if (option === null) {
        setVehicles((rows) => {
          return rows.map((cur) =>
            cur.versionId !== "" ? { ...cur, hidden: false } : cur
          );
        });
      }
      setBrandInput(option);
    }, []);
  
  const handleResetSearchFilterClick = useCallback(() => {
    setVehicles((rows) => {
      return rows.map((cur) => ({ ...cur, hidden: false }));
    });
    let searchResults: Option = { value: "", label: "" };
    setBrandInput(searchResults);
    setResetSearchDisabled(true);    
  }, []);
  
  const handleSearchOptionsClick = useCallback(() => {
      let searchResults: Option = { value: "", label: "" };
      setBrandInput((brandInputSelect) => {
        searchResults = vehicleSearchOptions.filter(
          (b) => b.value === brandInputSelect?.value
        )[0];
        return brandInputSelect;
      });
      // [0 temporary solution]
      if (searchResults.value !== "") {      
        setVehicles((rows) => {
          //  rows.filter((element) => element.versionId === searchResults.value)
          //    .length
          //);
          return rows.map((cur) =>
            cur.versionId !== searchResults.value ? { ...cur, hidden: true } : cur
          );
        });
        //setDisable(false);
        setResetSearchDisabled(false);
      }
    }, [vehicleSearchOptions]);

  const getVersion = useCallback((make: string, model:string,indexVehicle:number) => {
    const index = versionsSearchOptions.findIndex((e) =>
      e.makeId === make && e.modelId === model ? make : ""
    );

    if(versionsSearchOptions[index]?.versions[0].value===""){
     let filters: { type: AdvancedFilterVersionsOptions; value: string }[] = [];
      filters.push({
        type: AdvancedFilterVersionsOptions.Make,
        value: make,
      });
       filters.push({
        type: AdvancedFilterVersionsOptions.Model,
        value:model,
      });
     
      let filtersBystate = VehicleTypeStatusHelper.generateAdvancedFilterVersionsOptions(true);
      if(isFilterByFunctionalGroups){
        filtersBystate = VehicleTypeStatusHelper.generateAdvancedFilterVersionsOptions(false);
      }
      if(filtersBystate.length > 0){
        filters.push(...filtersBystate);
      }

      vehiclesService.getVersionsToCombo(filters).then((data) => {
        if (!data.succeeded) {
          openAlert({
            variant: "error",
            text: "Ocorreu um erro carregar as descrições das viaturas! Por favor tente novamente. Se o problema persistir, contate o administrador.",
            title: "Atenção",
          });
        } else {
          if (data.value.length > 0) {
            if (data.value !== undefined && data.value[0] !== undefined) {
              setVersionsSearchOptions((rows) =>
                rows.map((cur, index) => {
                  if (cur.makeId !== make || cur.modelId !== model)  {
                    return cur;
                  }
                                  
                  if (cur.versions.length === 1 && cur.versions[0].label==='') {
                    cur.versions = data.value;
                  } else {
                    data.value.forEach((item: { label: string; value: string; isInactive: boolean }) => {
                      if (!cur.versions.find((ver) =>{ return ver.value === item.value; })) {
                        cur.versions.push(item);
                      }
                    });
                  }
                  return cur;
                })
              );
              return data.value
            }
          } else {
            const currentMakeIndex = versionsSearchOptions.findIndex(
              (x) => x.makeId === make && x.modelId===model
            );
            let vehicle:IPreConfiguredVehicle = vehicles[indexVehicle];
            const hasInactiveVersion = versionsSearchOptions[currentMakeIndex].versions.some(v => v.value === vehicle?.versionId);
            if(!hasInactiveVersion){
              if( versionsSearchOptions[currentMakeIndex].versions.filter(x => x.value === "").length===1) {
                versionsSearchOptions[currentMakeIndex].versions=[{
                  label: `${vehicle?.modelDescription}; ${vehicle?.caracteristics}`,
                  value: vehicle.versionId,
                  isInactive: true
                }]; 
              }
              else{
                versionsSearchOptions[currentMakeIndex].versions.push({
                  label: `${vehicle?.modelDescription}; ${vehicle?.caracteristics}`,
                  value: vehicle.versionId,
                  isInactive: true
                }); 
              }
            }
            setVersionsSearchOptions([...versionsSearchOptions]);
            return  [...versionsSearchOptions[currentMakeIndex].versions];
          }
        }
      });
    } else {  
      return [...versionsSearchOptions[index]?.versions];
    }
  },[
    vehicles,
    versionsSearchOptions,
    isFilterByFunctionalGroups
  ]);

  const getModel = useCallback((make: string,model:string) => {
    const index = modelSearchOptions.findIndex((e) =>
      e.makeId === make ? make : ""
    );

    let makeModel = modelSearchOptions.find( x => x.makeId === make);
    if( makeModel !== undefined && makeModel?.models.find( x => x.value === model) === undefined && (model !== undefined && model !== "")) {
      //let brand = brandsSectionProps.find((x: { label: string; models:{label:string,id:string}[]}) => x.label === make);
      let brand = makesSearchOptions.find((x: { label: string; models:{label:string,id:string}[]}) => x.label === make);
      let modelOption = brand.models.find((x: { id: any;label:string }) => x.id === model);
      // const index = modelSearchOptions.findIndex((e) =>
      //   e.makeId === make ? make : ""
      // );
     if(modelOption !== undefined){
        if(makeModel.models === undefined)
          makeModel.models=[{label:modelOption.label,value:modelOption.id}];
        else
          makeModel?.models.push({label:modelOption.label,value:modelOption.id});
      }
       return makeModel?.models ? makeModel?.models:[];
    }
    return modelSearchOptions[index]?.models?modelSearchOptions[index]?.models:[];
  },[modelSearchOptions, makesSearchOptions/*brandsSectionProps*/]);
  
  function updateValuesVehicle(vehicle: IPreConfiguredVehicle,fieldName:string): IPreConfiguredVehicle {
    if(fieldName === "consumersPrice"){
       vehicle.priceexclVAT = convertNumber((
        convertNumber(vehicle.consumersPrice) / (1 + vehicle.vat / 100)).toFixed(2)
      );
    }  
    else {
        vehicle.consumersPrice = convertNumber((
          convertNumber(vehicle.priceexclVAT) * (1 + vehicle.vat / 100)
        ).toFixed(2)
      );
    }
    vehicle.discountWithTax = convertNumber((
        convertNumber(vehicle.discountWithoutTax) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.discountWithTaxTotal = 
      // regra para envio de valor
      convertNumber((
        convertNumber(vehicle.discountWithoutTax) +
        ((convertNumber(vehicle.priceexclVAT) + convertNumber(vehicle.totalExtraWithoutTax)) * (Number(vehicle.discountWithTaxPercentage) / 100))
      ).toFixed(2)
      );

      // regra para envio de percentagem
      // convertNumber(
      //   ((convertNumber(vehicle.discountWithoutTax) / ( Number(vehicle.priceexclVAT) + vehicle.totalExtraWithoutTax ) )*100
      // ).toFixed(2));

    vehicle.campaignWithTax = convertNumber((
        Number(vehicle.campaignWithoutTax) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.campaignWithTaxTotal =convertNumber((
        convertNumber(vehicle.campaignWithoutTax) +
        convertNumber(vehicle.priceexclVAT) * (Number(vehicle.campaignWithTaxPercentage) / 100)
      ).toFixed(2)
    );
    vehicle.rappelWithTax =convertNumber((
        Number(vehicle.rappelWithoutTax) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    let rappelTotal =convertNumber((
        convertNumber(vehicle.rappelWithoutTax) +
        convertNumber(vehicle.priceexclVAT) * (Number(vehicle.rappelWithTaxPercentage) / 100)
      ).toFixed(2)
    );
    //atualiza rappel
    vehicle.rappelWithTaxTotal = rappelTotal;

    vehicle.totalExtraWithoutTax =convertNumber((
        Number(vehicle.totalExtraWithTax) / (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.totalAccessoriesWithout =convertNumber((
        Number(vehicle.totalAccessoriesWith) / (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
   
    vehicle.proformaInvoicePriceWithoutTax = convertNumber((
        // convertNumber(vehicle.pvp) +
        // convertNumber(vehicle.totalAccessoriesWithout) +
        // convertNumber(vehicle.totalExtraWithoutTax) -
        // convertNumber(vehicle.discountWithTaxTotal) - 
        // convertNumber(vehicle.campaignWithTaxTotal)

        convertNumber(vehicle.priceexclVAT)
        + convertNumber(vehicle.luxury_tax)
        + convertNumber(vehicle.transport_x0026_immatriculation_cost)
        + convertNumber(vehicle.recycling_charge)

        + convertNumber(vehicle.totalAccessoriesWithout)
        + convertNumber(vehicle.totalExtraWithoutTax)

        - convertNumber(vehicle.discountWithTaxTotal) //envio valor
        // - convertNumber(vehicle.discountWithoutTax) //envio percentagem
        - convertNumber(vehicle.campaignWithTaxTotal)
      ).toFixed(2)
    );
    vehicle.proformaInvoicePriceWithTax =convertNumber((
        (
          convertNumber(vehicle.priceexclVAT)
          + convertNumber(vehicle.luxury_tax)
          + convertNumber(vehicle.transport_x0026_immatriculation_cost)
          + convertNumber(vehicle.recycling_charge)

          + convertNumber(vehicle.totalAccessoriesWithout)
          + convertNumber(vehicle.totalExtraWithoutTax)
          
          - convertNumber(vehicle.discountWithTaxTotal) //envio valor
          // - convertNumber(vehicle.discountWithoutTax) //envio percentagem
          - convertNumber(vehicle.campaignWithTaxTotal)
        ) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.pvp = convertNumber((
        convertNumber(vehicle.priceexclVAT) +
        convertNumber(vehicle.totalAccessoriesWithout) +
        convertNumber(vehicle.totalExtraWithoutTax) +
        convertNumber(vehicle.luxury_tax) +
        convertNumber(vehicle.transport_x0026_immatriculation_cost) +
        convertNumber(vehicle.recycling_charge)
      ).toFixed(2)
    );

    vehicle.businessValueWithoutTax =convertNumber((
        convertNumber(vehicle.priceexclVAT) +

        convertNumber(vehicle.totalAccessoriesWithout) +
        convertNumber(vehicle.totalExtraWithoutTax) +

        convertNumber(vehicle.luxury_tax) +
        convertNumber(vehicle.transport_x0026_immatriculation_cost) +
        convertNumber(vehicle.recycling_charge) -

        convertNumber(vehicle.rappelWithTaxTotal) -
        convertNumber(vehicle.extraRappel) -

        convertNumber(vehicle.discountWithTaxTotal) -  //envio valor
        // convertNumber(vehicle.discountWithoutTax) -  //envio percentagem
        convertNumber(vehicle.campaignWithTaxTotal)
      ).toFixed(2)
    );
    vehicle.businessValueWithTax =convertNumber((
        (
          convertNumber(vehicle.priceexclVAT) +

          convertNumber(vehicle.totalAccessoriesWithout) +
          convertNumber(vehicle.totalExtraWithoutTax) +

          convertNumber(vehicle.luxury_tax) +
          convertNumber(vehicle.transport_x0026_immatriculation_cost) +
          convertNumber(vehicle.recycling_charge) -

          convertNumber(vehicle.rappelWithTaxTotal) -
          convertNumber(vehicle.extraRappel) -

          convertNumber(vehicle.discountWithTaxTotal) - //envio valor
          // convertNumber(vehicle.discountWithoutTax) - //envio percentagem
          convertNumber(vehicle.campaignWithTaxTotal)
        ) * (1 + vehicle.vat / 100)
      ).toFixed(2)
    );
    vehicle.totalExtraWithTax=convertNumber(vehicle.totalExtraWithTax.toFixed(2));
    return vehicle;
  }

  const handleAddVehicleClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setVehicles((vehicles) => {
      return [
        ...vehicles,
        {
          id: 0,
          proposalId: 0,
          makeId: "",
          leaseQuoteNo:"",
          versionId: "",
          modelId: "",
          licensePlate: "",
          priceexclVAT: 0,
          consumersPrice: 0,
          pvp: 0,
          recycling_charge: 0,
          luxury_tax: 0,
          transport_x0026_immatriculation_cost: 0,
          status: "New",
          state: PreConfiguredVehicleState.New,
          use: "",
          numberofDoors: 0,
          fuelId: "100001",
          fuelType: "",
          euroTaxInternationalSegmt: "",
          quantity_of_Seats: 0,
          lengthmm: 0,
          widthmm: 0,
          heightmm: 0,
          trunkVolumel: 0,
          wheelBasemm: 0,
          trainFrontWheelsmm: 0,
          trainRearWheelsmm: 0,
          compressionratio: 0,
          bore: 0,
          powerHP: 0,
          powerkW: 0,
          cO2DischargeDecF: 0,
          engineCc: 0,
          torqueNm: 0,
          fuelTankCapacityl: 0,

          maxspeedkmh: 0,
          acceleration0100kmh: 0,
          mileage: 0,
          modelYear: "",
          recordDate: "",
          accessories: [],
          extraStandard: [],
          simulations: [],
          no: "",
          lotId: "",
          totalExtraWithoutTax: 0,
          totalAccessoriesWithout: 0,
          proformaInvoicePriceWithoutTax: 0,
          totalExtraWithTax: 0,
          totalAccessoriesWith: 0,
          proformaInvoicePriceWithTax: 0,
          discountWithTaxTotal: 0,
          rappelWithTaxTotal: 0,
          campaignWithTaxTotal: 0,
          businessValueWithoutTax: 0,
          businessValueWithTax: 0,
          discountWithTax: 0,
          discountWithoutTax: 0,
          discountWithTaxPercentage: 0,
          campaignWithTax: 0,
          campaignWithoutTax: 0,
          campaignWithTaxPercentage: 0,
          rappelWithTax: 0,
          rappelWithTaxPercentage: 0,
          rappelWithoutTax: 0,
          extraRappel: 0,
          extraOptionsSearch: [],
          extraOptionSelect: 0,
          versionDescription: "",
          caracteristics: "",
          quantity: 1,
          oilChange: 0,
          bPM: 0,
          luxury_tax_vat: 0,
          vat: 0,
          technMaxQuantityKm: 0,
          hidden: false,

          modelDescription: "",
          iuc:0,
          number_of_wheels:0,
          group_Price_Map:"",
          vehicleStatus: {
            color: "warning",
            label: "evaluating",
          },
          // campos mapeados no step 3
          commercialOptions: [],
          //tipo aluguer
          typeofRental: "",// currentUser && currentUser.typeofRental ? currentUser.typeofRental : "",
          // typeofRental: dataCard && dataCard.typeofRental ? dataCard.typeofRental : "",
          // condições comerciais
          commercialConditions: "",//currentUser && currentUser.commercialConditions ? currentUser.commercialConditions : "",
          // commercialConditions: dataCard && dataCard.commercialConditions ? dataCard.commercialConditions : "",
          //tipo de renda
          typeofIncome: "",//currentUser && currentUser.typeofIncome ? currentUser.typeofIncome : "",
          // typeofIncome: dataCard && dataCard.typeofIncome ? dataCard.typeofIncome : "",
            // paymentTerms[1].result[0],
          driverId: "", //FIXME susbtituir por nome do contato do user quando este estiver definido no user
          renew: false,
          driverName: "",
        },
      ];
    });
  },[/*currentUser*/]);

  const handleRemoveVehicleClick = useCallback((index: number) => {
    const vehicle = {...vehicles[index]};
    const vehicleId = vehicle?.id;
  

    if (vehicleId && vehicleId > 0) {
      //setChangedProposal(true);
      vehiclesService.deletePreConfigured(vehicleId).then(() => {
        // removeVehicle.splice(index, 1);
        setVehicles((rows) => {
          rows = rows.filter( (element,i) => i !== index)
          let item = vehicleSearchOptions.find( x => { return x.value ===vehicle.versionId });
          if(item) {
            updateSearchOptions(item,vehicle.versionId,rows)
          }

          // correção dos indexes dos extras após alteração do nº de viaturas
          rows.map((row,rowIndex) => {
            return row.extraStandard.map((extra, indexExtr) => {
              extra.valueTextFieldProps.name = "price-price_Excl_VAT-" + rowIndex + "-" + indexExtr;
              return extra;
            })
          })
          return rows
        });
      });
    } else {
      // removeVehicle.splice(index, 1);
      setVehicles((rows) => {
        rows = rows.filter( (element,i) => i !== index)
        let item = vehicleSearchOptions.find( x => { return x.value ===vehicle.versionId });
        if(item) {
          updateSearchOptions(item,vehicle.versionId,rows)
        }

        // correção dos indexes dos extras após alteração do nº de viaturas
        rows.map((row,rowIndex) => {
          return row.extraStandard.map((extra, indexExtr) => {
            extra.valueTextFieldProps.name = "price-price_Excl_VAT-" + rowIndex + "-" + indexExtr;
            return extra;
          })
        })
        return rows
      });
    }
  },[vehicles,vehicleSearchOptions, updateSearchOptions]);

  const handleSaveVehicleClick = useCallback((vehicle: IPreConfiguredVehicle, vehicleIndex: number) => {
    //const vehicle = {...vehicles[vehicleIndex]};
    vehiclesService.upsertPreConfiguredVehicle(vehicle).then((response) => {
      if(response.succeeded){
        setVehicles(rows => {
          return rows.map((row, index) => {
            if(index === vehicleIndex){
              let vehicle = response.value;
              vehicle.hidden = false;
              //await initExtraStandardDetails(vehicle) // ,response.value.vehicles.length);
              // por alguma razão não carrega a lista de compativeis em modo de edição
              let extrasSearch:Option[] = []
              let updatetotalExtraWithoutTax:number = 0;
              let updatetotalExtraWithTax:number = 0;
              row.extraStandard.map(x => {
                x.visible = true
                if (x.checkedExtra || (x.checkboxProps && x.checkboxProps.checked) ) {
                  updatetotalExtraWithoutTax += convertNumber(x.price_Excl_VAT);
                  updatetotalExtraWithTax += convertNumber(x.price);
                }
                return x;
              })
              if(vehicle.totalExtraWithTax !== updatetotalExtraWithTax && updatetotalExtraWithTax !== 0) {
                vehicle.totalExtraWithTax = updatetotalExtraWithTax;
              }
              if(vehicle.totalExtraWithoutTax !== updatetotalExtraWithoutTax && updatetotalExtraWithoutTax !== 0) {
                vehicle.totalExtraWithoutTax = updatetotalExtraWithoutTax;
              }

              vehicle.extraStandard.map((element: { description: any; optionTypeId: any ;manufactor_Code:any}) => {
                extrasSearch.push({
                  label:`${element.description} - ${element.manufactor_Code} - ${element.optionTypeId}`,
                  value: element.optionTypeId,
                });
                return element
              });
              vehicle.extraOptionsSearch = extrasSearch;
              row.extraOptionsSearch = vehicle.extraOptionsSearch;
              row.totalExtraWithoutTax = vehicle.totalExtraWithoutTax;
              row.totalExtraWithTax = vehicle.totalExtraWithTax;
            }
            return {...row};
          })
        });
      }
      else{
        openAlert({
          variant: "error",
          text: response.message,
          title: "Atenção",
        });
      }
    });
  },[/*vehicles*/]);

  const upsertVehicle = useCallback(async (vehicle: IPreConfiguredVehicle): Promise<APIResponse> => {
    return await vehiclesService.upsertPreConfiguredVehicle(vehicle);
  },[]);

  const handleAddAdvSearchSelectedVehicles_Click = useCallback( async (itemsToAdd:any) => {
    if (!itemsToAdd) return;
    //Carregamento de versões inativas de viaturas 
    let mustUpdateVersions = false;
    for (let i = 0; i < itemsToAdd.length; i++) {
      let itemToAdd:any = itemsToAdd[i];
      var inactiveVehicleTypeStatus: string = VehicleTypeStatus[VehicleTypeStatus.Inactive];
      if(itemToAdd.isInactive === inactiveVehicleTypeStatus) {
        const currentMakeIndex = versionsSearchOptions.findIndex(
          (x) => x.makeId === itemToAdd.makeId && x.modelId === itemToAdd.modelId
        );
        const hasInactiveVersion = versionsSearchOptions[currentMakeIndex].versions.some(v => v.value === itemToAdd.versionId);
        if(!hasInactiveVersion){
          if( versionsSearchOptions[currentMakeIndex].versions.filter(x => x.value === "").length===1){
             versionsSearchOptions[currentMakeIndex].versions = [{
              label: itemToAdd.versionDetails.description + '; ' + itemToAdd.versionDetails.caracteristics,
              value: itemToAdd.versionId,
              isInactive: true
            }]; 
          } else {
            versionsSearchOptions[currentMakeIndex].versions.push({
              label: itemToAdd.versionDetails.description + '; ' + itemToAdd.versionDetails.caracteristics,
              value: itemToAdd.versionId,
              isInactive: true
            }); 
          }
        }
        mustUpdateVersions = true;          
      }
    }
    if(mustUpdateVersions){
      setVersionsSearchOptions([...versionsSearchOptions]);
    }
    let totalUpsertedVehicles = 0;
    for (let i = 0; i < itemsToAdd.length; i++) {
      const versionDetails = (itemsToAdd[i] as any)?.versionDetails;
      const options = await vehiclesService.getOptionsType(versionDetails.no, null);
      let extras = [];
      let accessories = [];
      if (options.succeeded && options.value.length > 0) {
        extras = options.value.filter(
          (x: { general_with_this_Type: boolean }) =>
            x.general_with_this_Type === false
        );
        accessories = options.value.filter(
          (x: { general_with_this_Type: boolean }) =>
            x.general_with_this_Type === true
        );
      }
      let totalAccessories = 0;
      let totalAccessoriesVat = 0;
      if (accessories.length > 0) {
        accessories.forEach((item: { price: number }) => {
          totalAccessories += item.price;
        });
      }
      if (totalAccessories > 0) {
        totalAccessoriesVat =
          totalAccessories + totalAccessories * (1 + versionDetails.vat / 100);
      }
      // vehicleDataTable.push({
      let vehicle: IPreConfiguredVehicle = {
        id: 0,
        makeId: versionDetails.make,
        leaseQuoteNo:"",
        versionId: versionDetails.no,
        modelId: versionDetails.modelNo,
        priceexclVAT: versionDetails.priceexclVAT,
        consumersPrice: versionDetails.consumersPrice,
        recycling_charge: versionDetails.recycling_charge,
        luxury_tax: versionDetails.luxury_tax,
        transport_x0026_immatriculation_cost:
          versionDetails.transport_x0026_immatriculation_cost,
        status: versionDetails.status,
        use: versionDetails.use,
        pvp: 0,
        numberofDoors: versionDetails.numberofDoors,
        fuelId: versionDetails.fuelId,
        fuelType: versionDetails.fuelType,
        euroTaxInternationalSegmt: versionDetails.euroTaxInternationalSegmt,
        quantity_of_Seats: versionDetails.quantity_of_Seats,
        lengthmm: versionDetails.lengthmm,
        widthmm: versionDetails.widthmm,
        heightmm: versionDetails.hightmm,
        trunkVolumel: versionDetails.trunkVolumel,
        wheelBasemm: versionDetails.wheelBasemm,
        trainFrontWheelsmm: versionDetails.trainFrontWheelsmm,
        trainRearWheelsmm: versionDetails.trainRearWheelsmm,
        compressionratio: versionDetails.compressionratio,
        bore: versionDetails.bore,
        powerHP: versionDetails.powerHP,
        powerkW: versionDetails.powerkW,
        cO2DischargeDecF: versionDetails.cO2DischargeDecF,
        engineCc: versionDetails.engineCc,
        torqueNm: versionDetails.torqueNm,
        fuelTankCapacityl: versionDetails.fuelTankCapacityl,
        maxspeedkmh: versionDetails.maxspeedkmh,
        acceleration0100kmh: versionDetails.acceleration0100kmh,
        number_of_wheels: versionDetails.number_of_wheels,
        licensePlate: "",
        mileage: 0,
        modelYear: "",
        recordDate: "0001-01-01T00:00:00",
        lotId: (itemsToAdd[i].associateLotTextFieldProps.value as string) || "",
        totalExtraWithoutTax: 0,
        totalAccessoriesWithout: totalAccessories,
        proformaInvoicePriceWithoutTax: 0,
        totalExtraWithTax: 0,
        totalAccessoriesWith: totalAccessoriesVat,
        proformaInvoicePriceWithTax: 0,
        discountWithTaxTotal: 0,
        rappelWithTaxTotal: 0,
        campaignWithTaxTotal: 0,
        businessValueWithoutTax: 0,
        businessValueWithTax: 0,
        discountWithTax: 0,
        discountWithoutTax: 0,
        discountWithTaxPercentage: 0,
        campaignWithTax: 0,
        campaignWithoutTax: 0,
        campaignWithTaxPercentage: 0,
        rappelWithTax: 0,
        rappelWithTaxPercentage: 0,
        rappelWithoutTax: 0,
        extraRappel: 0,
        vat: versionDetails.vat,
        luxury_tax_vat:
          versionDetails.luxury_tax +
          versionDetails.luxury_tax * versionDetails.vat,
        no: versionDetails.no,
        accessories: accessories,
        extraStandard: extras,
        extraOptionSelect: 0,
        extraOptionsSearch: [],
        simulations: [],
        versionDescription: `${versionDetails.make} - ${versionDetails.description}`,
        caracteristics: "",
        quantity: 1,
        typeofRental: currentUser!.typeofRental,
        commercialConditions: currentUser!.commercialConditions,
        typeofIncome: currentUser?.typeofIncome ? currentUser.typeofIncome : "",
        oilChange: versionDetails.oilChange,
        bPM: versionDetails.bPM,
        technMaxQuantityKm: versionDetails.technMaxQuantityKm,
        driverId: "",
        renew: false,
        hidden: false,
        commercialOptions: [],
        group_Price_Map:versionDetails.group_Price_Map,
        modelDescription: versionDetails.modelDescription,
        iuc:versionDetails.iuc,
        //quotationProps
        vehicleStatus: {
          label: "Criado",
          color: "info",
        },
        //listagem de propostas
        driverName: "",
        state: PreConfiguredVehicleState.New,
      };
      // });

      await initExtraStandardDetails(vehicle); //vehicleDataTable.length); ???
      let option: Option = {
        label: `${versionDetails.make}; ${versionDetails.description}; ${versionDetails.caracteristics}`,
        value: versionDetails.no,
      };
      // vehicleDataTable[vehicleDataTable.length-1] =  updateValuesVehicle(vehicleDataTable[vehicleDataTable.length-1]);
      vehicle = updateValuesVehicle(vehicle,"");
      updateSearchOptions(option, "", vehicles);
      const upsertResult = await upsertVehicle(vehicle);
      if(upsertResult.succeeded){
        totalUpsertedVehicles++;
        vehicle.id = upsertResult.value.id;
        setVehicles((rows) => {
          rows.push(vehicle);
          updateSearchOptions(option, "", rows);
          return [...rows];
        });
      }
    }
    if(totalUpsertedVehicles === itemsToAdd.length){
      openAlert({
        variant: "success",
        text: "Viaturas adicionadas com sucesso",
        title: "Sucesso",
      }); 
    } else {
      if(totalUpsertedVehicles > 0){
        openAlert({
          variant: "error",
          text: `Não foi possivel adicionar ${(itemsToAdd.length - totalUpsertedVehicles)} viaturas. Por favor, tente novamente.`,
          title: "Atenção",
        }); 
      } else {
        openAlert({
          variant: "error",
          text: "Ocorreu um erro ao adicionar as viaturas. Por favor, tente novamente.",
          title: "Atenção",
        }); 
      }
    }
  }, [upsertVehicle, versionsSearchOptions, currentUser, initExtraStandardDetails, updateSearchOptions, vehicles]);

  const {
    filterDrawerProps,
    // brandsSectionProps,
    setBrandsSectionProps
  } = useAdvVehicleFilterHook(settings, vehiclesService, handleAddAdvSearchSelectedVehicles_Click, openAlert);

  const handleDuplicateVehicleClick= useCallback((vehicleIndex:number) => {
    const vehicleToDuplicate = vehicles[vehicleIndex] as any;
    var duplicatedVehicle = JSON.parse(
      JSON.stringify(vehicleToDuplicate)
    );
    duplicatedVehicle.id = 0;
    duplicatedVehicle.accessories.forEach((item: { id: number; }) => item.id = 0);
    duplicatedVehicle.extraStandard.forEach((item: { id: number; }) => item.id = 0);
    //add an element to vehicles and set the state
    setVehicles((rows) => {
      return [...rows, duplicatedVehicle];
    });
  },[vehicles])

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const { vehicleGroupEditModal } = useVehicleGroupEditModalHook( selectedRows, setVehicles, vendorsMemo); // (selected, setVehicles, setChangedProposal, vendorsMemo)
  const doNothing = () => {
    alert("Funcionalidade em desenvolvimento");
  };

  // const proformaFilesProgressBarProps: ProgressBarProps[] = [
  //   {
  //     progress: 55,
  //     title: 'nome-ficheiro.pdf',
  //     label: 'Label',
  //     deleteIcon: true,
  //     deleteTooltipText: 'Apagar',
  //   },
  //   {
  //     progress: 70,
  //     title: 'nome-ficheiro.pdf',
  //     label: 'Label',
  //     deleteTooltipText: 'Apagar',
  //   },
  //   {
  //     progress: 100,
  //     title: 'nome-ficheiro.pdf',
  //     label: 'Label',
  //     deleteIcon: true,
  //     deleteTooltipText: 'Apagar',
  //   },
  //   {
  //     progress: 55,
  //     title: 'nome-ficheiro.pdf',
  //     label: '12MB/28MB',
  //     size: 'small',
  //     deleteIcon: true,
  //     deleteTooltipText: 'Apagar',
  //   },
  //   {
  //     progress: 20,
  //     title: 'nome-ficheiro.pdf',
  //     label: 'Erro',
  //     size: 'small',
  //     error: true,
  //     deleteIcon: true,
  //     deleteTooltipText: 'Apagar',
  //   },
  // ];

  const populateFunctionalGroupVehicleBrands = useCallback((
    functionalGroupsMakes: string[],
    functionalGroupsModels:string[],
    functionalGroupsVersionTypes: FunctionalGroupsVersionType[]
  ) => {
    vehiclesService.getFunctionalGroupBrands(functionalGroupsMakes, functionalGroupsModels).then((data) => {
      if(data && data.succeeded){
        const items = convertToExpandableMakesOptions(data.value);
        //cria as opções para alimentar o dropdown de modelos.
        let modelsSearchOptionsData: IModel[] = [{
          makeId: "",
          models: [{ label: "", value: "" }],
        }];
        data.value.map((item: { id: string,models:[{ id: string, description: string, active: boolean }] }) => {
          let modelsMake:[{label:string, value:string}]=[{ label: "", value: "" }];
  
          item.models.forEach((element) => {
            // if (element.active) {
            modelsMake.push({
              label: element.description,
              value: element.id
            });
            // }
            setVersionsSearchOptions((rows) => [
              ...rows,
              {
                  makeId: item.id,
                  modelId: element.id,
                  versions: [{ label: "", value: "", isInactive: false }],
              }
            ]);
          });
          modelsMake.splice(0,1);
          
          // 
          modelsSearchOptionsData.push({
            makeId: item.id,
            models: modelsMake.length > 0 ? modelsMake : [{ label: "", value: "" }]

          })
          setModelsSearchOptions(modelsSearchOptionsData);
          return item;
        });
        // alimenta as opções das marcas 
        setBrandsSectionProps(items);
        setMakesSearchOptions(items);


        vehiclesService.getPreConfiguredVehicles(null)
        .then((response:APIResponse) => {
          if(response.succeeded){
            if(response.value.length > 0){
              let vehicles:IPreConfiguredVehicle[] = response.value;
              // caso o perfil associado ao utilizador tenha sido alterado e este tenha viaturas associadas
              // a grupos funcionais de outros perfis, só serão tratadas as viaturas associadas ao perfil actual.
              // as viaturas são filtradas com base na marca e no model.
              vehicles = vehicles.filter(vehicle => {
                return items.some((item: any) => 
                  item.label === vehicle.makeId && 
                  item.models.some((model:any) => model.id === vehicle.modelId)
                );
              });
              // fim filtro

              // Promise.all(response.value.map(async (vehicle:IPreConfiguredVehicle) => {
              Promise.all(vehicles.map(async (vehicle:IPreConfiguredVehicle) => {
                vehicle.hidden = false;
                let extras:IPreConfiguredExtraStandard[] = JSON.parse(JSON.stringify(vehicle.extraStandard));

                let response = await vehiclesService.getOptionsType(vehicle.versionId,false);
                if (!response.succeeded) {
                  openAlert({
                    variant: "error",
                    text: `Não foi possível carregar os extras da viatura ${vehicle.versionDescription}! Por favor tente novamente. Se o problema persistir, contate o administrador.`,
                    title: "Atenção",
                  });
                } else {
                  let extrasStandardTemp = vehicle.extraStandard;
                  if (extrasStandardTemp.length > 0){
                    vehiclesService.deleteExtrasStandard(extrasStandardTemp);
                  }
                  let extrasSearch: Option[];
                  extrasSearch = [];
                  vehicle.totalExtraWithoutTax =0;
                  vehicle.totalExtraWithTax = 0;
            
                  let index = vehicles.findIndex(x => x.id === vehicle.id)
                  if(index === -1){
                    index = 0;
                  }
                  let disable = vehicle.licensePlate ? true :false;
                  response.value.forEach((element:any,indexExtr:number) => {
                    //lista de pesquisa de extras
                    extrasSearch.push({
                      label: `${element.description} - ${element.manufactor_Code} - ${element.optionTypeId}`,
                      value: element.optionTypeId,
                    });
                    vehicle.extraStandard.forEach((frequentExtra) => {
                      if (frequentExtra.optionTypeId === element.optionTypeId) {
                        element.checkedExtra = frequentExtra.checkedExtra
                        element.visible = true
                        if(frequentExtra.checkedExtra === true){
                          vehicle.totalExtraWithTax = vehicle.totalExtraWithTax + element.price
                          vehicle.totalExtraWithoutTax = vehicle.totalExtraWithoutTax + element.price_Excl_VAT
                        }
                      }
                    })
                    //esturtura ExtraStandard do portal
                    element.group = element.main_Group_Allocation ? element.main_Group_Allocation : ""
                    element.groupLabel = element.descGroup ? element.descGroup :""
                    element.code = element.optionTypeId
                    element.label = element.description
                    element.valueTextFieldProps = {
                      disabled: disable,
                      name: "price-price_Excl_VAT-" + index + "-" + indexExtr, //name: `price-${index}-${indexExtr}`,
                      value: element.price_Excl_VAT, 
                      //onChange: handleExtraStandardTextFieldChange,
                      onInput: handleExtraStandardTextFieldChange,
                    }
                    element.checkboxProps = {
                      disabled: disable,
                      checked: element.checkedExtra,
                      name:element.optionTypeId
                    }
                    element.lazyload = true
                    element.requiredCodes = [] // => obrigatórios
                    element.incompatibilityCodes = [] // incompatibilidade de extras
                    element.includes = [] // => packs de extras
            
                    switch (element.inclusion_Type) {
                      case 'Obligatory':
                        element.requiredCodes = element.compatibleExtrasList
                        break;
                      case 'Inclusion':
                        element.requiredCodes = element.compatibleExtrasList
                        break;
                      case 'Exclusion':
                        // temp fix
                        let incompatibleExtrasTmp:string[] = []
                        element.incompatibleExtrasList.forEach((incompatible:any) => {
                          incompatible.forEach((value:any) => {
                            incompatibleExtrasTmp.push(value)
                          });
                        });
                        element.incompatibilityCodes = incompatibleExtrasTmp
                        // end temp fix
                        // aux[aux.length-1].incompatibilityCodes = item.incompatibleExtrasList
                        break;
                    
                      default:
                        break;
                    }
                    // Package
                    if(element.package === 'Yes') {
                      let packageExtras:{
                        label:string,
                        code:string
                      }[] = [];
              
                      element.compatibleExtrasList.forEach((value:string) => {
                        let compatibleExtra:IPreConfiguredExtraStandard = response.value.find((x:IPreConfiguredExtraStandard) => x.optionTypeId === value)
                        packageExtras.push({
                          label: compatibleExtra?.description ?? value ,
                          code: compatibleExtra?.optionTypeId ?? value
                        })
                      })
                      element.includes = packageExtras;
                      element.requiredCodes = []
                    } else {
                      element.includes = []
                    }
                  });
                  vehicle.extraStandard = response.value;
                  vehicle.extraOptionsSearch = extrasSearch;
                }

                // por alguma razão não carrega a lista de compativeis em modo de edição
                let extrasSearch:Option[] = []
                let updatetotalExtraWithoutTax = 0
                let updatetotalExtraWithTax = 0
                vehicle.extraStandard.map(x => {
                  x.visible = true
                  extras.forEach(extra => {
                    if (extra.optionTypeId === x.optionTypeId){
                      x.id = extra.id;
                      x.vehicleId = extra.preConfiguredVehicleId;
                      if(x.price_Excl_VAT !== extra.price_Excl_VAT){
                        x.price_Excl_VAT = extra.price_Excl_VAT;
                        x.valueTextFieldProps.value = extra.price_Excl_VAT;
                      }
                      if(x.price !== extra.price){
                        x.price = extra.price;
                      }
                    }
                  });
                  if (x.checkedExtra || (x.checkboxProps && x.checkboxProps.checked) ) {
                    updatetotalExtraWithoutTax += x.price_Excl_VAT;
                    updatetotalExtraWithTax += x.price;
                  }
                  return x;
                })
                if(vehicle.totalExtraWithTax !== updatetotalExtraWithTax && updatetotalExtraWithTax !== 0) {
                  vehicle.totalExtraWithTax = updatetotalExtraWithTax;
                }
                if(vehicle.totalExtraWithoutTax !== updatetotalExtraWithoutTax && updatetotalExtraWithoutTax !== 0) {
                  vehicle.totalExtraWithoutTax = updatetotalExtraWithoutTax;
                }

                vehicle.extraStandard.map((element: { description: any; optionTypeId: any ;manufactor_Code:any}) => {
                  extrasSearch.push({
                    label:`${element.description} - ${element.manufactor_Code} - ${element.optionTypeId}`,
                    value: element.optionTypeId,
                  });
                  return element
                });
                vehicle.extraOptionsSearch = extrasSearch;

                const filters = VehicleTypeStatusHelper.generateAdvancedFilterVersionsOptions(true);
                filters.push({
                  type: AdvancedFilterVersionsOptions.Make,
                  value: vehicle.makeId,
                });
                filters.push({
                  type: AdvancedFilterVersionsOptions.Model,
                  value: vehicle.modelId,
                });
                //vehicles.push(vehicle);
                await vehiclesService.getVersionsToCombo(filters).then(versionsCombo => {
                  if (versionsCombo.value.length > 0) {
                    if (versionsCombo.value !== undefined && versionsCombo.value[0] !== undefined) {
                      let option: Option | undefined = {
                        label: "",
                        value: "",
                      };
                      const makeVersions = versionsCombo.value.map(
                        (item: {
                          label: string;
                          value: string;
                          isInactive: boolean;
                        }) => {
                          return {
                            label: item.label,
                            value: item.value,
                            isInactive: false
                          };
                        }
                      );                
                      if (makeVersions && makeVersions.length > 0) {
                        setVersionsSearchOptions((rows) =>
                          rows.map((cur, index) => {
                            if (cur.makeId === vehicle?.makeId && cur.modelId===vehicle?.modelId) {
                              //var inactiveVehicleTypeStatus: string = VehicleTypeStatus[VehicleTypeStatus.Inactive];
                              if(vehicle !== undefined) {                          
                                cur.versions=makeVersions;                                  
                                const hasInactiveVersion = cur.versions.some((v: { value: string; }) => v.value === vehicle?.versionId);
                                if(!hasInactiveVersion) {
                                  if( cur.versions.filter((x: { value: string; }) => x.value === "").length===1){
                                    cur.versions = [{
                                      label:`${vehicle?.modelDescription}; ${vehicle?.caracteristics}`,
                                      value: vehicle.versionId,
                                      isInactive: true
                                    }]; 
                                  }
                                  else{
                                    cur.versions.push({ 
                                      label: `${vehicle?.modelDescription}; ${vehicle?.caracteristics}`,
                                      value: vehicle.versionId,
                                      isInactive: true
                                    }); 
                                  }
                                }
                                option = {
                                  label: `${vehicle.versionDescription}; ${vehicle.caracteristics}`,
                                  value: vehicle.versionId ,
                                }
                                updateSearchOptions(option , '', vehicles);//  xxxx
                              }  
                            }
                            return cur;
                          })
                        );
                      }                                
                    }
                  } 
                });
                return vehicle
              })).finally(() => {
                //setVehicles(vehicles);
              })
              setVehicles(vehicles);
            }          
          }
        });  
      }
    });
  },[
    convertToExpandableMakesOptions,
    setBrandsSectionProps,
    handleExtraStandardTextFieldChange,
    updateSearchOptions
  ])

  useEffect(() => {
    let functionGroups: string[] = [];
    functionGroups = allowedValues(functionGroups, 'functional groups');
    if(currentUser.profile) {
      setIsFilterByFunctionalGroups(true);
      profileService.getFunctionalGroupVehicles(functionGroups).then((response) => {
        if(response){
          if(response.succeeded){
            let functionalGroupsMakesData: string[] = [];
            let functionalGroupsModelsData: string[] = [];
            let functionalGroupsVersionTypesData: FunctionalGroupsVersionType[] = [];
            response.value.forEach((element: FunctionalGroupVehicle) => {
              if(!functionalGroupsMakesData.some(x => x === element.make)){
                functionalGroupsMakesData.push(element.make);
              }
              if(!functionalGroupsModelsData.some(x => x === element.model)){
                functionalGroupsModelsData.push(element.model);
              }
              if(!functionalGroupsVersionTypesData.some(x => x.versionType === element.model_Type)){
                functionalGroupsVersionTypesData.push({
                  function_Group: element.function_Group,
                  versionType: element.model_Type,
                  campaign: element.campaign,
                  discountPercentage: element.discount_Percent,
                  rappel: element.rappel,
                  transport_x0026_immatriculation_cost: element.transport_x0026_immatriculation_cost,
                  contractExpenses: element.contract_Expenses
                });
              }

            });
            setFunctionalGroupsMakes(functionalGroupsMakesData);
            setFunctionalGroupsModels(functionalGroupsModelsData);
            setFunctionalGroupsVersionTypes(functionalGroupsVersionTypesData);

            populateFunctionalGroupVehicleBrands(functionalGroupsMakesData,functionalGroupsModelsData,functionalGroupsVersionTypesData);

          }
        }
      });
    } else {
      vehiclesService.getBrands().then((data) => {
        
        const items = convertToExpandableMakesOptions(data);
        data.map((item: { id: string,models:[{ id: string, description: string, active: boolean }] }) => {
          let modelsMake:[{label:string, value:string}]=[{ label: "", value: "" }];

          item.models.forEach((element) => {
            if (element.active) {
              modelsMake.push({
                label: element.description, 
                value: element.id
              });
            }
            setVersionsSearchOptions((rows) => [
              ...rows,
              {
                  makeId: item.id,
                  modelId:element.id,
                  versions: [{ label: "", value: "", isInactive: false }],
              }
            ]);  
          });
          modelsMake.splice(0,1);
        
          setModelsSearchOptions((rows) => [
            ...rows,
            {
              makeId: item.id,
              models: modelsMake.length > 0 ? modelsMake : [{ label: "", value: "" }]
            }
          ]);
          return item;
        });

        setBrandsSectionProps(items);
        setMakesSearchOptions(items);

        vehiclesService.getPreConfiguredVehicles(null)
        .then((response:APIResponse) => {
          if(response.succeeded){
            if(response.value.length > 0){
              let vehicles:IPreConfiguredVehicle[] = response.value;
              Promise.all(response.value.map(async (vehicle:IPreConfiguredVehicle) => {
                vehicle.hidden = false;
                let extras:IPreConfiguredExtraStandard[] = JSON.parse(JSON.stringify(vehicle.extraStandard));

                //await initExtraStandardDetails(vehicle) // ,response.value.vehicles.length);

                let response = await vehiclesService.getOptionsType(vehicle.versionId,false);
                if (!response.succeeded) {
                  openAlert({
                    variant: "error",
                    text: `Não foi possível carregar os extras da viatura ${vehicle.versionDescription}! Por favor tente novamente. Se o problema persistir, contate o administrador.`,
                    title: "Atenção",
                  });
                } else {
                  let extrasStandardTemp = vehicle.extraStandard;
                  if (extrasStandardTemp.length > 0){
                    vehiclesService.deleteExtrasStandard(extrasStandardTemp);
                  }
                  let extrasSearch: Option[];
                  extrasSearch = [];
                  vehicle.totalExtraWithoutTax =0;
                  vehicle.totalExtraWithTax = 0;
            
                  let index = vehicles.findIndex(x => x.id === vehicle.id)
                  if(index === -1){
                    index = 0;
                  }
                  let disable = vehicle.licensePlate ? true :false;
                  response.value.forEach((element:any,indexExtr:number) => {
                    //lista de pesquisa de extras
                    extrasSearch.push({
                      label: `${element.description} - ${element.manufactor_Code} - ${element.optionTypeId}`,
                      value: element.optionTypeId,
                    });
                    vehicle.extraStandard.forEach((frequentExtra) => {
                      if (frequentExtra.optionTypeId === element.optionTypeId) {
                        element.checkedExtra = frequentExtra.checkedExtra
                        element.visible = true
                        if(frequentExtra.checkedExtra === true){
                          vehicle.totalExtraWithTax = vehicle.totalExtraWithTax + element.price
                          vehicle.totalExtraWithoutTax = vehicle.totalExtraWithoutTax + element.price_Excl_VAT
                        }
                      }
                    })
                    //esturtura ExtraStandard do portal
                    element.group = element.main_Group_Allocation ? element.main_Group_Allocation : ""
                    element.groupLabel = element.descGroup ? element.descGroup :""
                    element.code = element.optionTypeId
                    element.label = element.description
                    element.valueTextFieldProps = {
                      disabled: disable,
                      name: "price-price_Excl_VAT-" + index + "-" + indexExtr, //name: `price-${index}-${indexExtr}`,
                      value: element.price_Excl_VAT, 
                      //onChange: handleExtraStandardTextFieldChange,
                      onInput: handleExtraStandardTextFieldChange,
                    }
                    element.checkboxProps = {
                      disabled: disable,
                      checked: element.checkedExtra,
                      name:element.optionTypeId
                    }
                    element.lazyload = true
                    element.requiredCodes = [] // => obrigatórios
                    element.incompatibilityCodes = [] // incompatibilidade de extras
                    element.includes = [] // => packs de extras
            
                    switch (element.inclusion_Type) {
                      case 'Obligatory':
                        element.requiredCodes = element.compatibleExtrasList
                        break;
                      case 'Inclusion':
                        element.requiredCodes = element.compatibleExtrasList
                        break;
                      case 'Exclusion':
                        // temp fix
                        let incompatibleExtrasTmp:string[] = []
                        element.incompatibleExtrasList.forEach((incompatible:any) => {
                          incompatible.forEach((value:any) => {
                            incompatibleExtrasTmp.push(value)
                          });
                        });
                        element.incompatibilityCodes = incompatibleExtrasTmp
                        // end temp fix
                        // aux[aux.length-1].incompatibilityCodes = item.incompatibleExtrasList
                        break;
                    
                      default:
                        break;
                    }
                    // Package
                    if(element.package === 'Yes') {
                      let packageExtras:{
                        label:string,
                        code:string
                      }[] = [];
              
                      element.compatibleExtrasList.forEach((value:string) => {
                        let compatibleExtra:IPreConfiguredExtraStandard = response.value.find((x:IPreConfiguredExtraStandard) => x.optionTypeId === value)
                        packageExtras.push({
                          label: compatibleExtra?.description ?? value ,
                          code: compatibleExtra?.optionTypeId ?? value
                        })
                      })
                      element.includes = packageExtras;
                      element.requiredCodes = []
                    } else {
                      element.includes = []
                    }
                  });
                  vehicle.extraStandard = response.value;
                  vehicle.extraOptionsSearch = extrasSearch;
                }


                // por alguma razão não carrega a lista de compativeis em modo de edição
                let extrasSearch:Option[] = []
                let updatetotalExtraWithoutTax = 0
                let updatetotalExtraWithTax = 0
                vehicle.extraStandard.map(x => {
                  x.visible = true
                  extras.forEach(extra => {
                    if (extra.optionTypeId === x.optionTypeId){
                      x.id = extra.id;
                      x.vehicleId = extra.preConfiguredVehicleId;
                      if(x.price_Excl_VAT !== extra.price_Excl_VAT){
                        x.price_Excl_VAT = extra.price_Excl_VAT;
                        x.valueTextFieldProps.value =extra.price_Excl_VAT;
                      }
                      if(x.price !== extra.price){
                        x.price = extra.price;
                      }
                    }
                  });
                  if (x.checkedExtra || (x.checkboxProps && x.checkboxProps.checked) ) {
                    updatetotalExtraWithoutTax += x.price_Excl_VAT;
                    updatetotalExtraWithTax += x.price;
                  }
                  return x;
                })
                if(vehicle.totalExtraWithTax !== updatetotalExtraWithTax && updatetotalExtraWithTax !== 0) {
                  vehicle.totalExtraWithTax = updatetotalExtraWithTax;
                }
                if(vehicle.totalExtraWithoutTax !== updatetotalExtraWithoutTax && updatetotalExtraWithoutTax !== 0) {
                  vehicle.totalExtraWithoutTax = updatetotalExtraWithoutTax;
                }

                vehicle.extraStandard.map((element: { description: any; optionTypeId: any ;manufactor_Code:any}) => {
                  extrasSearch.push({
                    label:`${element.description} - ${element.manufactor_Code} - ${element.optionTypeId}`,
                    value: element.optionTypeId,
                  });
                  return element
                });
                vehicle.extraOptionsSearch = extrasSearch;

                const filters = VehicleTypeStatusHelper.generateAdvancedFilterVersionsOptions(true);
                filters.push({
                  type: AdvancedFilterVersionsOptions.Make,
                  value: vehicle.makeId,
                });
                filters.push({
                  type: AdvancedFilterVersionsOptions.Model,
                  value: vehicle.modelId,
                });
                //vehicles.push(vehicle);
                await vehiclesService.getVersionsToCombo(filters).then(versionsCombo => {
                  if (versionsCombo.value.length > 0) {
                    if (versionsCombo.value !== undefined && versionsCombo.value[0] !== undefined) {
                      let option: Option | undefined = {
                        label: "",
                        value: "",
                      };
                      const makeVersions = versionsCombo.value.map(
                        (item: {
                          label: string;
                          value: string;
                          isInactive: boolean;
                        }) => {
                          return {
                            label: item.label,
                            value: item.value,
                            isInactive: false
                          };
                        }
                      );                
                      if (makeVersions && makeVersions.length > 0) {
                        setVersionsSearchOptions((rows) =>
                          rows.map((cur, index) => {
                            if (cur.makeId === vehicle?.makeId && cur.modelId===vehicle?.modelId) {
                              //var inactiveVehicleTypeStatus: string = VehicleTypeStatus[VehicleTypeStatus.Inactive];
                              if(vehicle !== undefined) {                          
                                cur.versions=makeVersions;                                  
                                const hasInactiveVersion = cur.versions.some((v: { value: string; }) => v.value === vehicle?.versionId);
                                if(!hasInactiveVersion) {
                                  if( cur.versions.filter((x: { value: string; }) => x.value === "").length===1){
                                    cur.versions = [{
                                      label:`${vehicle?.modelDescription}; ${vehicle?.caracteristics}`,
                                      value: vehicle.versionId,
                                      isInactive: true
                                    }]; 
                                  }
                                  else{
                                    cur.versions.push({ 
                                      label: `${vehicle?.modelDescription}; ${vehicle?.caracteristics}`,
                                      value: vehicle.versionId,
                                      isInactive: true
                                    }); 
                                  }
                                }
                                option = {
                                  label: `${vehicle.versionDescription}; ${vehicle.caracteristics}`,
                                  value: vehicle.versionId ,
                                }
                                updateSearchOptions(option , '', vehicles);//  xxxx
                              }  
                            }
                            return cur;
                          })
                        );
                      }                                
                    }
                  } 
                });
                return vehicle
              })).finally(()=>{
                //setVehicles(vehicles);
              })
              setVehicles(vehicles);
            }          
          }
        });  
      });
    }
  }, [
    initExtraStandardDetails, updateSearchOptions,
    setBrandsSectionProps, handleExtraStandardTextFieldChange,
    convertToExpandableMakesOptions, allowedValues, currentUser,
    populateFunctionalGroupVehicleBrands
  ]);


  //#region SK-838 Código para implementação pendente de aprovação
  // const [fileProgressBarList, setFileProgressBarList]= useState< ProgressBarProps[]>([]);

  // const handleAttachFiles = useCallback((e: ChangeEvent<HTMLInputElement>): ProgressBarProps[] => {
  //   let progressBarList: ProgressBarProps[] = [];// fileProgressBarList;
  //   const fileExtenstionList:string[] = ['jpg', 'jpeg', 'png', 'doc', 'pdf', 'gif', 'zip', 'rar', 'txt', 'xls', 'docx', 'xlsx'];
  //   if ( e.target.files && e.target.files.length > 0) {
  //     Array.from(e.target.files).forEach((file) => {
  //       let fileType: string = file.name.split(".").pop()!;
  //       if (file.size > 2097152) {
  //         openAlert({
  //           variant: "info",
  //           text: "O Tamanho do ficheiro é superior a 2MB. Por favor introduza um ficheiro dentro do limite de tamanho. ",
  //           title: "Atenção",
  //           autoHideDuration: 3000,
  //         });
  //       } else if ( !fileExtenstionList.includes(fileType) ) {
  //         openAlert({
  //           variant: "info",
  //           text: "O tipo de ficheiro não é permitido. Por favor introduza um ficheiro com um tipo de ficheiro permitido. ",
  //           title: "Atenção",
  //           autoHideDuration: 3000,
  //         });
  //       } else { 
  //         let size = ''; 
  //         switch (true) {
  //           case (file.size < 1024):
  //             size = `${file.size} B`;
  //             break;
  //           case (file.size > 1024 && file.size < 1048576):
  //             size = `${(file.size / 1024).toFixed(2)} KB`;
  //           break;
  //           case (file.size > 1048576 && file.size < 1073741824):
  //             size = `${(file.size / 1048576).toFixed(2)} MB`;
  //             break;
          
  //           default:
  //             break;
  //         }
          
  //         const currentFileprogressBarProps: ProgressBarProps = {
  //           progress: 0,
  //           title: file.name,
  //           label: size,
  //           deleteIcon: true,
  //           deleteTooltipText: "Apagar",
  //         };
  //         if (file.size <= 2097152) {
  //           progressBarList.push(currentFileprogressBarProps);          
  //         }
  //       }
  //     });
  //   }
  //   setFileProgressBarList(progressBarList);
  //   return progressBarList;
  // },[]);

  // const handleAttachFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) =>  {
  //   if (e.target.files) {
  //     let attachmentsList: ProgressBarProps[] = [];
  //     attachmentsList = handleAttachFiles(e);
  //     // serviço para fazer upload para a api e mediante o resultado colocar o progresso a 100% e definir o evento para apagar        
  //     //instantiate progress bar variable
  //     let progressBarProps: ProgressBarProps = {
  //       progress: 0,
  //       title: "",
  //       label: "",
  //       deleteIcon: true,
  //       deleteTooltipText: "Apagar",
  //     };

  //     // metodo para testar o progresso do upload de ficheiros
  //     // const updateProgress = (index, progress) => {
  //     //   // Update progress in attachmentsList
  //     //   attachmentsList[index].progress = progress;
  //     //   // Update the state here to trigger re-render, if necessary
  //     //   // For example: setAttachments([...attachmentsList]);
  //     // };
  
  //     // const uploadFile = (file, index) => {
  //     //   const formData = new FormData();
  //     //   formData.append("file", file); // Assuming the file is attached like this
  
  //     //   axios.post("YOUR_API_ENDPOINT", formData, {
  //     //     headers: {
  //     //       'Content-Type': 'multipart/form-data'
  //     //     },
  //     //     onUploadProgress: (progressEvent) => {
  //     //       const progress = (progressEvent.loaded / progressEvent.total) * 100;
  //     //       updateProgress(index, progress);
  //     //     }
  //     //   })
  //     //   .then(response => {
  //     //     // Handle successful upload
  //     //     updateProgress(index, 100); // Ensure progress is set to 100% on success
  //     //   })
  //     //   .catch(error => {
  //     //     // Handle error
  //     //     console.error("Upload error", error);
  //     //   });
  //     // };
  
  //     // // Call uploadFile for each file
  //     // attachmentsList.forEach((attachment, index) => {
  //     //   uploadFile(attachment.file, index); // Assuming `attachment` has a `file` property
  //     // });

      
  //     // se o serviço devolver o estado que não foi possivel fazer upload
  //     // enviar mensagem de erro para o utilizador
  //     // } else {
  //     //   console.log("no attachments uploaded");
  //     //   //Notificar utilizador
  //     //   openAlert({
  //     //     variant: "error",
  //     //     text: "Não foi possivel anexar o documento.",
  //     //     title: "Aviso",
  //     //   });
  //     // }
  //   }
  // },[handleAttachFiles]);
  //#endregion
  return (
    <>
      <BackofficePreconfiguredVehiclesPage

        topBarProps={topBarPropsMemo}
        dashboardMenuButtonProps={dashboardMenuButtonPropsMemo}
        proposalMenuButtonProps={proposalMenuButtonPropsMemo}
        clientsMenuButtonProps={clientsMenuButtonPropsMemo}
        contractsMenuButtonProps={contractsMenuButtonPropsMemo}
        performanceMenuButtonProps={performanceMenuButtonPropsMemo}
        backofficeMenuButtonProps={backofficeMenuButtonPropsMemo}
        breadCrumbsLinks={[
          {
            label: 'Backoffice',
            onClick:  handleCardClick('backoffice'),
          },
          { 
            label: 'Gestão de veículos preconfigurados',
          },
        ]}
        pageTitle="Gestão de veículos preconfigurados"
        // SK-838 PROFORMA
        importProformaButtonText="Carregar proforma"
        importProformaButtonProps={{
          onClick: () => alert('carregar proforma'),
        }}
        onProformaFileChange={doNothing}
        // onProformaFileChange={handleAttachFileChange} // SK-838
        uploadProformaModalProps={{
          title: 'Carregar proforma(s)',
          handleClose: doNothing,
          proformaFilesProgressBarProps:[], //: fileProgressBarList, // SK-838
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Aplicar',
          confirmButtonProps: { onClick: doNothing },
        }}
        // FIM SECÇÃO SK-838 PROFORMA
        
        addNewVehicleButtonText="Adicionar veículo"
        addNewVehicleButtonProps={{
          startIcon: AddSmallIcon,
          onClick: handleAddVehicleClick,
        }}
        userSearchResultProps={{
          label: 'Pesquise por Marca ou outras caracteristicas',
          placeholder: 'Pesquisar',
          options: vehicleSearchOptions,
          value: brandInput,
          onChange: handleVehicleSearchPropsChange,
        }}
        filterIconProps={{}}
        eraseLinkText="LIMPAR"
        eraseLinkProps= {{ 
          disabled:  resetSearchDisabled,
          onClick: handleResetSearchFilterClick 
        }}
        preconfiguredVehiclesTableProps={{
          expandableText: 'Abrir detalhes',
          closeExpandableText: 'Fechar detalhes',
          onChange: handleEdit,
          warningText: '',
          rows: vehicles.filter(x => x.hidden === false).map((vehicle, index) => {
            return {
              cells: [
                {
                  value: vehicle.makeId,
                  placeholder: 'Selecionar',
                  options: brandOptions,
                  name: 'makeId-'+index,
                  //onChange: ()=>{alert("aa")}//handleVehicleMakeChange
                },
                {
                  value: vehicle.modelId,
                  placeholder: 'Selecionar modelo',
                  options: vehicle.makeId ? getModel(vehicle.makeId,vehicle?.modelId) : [],
                  name: 'modelId-'+index,
                  //onChange:handleVehicleModelChange,
                },             
                {
                  value: vehicle.versionId,
                  placeholder: 'Selecionar descrição',
                  options: vehicle.modelId ? getVersion(vehicle.makeId, vehicle.modelId,index) : [],
                  name: 'versionId-'+index,
                  // disableFilterOptions:true,
                  //onChange:handleVehicleVersionChange,
                },
                {
                  value: vehicle.state,
                  name: 'state',
                  options: [
                    {
                      label: 'Novo',
                      value: 'New',
                    },
                    {
                      label: 'Usado',
                      value: 'Used',
                    }
                  ],
                },
                {
                  value: vehicle.pvp,
                  name: 'pvp',
                  disabled: true,
                },
                {
                  value: vehicle.totalExtraWithoutTax,
                  name: 'extras',
                  disabled: true,
                },
                {
                  value: vehicle.discountWithTaxTotal,
                  name: 'discount',
                  disabled: true,
                },
                {
                  value: vehicle.proformaInvoicePriceWithoutTax,
                  name: 'proformaPrice',
                  disabled: true,
                },
                {
                  value: vehicle.businessValueWithoutTax,
                  name: 'businessPrice',
                  disabled: true,
                },
              ],
              details: {
                expandExtraAccessoriesText: 'Mostrar todos os acessórios',
                collapseExtraAccessoriesText: 'Ocultar acessórios',
                expandExtraStandardsText: 'Mostrar todos os extras',
                collapseExtraStandardsText: 'Ocultar extras',
                noSelectedExtraStandardsText: [
                  'Não existem extras selecionados',
                  'Selecione os extras da lista apresentada em Todos os extras',
                ],
                otherVehicleDataTitle: 'Outros dados da viatura',
                registrationTextFieldProps: {
                  label: 'Matrícula',
                  value: vehicle.licensePlate,
                  placeholder: '00-00-00',
                  name:index +'-licensePlate',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Matricula', 'Detalhes Viatura'),
                  hidden : propertyHidden('Matricula', 'Detalhes Viatura'),
                },
                fuelType: {
                  label: 'Combustível', 
                  value: vehicle.fuelType,
                  disabled: propertyDisabled('Combustível', 'Detalhes Viatura'),
                  hidden : propertyHidden('Combustível', 'Detalhes Viatura'),
                },
                cylinderDisplacement: {
                  label: 'Cilindrada',
                  value: vehicle.engineCc.toString(),
                  disabled: propertyDisabled('Cilindrada', 'Detalhes Viatura'),
                  hidden : propertyHidden('Cilindrada', 'Detalhes Viatura'),
                },
                potency: {
                  label: 'Potência',
                  value: vehicle.powerHP.toString(),
                  disabled: propertyDisabled('Potência', 'Detalhes Viatura'),
                  hidden : propertyHidden('Potência', 'Detalhes Viatura'),
                },
                associateLotText: 'Associar lote',
                lotIDTextFieldProps: {
                  label: 'Nome Identificativo do Lote',
                  placeholder: 'Inserir nome do lote',
                  value: vehicle.lotId,
                  name:index +'-lotId',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Nome Identificativo do Lote', 'Detalhes Viatura'),
                  hidden: propertyHidden('Nome Identificativo do Lote', 'Detalhes Viatura'),
                },
                businessSettingsTitle: 'Configurações do negócio',
                vehicleValuesText: 'Valores da viatura',
                valuesWithoutTaxText: 'Valores sem IVA',
                basePriceWithoutTaxTextFieldProps: {
                  label: 'Preço base',
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle.priceexclVAT,
                  name:index +'-priceexclVAT',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Preço base s/IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Preço base s/IVA', 'Detalhes Viatura'),
                },
                totalExtraWithoutTaxTextFieldProps: {
                  label: 'Total extras',
                  placeholder: '0',
                  defaultValue: '0',
                  endAdornment: '€',
                  value: vehicle.totalExtraWithoutTax,
                  name:index +'-totalExtraWithoutTax',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('Total extras s/IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Total extras s/IVA', 'Detalhes Viatura'),
                },
                totalAccessoriesWithoutTaxTextFieldProps: {
                  label: 'Total acessórios',
                  placeholder: '0',
                  defaultValue: '0',
                  endAdornment: '€',
                  value: vehicle.totalAccessoriesWithout,
                  name:index +'-totalAccessoriesWithout',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('Total acessórios s/IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Total acessórios s/IVA', 'Detalhes Viatura'),
                },
                discountWithoutTaxTextFieldProps: {
                  label: 'Desconto',
                  placeholder: '0',
                  defaultValue: '0',
                  endAdornment: '€',
                  value: vehicle.discountWithoutTax,
                  name:index +'-discountWithoutTax',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Desconto s/IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Desconto s/IVA', 'Detalhes Viatura'),
                },
                // disabled by default
                proformaInvoicePriceWithoutTaxTextFieldProps: {
                  label: 'Preço fatura proforma',
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle.proformaInvoicePriceWithoutTax,
                  name:index +'-proformaInvoicePriceWithoutTax',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('Preço fatura proforma s/IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Preço fatura proforma s/IVA', 'Detalhes Viatura'),
                },
                campaignWithoutTaxTextFieldProps: {
                  label: 'Campanha',
                  placeholder: '0',
                  defaultValue: '0',
                  endAdornment: '€',
                  value: vehicle.campaignWithoutTax,
                  name:index +'-campaignWithoutTax',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Campanha s/Iva', 'Detalhes Viatura'),
                  hidden : propertyHidden('Campanha s/Iva', 'Detalhes Viatura'),
                },
                rappelWithoutTaxTextFieldProps: {
                  label: 'Rappel',
                  placeholder: '0',
                  defaultValue: '400',
                  endAdornment: '€',
                  value: vehicle.rappelWithoutTax,
                  name:index +'-rappelWithoutTax',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Rappel s/Iva', 'Detalhes Viatura'),
                  hidden : propertyHidden('Rappel s/Iva', 'Detalhes Viatura'),
                },
                onDiscountFileClick: () => alert('discount file'),
                onRappelFileClick: () => alert('rappel file'),
                rappelWithoutTaxExtraTextFieldProps: {
                  label: 'Rappel extra',
                  placeholder: '0',
                  defaultValue: '400',
                  endAdornment: '€',
                  warning: vehicle.extraRappel > 0 ? true : false,
                  value: vehicle.extraRappel,
                  name:index +'-extraRappel',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Rappel extra', 'Detalhes Viatura'),
                  hidden : propertyHidden('Rappel extra', 'Detalhes Viatura'),
                },
                hasRappelWithoutTaxExtraThreshold: vehicle.extraRappel > 0 ? true : false,
                rappelWithoutTaxExtraThresholdLabel: 'Carece validação',
                valuesWithTaxText: 'Valores com IVA',
                basePriceWithTaxTextFieldProps: {
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle.consumersPrice,
                  name:index +'-consumersPrice',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Preço base c/IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Preço base c/IVA', 'Detalhes Viatura'),
                },
                // disabled by default
                totalExtraWithTaxTextFieldProps: {
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle.totalExtraWithTax,
                  name:index +'-totalExtraWithTax',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('Total extras c/IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Total extras c/IVA', 'Detalhes Viatura'),
                },
                // disabled by default
                totalAccessoriesWithTaxTextFieldProps: {
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle.totalAccessoriesWith,
                  name:index +'-totalAccessoriesWith',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('Total acessórios c/IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Total acessórios c/IVA', 'Detalhes Viatura'),
                },
                // disabled by default
                discountWithTaxTextFieldProps: {
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle.discountWithTax,
                  name:index +'-discountWithTax',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('Desconto c/IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Desconto c/IVA', 'Detalhes Viatura'),
                },
                discountWithTaxExtraTextFieldProps: {
                  placeholder: '0',
                  endAdornment: '%',
                  value: vehicle.discountWithTaxPercentage,
                  name:index +'-discountWithTaxPercentage',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Desconto em percentagem', 'Detalhes Viatura'),
                  hidden : propertyHidden('Desconto em percentagem', 'Detalhes Viatura'),
                },
                // disabled by default
                discountWithTaxTotalTextFieldProps: {
                  placeholder: '0',
                  endAdornment: '%',
                  // regra para o valor total
                  value: convertNumber((vehicle.discountWithTaxTotal/ (Number(vehicle.priceexclVAT) + vehicle.totalExtraWithoutTax + vehicle.totalAccessoriesWithout))*100).toFixed(2),
                  //regra para a percentagem
                  // value: vehicle.discountWithTaxTotal,
                  name:index +'-discountWithTaxTotal',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('Desconto Total c/IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Desconto Total c/IVA', 'Detalhes Viatura'),
                },
                // disabled by default
                proformaInvoicePriceWithTaxTextFieldProps: {
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle.proformaInvoicePriceWithTax,
                  name:index +'-proformaInvoicePriceWithTax',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('Preço fatura proforma c/IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Preço fatura proforma c/IVA', 'Detalhes Viatura'),
                },
                // disabled by default
                campaignWithTaxTextFieldProps: {
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle.campaignWithTax,
                  name:index +'-campaignWithTax',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('Campanha c/Iva', 'Detalhes Viatura'),
                  hidden : propertyHidden('Campanha c/Iva', 'Detalhes Viatura'),
                },
                campaignWithTaxExtraTextFieldProps: {
                  placeholder: '0',
                  endAdornment: '%',
                  value: vehicle.campaignWithTaxPercentage,
                  name:index +'-campaignWithTaxPercentage',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Campanha em percentagem', 'Detalhes Viatura'),
                  hidden : propertyHidden('Campanha em percentagem', 'Detalhes Viatura'),
                },
                // disabled by default
                campaignWithTaxTotalTextFieldProps: {
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle.campaignWithTaxTotal,
                  name:index +'-campaignWithTaxTotal',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('Total campanha', 'Detalhes Viatura'),
                  hidden : propertyHidden('Total campanha', 'Detalhes Viatura'),
                },
                // disabled by default
                rappelWithTaxTextFieldProps: {
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle.rappelWithTax,
                  name:index +'-rappelWithTax',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('Rappel c/Iva', 'Detalhes Viatura'),
                  hidden : propertyHidden('Rappel c/Iva', 'Detalhes Viatura'),
                },
                rappelWithTaxExtraTextFieldProps: {
                  placeholder: '0',
                  endAdornment: '%',
                  value: vehicle.rappelWithTaxPercentage,
                  name:index +'-rappelWithTaxPercentage',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Rappel em percentagem', 'Detalhes Viatura'),
                  hidden : propertyHidden('Rappel em percentagem', 'Detalhes Viatura'),
                },
                // disabled by default
                rappelWithTaxTotalTextFieldProps: {
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle.rappelWithTaxTotal,
                  name:index +'-rappelWithTaxTotal',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('Rappel Total c/IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Rappel Total c/IVA', 'Detalhes Viatura'),
                },
                expensesText:  propertyHidden('Ecovalor', 'Detalhes Viatura') && propertyHidden('Isv sem IVA', 'Detalhes Viatura') && propertyHidden('PVP sem IVA', 'Detalhes Viatura') && propertyHidden('Despesas de legalização', 'Detalhes Viatura')
                  ? ''
                  : 'Despesas',
                ecoValueTextFieldProps: {
                  label: 'Ecovalor',
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle?.recycling_charge,
                  name:index +'-recycling_charge',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Ecovalor', 'Detalhes Viatura'),
                  hidden : propertyHidden('Ecovalor', 'Detalhes Viatura'),
                },
                ISV_WithoutTaxTextFieldProps: {
                  label: 'ISV sem IVA',
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle?.luxury_tax,
                  name:index +'-luxury_tax',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Isv sem IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Isv sem IVA', 'Detalhes Viatura'),
                },
                // disabled by default
                PVP_WithoutTaxTextFieldProps: {
                  label: 'PVP sem IVA',
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle?.pvp,
                  name:index +'-pvp',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('PVP sem IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('PVP sem IVA', 'Detalhes Viatura'),
                },
                legalizationAndTransportCostsTextFieldProps: {
                  label: 'Despesas de legalização e transporte',
                  placeholder: '0',
                  endAdornment: '€',
                  value: vehicle.transport_x0026_immatriculation_cost,
                  name:index +'-transport_x0026_immatriculation_cost',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Despesas de legalização', 'Detalhes Viatura'),
                  hidden : propertyHidden('Despesas de legalização', 'Detalhes Viatura'),
                },
                addAccessoryTitle: 'Selecionar Acessórios/extras',
                addAccessoryText: 'Adicionar acessórios',
                selectExtraStandardTitle: 'Escolher extras standard s/IVA',
                rentalDataText: propertyDisabled('Tipo de aluguer', 'Detalhes Viatura') && propertyHidden('Condições comerciais', 'Detalhes Viatura') && propertyHidden('Quantidade', 'Detalhes Viatura') ? "" :' Dados do aluguer',
                rentalType: { 
                  label: 'Tipo de aluguer', 
                  value: vehicle.typeofRental,
                  disabled: propertyDisabled('Tipo de aluguer', 'Detalhes Viatura'),
                  hidden : propertyHidden('Tipo de aluguer', 'Detalhes Viatura'),
                },
                businessConditions: {
                  label: 'Condiçoes comerciais',
                  value: vehicle.commercialConditions,
                  disabled: propertyDisabled('Condições comerciais', 'Detalhes Viatura'),
                  hidden : propertyHidden('Condições comerciais', 'Detalhes Viatura'),
                },
                amountTextFieldProps: {
                  label: 'Quantidade',
                  placeholder: '0',
                  value: vehicle.quantity,
                  name:index +'-quantity',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Quantidade','Detalhes Viatura'),
                  hidden : propertyHidden('Quantidade', 'Detalhes Viatura'),
                },
                businessPriceText: 'Preço do negócio',
                businessValueWithoutTaxTextFieldProps: {
                  label: 'Valor de negócio sem IVA',
                  defaultValue: '0 ',
                  endAdornment: '€',
                  value: vehicle.businessValueWithoutTax,
                  name:index +'-businessValueWithoutTax',
                  onChange: handleOnChange,
                  disabled: propertyDisabled('Valor de negócio sem IVA', 'Detalhes Viatura'),
                  hidden : propertyHidden('Valor de negócio sem IVA', 'Detalhes Viatura'),
                },
                //disabled: true
                businessValueWithTaxTextFieldProps: {
                  label: 'Valor de negócio com IVA',
                  endAdornment: '€',
                  defaultValue: '0 ',
                  value: vehicle.businessValueWithTax,
                  name:index +'-businessValueWithTax',
                  onChange: handleOnChange,
                  disabled: true,
                  // disabled: propertyDisabled('Valor de negócio com IVA', 'Detalhes Viatura'),
                  hidden: propertyHidden('Valor de negócio com IVA', 'Detalhes Viatura'),
                },
                addAccessoryButtonText: 'Adicionar Acessório',
                addAccessoryButtonProps: {
                  name:index+"-"+vehicle?.id,
                  onClick: handleAddAccessoryButtonPropsClick,
                  disabled: propertyDisabled('Acessórios', 'Detalhes Viatura'),
                  hidden: propertyHidden('Acessórios', 'Detalhes Viatura'),
                },
                searchStandardExtraButtonText: 'Pesquisar',
                searchStandardExtraButtonProps: {
                  name:String(index),
                  onClick:handleExtraSearchPropsClick
                },
  
                allExtrasButtonText: "Todos os extras",
                allExtrasButtonProps: {},
                selectedExtrasButtonText: "Extras selecionados",
                selectedExtrasButtonProps: {},
                extraAccessories: getAccessories(index),
                onExtraCheckboxChange: handleExtraStandarCheckChange,
                // extras:[],
                extraIncompatibilityModalProps: {
                  title: "Incompatibilidade de extras",
                  cancelButtonText: "Manter opções",
                  confirmButtonText: "Atualizar",
                  confirmButtonProps: () => {
                    console.log('extraIncompatibilityModalProps')
                    alert('extraIncompatibilityModalProps')
                  },
                  incompatabilityText: [
                    "Estes dois acessórios que selecionou são incompativeis.",
                    "Por favor escolha, qual opção pretende manter?",
                  ],
                  requiredExtrasText: [
                    "A opção selecionada requer que inclua também os extras abaixo:",
                  ],
                  alreadyIncludedText: [
                    "O Pack selecionado inclui os extras abaixo, pretende manter todas as opçoes mesmo assim?",
                  ],
                  titleRequiredExtras: "Extras de seleccção obrigatória",
                  titlePacksIncompatibility: "Packs de Extras incompatíveis"
                  // extras: [],
                },
                extraStandard: getExtraStandard(index),
                extraStandardSearchResultProps: getExtraSearchForVehicles(index),
                technicalCharacteristics: [
                  {
                    title: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura') ? "" : "Caracteristicas técnicas",
                    characteristic: [
                      {
                        group: "vehicleSpec",
                        groupLabel: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura') ? "" : "Especificações da viatura",
                        label: "Ano do modelo",
                        value: vehicle?.modelYear,
                        disabled: propertyDisabled('Caracteristicas técnicas', 'Detalhes Viatura'),
                        hidden: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura'),
                      },
                      {
                        group: "vehicleSpec",
                        groupLabel: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura') ? "" : "Especificações da viatura",
                        label: "Data de registo",
                        value: vehicle?.recordDate,
                        disabled: propertyDisabled('Caracteristicas técnicas', 'Detalhes Viatura'),
                        hidden: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura'),
                      },
                      {
                        group: "vehicleSpec",
                        groupLabel: propertyHidden('Caracteristicas técnicas') ? "" : "Especificações da viatura",
                        label: "Quilometragem",
                        value: vehicle?.mileage.toString(),
                        disabled: propertyDisabled('Caracteristicas técnicas', 'Detalhes Viatura'),
                        hidden: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura'),
                      },
                      {
                        group: "vehicleSpec",
                        groupLabel: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura') ? "" : "Especificações da viatura",
                        label: "Número de portas",
                        value: vehicle?.numberofDoors.toString(),
                        disabled: propertyDisabled('Caracteristicas técnicas', 'Detalhes Viatura'),
                        hidden: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura'),
                      },
                      {
                        group: "vehicleSpec",
                        groupLabel: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura') ? "" : "Especificações da viatura",
                        label: "Estado",
                        value: vehicle?.status?.toString(),
                        disabled: propertyDisabled('Caracteristicas técnicas', 'Detalhes Viatura'),
                        hidden: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura'),
                      },
                      {
                        group: "vehicleSpec",
                        groupLabel: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura') ? "" : "Especificações da viatura",
                        label: "Uso",
                        value: otherChaDropdownOptions.find(x => x.value === vehicle?.use)?.label ?? vehicle?.use,
                        disabled: propertyDisabled('Caracteristicas técnicas', 'Detalhes Viatura'),
                        hidden: propertyHidden('Caracteristicas técnicas', 'Detalhes Viatura'),
                      },
                    ],
                    isCollapsible: true,
                    collapsed: toggleCollapseTechnicalCharacteristics,
                    //IGNORA O TIPO DO EVENTO
                    onCollapse: (isCollapsed: boolean) => {
                      setToggleCollapseTechnicalCharacteristics( x => {
                        let value = !x
                        return value
                      });
                    } 
                  },
                  {
                    title: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : "Espaço e Dimensões",
                    characteristic: [
                      {
                        group: "iDim",
                        groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : 'Dimensões',
                        label: "Número de lugares",
                        value: vehicle && vehicle.quantity_of_Seats
                          ? vehicles[index].quantity_of_Seats.toString()
                          : "",
                        disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                        hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                      },
                      {
                        group: "iDim",
                        groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : 'Dimensões',
                        label: "Comprimento (mm)",
                        value: vehicle && vehicle.lengthmm
                          ? vehicle.lengthmm.toString()
                          : "",
                        disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                        hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                      },
                      {
                        group: "iDim",
                        groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : 'Dimensões',
                        label: "Largura (mm)",
                        value: vehicle && vehicle.widthmm
                          ? vehicle.widthmm.toString()
                          : "",
                        disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                        hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                      },
                      {
                        group: "iDim",
                        groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : 'Dimensões',
                        label: "Altura",
                        value: vehicle && vehicle.heightmm
                          ? vehicle.heightmm.toString()
                          : "",
                        disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                        hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                      },
                      {
                        group: "iDim",
                        groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : 'Dimensões',
                        label: "Volume Mala (L)	",
                        value: vehicle && vehicle.trunkVolumel
                          ? vehicle.trunkVolumel.toString()
                          : "",
                        disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                        hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                      },
                      {
                        group: "Dim&W",
                        groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : "Dimensões e pesos",
                        label: "Distância entre Eixos (mm)",
                        value: vehicle && vehicle.wheelBasemm
                          ? vehicle.wheelBasemm.toString()
                          : "",
                        disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                        hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                      },
                      {
                        group: "Dim&W",
                        groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : "Dimensões e pesos",
                        label: "Distância entre Eixos Dianteiros (mm)",
                        value: vehicle && vehicle.trainRearWheelsmm
                          ? vehicles[index].trainRearWheelsmm.toString()
                          : "",
                        disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                        hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                      },
                      {
                        group: "Dim&W",
                        groupLabel: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura') ? '' : "Dimensões e pesos",
                        label: "Distância entre Eixos traseiros (mm)",
                        value: vehicle && vehicle.trainFrontWheelsmm
                          ? vehicles[index].trainFrontWheelsmm.toString()
                          : "",
                        disabled: propertyDisabled('Espaço e Dimensões', 'Detalhes Viatura'),
                        hidden: propertyHidden('Espaço e Dimensões', 'Detalhes Viatura'),
                      },
                    ],
                    isCollapsible: true,
                    collapsed: toggleCollapseSpaceAndDimensions,
                    //IGNORA O TIPO DO EVENTO
                    onCollapse: (isCollapsed: boolean) => {
                      setToggleCollapseSpaceAndDimensions( x => {
                        let value = !x
                        return value
                      });
                    } 
                  },
                  {
                    title: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Especificações do motor",
                    characteristic: [
                      {
                        group: "motor",
                        groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Motor",
                        label: "Taxa de compressão",
                        value: vehicle && vehicle.compressionratio
                          ? vehicles[index].compressionratio.toString()
                          : "",
                        disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                        hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                      },
                      {
                        group: "motor",
                        groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Motor",
                        label: "Diâmetro",
                        value: vehicle && vehicle.bore
                          ? vehicle.bore.toString()
                          : "",
                        disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                        hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                      },
                      {
                        group: "motor",
                        groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Motor",
                        label: "Binário Máximo (Nm)",
                        value: vehicle && vehicle.torqueNm
                          ? vehicle.torqueNm.toString()
                          : "",
                        disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                        hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                      },
                      {
                        group: "motor",
                        groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Motor",
                        label: "Potência máxima (kw)",
                        value: vehicle && vehicle.powerkW
                          ? vehicle.powerkW.toString()
                          : "",
                        disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                        hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                      },
                      {
                        group: "envP",
                        groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Performance Ambiental",
                        label: "Capacidade do Tanque de Combustível (L)",
                        value: vehicle && vehicle.fuelTankCapacityl
                          ? vehicles[index].fuelTankCapacityl.toString()
                          : "",
                        disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                        hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                      },
                      {
                        group: "envP",
                        groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Performance Ambiental",
                        label: "Tipo de Combustível ",
                        value: vehicle && vehicle.fuelType
                          ? vehicle.fuelType.toString()
                          : "",
                        disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                        hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                        },
                      {
                        group: "envP",
                        groupLabel: propertyDisabled('Especificações do motor') ? "" : "Performance Ambiental",
                        label: "CO2-Emissão Grama / Kg",
                        value: vehicle && vehicle.cO2DischargeDecF
                          ? vehicles[index].cO2DischargeDecF.toString()
                          : "",
                        disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                        hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                      },
                      {
                        group: "perf",
                        groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Performance",
                        label: "Velocidade Máxima (Km/H)",
                        value: vehicle && vehicle.maxspeedkmh
                          ? vehicle.maxspeedkmh.toString()
                          : "",
                        disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                        hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                      },
                      {
                        group: "perf",
                        groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Performance",
                        label: "Aceleração 0-100 Km / H",
                        value: vehicle && vehicle.acceleration0100kmh
                          ? vehicles[index].acceleration0100kmh.toString()
                          : "",
                        disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                        hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                      },
                      {
                        group: "perf",
                        groupLabel: propertyDisabled('Especificações do motor', 'Detalhes Viatura') ? "" : "Performance",
                        label: "Autonomia",
                        value: vehicle && vehicle.technMaxQuantityKm
                          ? vehicles[index].technMaxQuantityKm.toString()
                          : "",
                        disabled: propertyDisabled('Especificações do motor', 'Detalhes Viatura'),
                        hidden: propertyHidden('Especificações do motor', 'Detalhes Viatura'),
                      },
                    ],
                    isCollapsible: true,
                    collapsed: toggleCollapseEngineSpecifications,
                    //IGNORA O TIPO DO EVENTO
                    onCollapse: (isCollapsed: boolean) => {
                      setToggleCollapseEngineSpecifications( x => {
                        let value = !x
                        return value
                      });
                    } 
  
                  },
                ],
                otherCharacteristics: [
                  {
                    title: propertyHidden('BPM', 'Detalhes Viatura') && propertyHidden('Mudança de óleo', 'Detalhes Viatura') && propertyHidden('Uso(Categoria da viatura)', 'Detalhes Viatura')? "" : "Outras informações / serviços",
                    characteristic: [
                      {
                        group: "vehicleSpec",
                        groupLabel: propertyHidden('BPM', 'Detalhes Viatura') ? "" : "BPM",
                        label: propertyHidden('BPM', 'Detalhes Viatura') ? "" : "BPM",
                        value: vehicle.bPM
                          ? vehicle.bPM.toString()
                          : "0",
                        disabled: propertyDisabled('BPM', 'Detalhes Viatura'),
                        hidden: propertyHidden('BPM', 'Detalhes Viatura'),
                      },
                      {
                        group: "vehicleSpec",
                        groupLabel: propertyHidden('Mudança de óleo', 'Detalhes Viatura') ? '' : 'Mudança de óleo', // aparenta só estar ativo na primeira propriedade
                        label: propertyHidden('Mudança de óleo', 'Detalhes Viatura') ? '' : 'Mudança de óleo',
                        value: vehicle.oilChange.toString(),
                        disabled: propertyDisabled('Mudança de óleo', 'Detalhes Viatura'),
                        hidden: propertyHidden('Mudança de óleo', 'Detalhes Viatura'),
                      },
                    ],
                    characteristicsDropdownProps: [
                      {
                        label: "Uso",
                        options: otherChaDropdownOptions,
                        value: vehicle.use,
                        name: index + "-use",
                        onChange: handleOnChange,
                        disabled: propertyDisabled('Uso(Categoria da viatura)', 'Detalhes Viatura'),
                        hidden: propertyHidden('Uso(Categoria da viatura)', 'Detalhes Viatura'),
                      },
                    ],
                  },
                ],
                // SK-850
                tiresTitle: '',
                tiresMeasurements: {
                  options: [],
                  label: 'Pesquise por CA ou veículo',
                  placeholder: 'Pesquisar',
                  value: '',
                  // onInputChange: handleOnInputSearchOptions,
                  freeSolo: true,
                  disableClearable: false
                },
                tiresDivergentMeasurements: {
                  options: [],
                  label: 'Pesquise por CA ou veículo',
                  placeholder: 'Pesquisar',
                  value: '',
                  // onInputChange: handleOnInputSearchOptions,
                  freeSolo: true,
                  disableClearable: false
                },

              },
              // SK-838 PROFORMA
              proformaFiles: [
                { title: "fileABC.pdf", size: "15MB" },
                { title: "fileXPTO.pdf", size: "10MB" }
              ]
              // FIM SECÇÃO SK-838 PROFORMA
              //warning: index === 0,
            };
          }),
          headCells: [
            {
              id: 'brand',
              label: 'Marca',
            },
            {
              id: 'model-desc',
              label: 'Modelo',
            },
            {
              id: 'model',
              label: 'Descrição do Modelo',
            },
            {
              id: 'type',
              label: 'Tipo de Utilização',
            },
            {
              id: 'pvp',
              label: 'PVP',
            },
            {
              id: 'extras',
              label: 'Extras ',
            },
            {
              id: 'discount',
              label: 'Desconto ',
            },
            {
              id: 'proformaPrice',
              label: 'Preço proforma ',
            },
            {
              id: 'businessPrice',
              label: 'Preço de negócio ',
            },
          ],
          actions: [
            {
              label: 'Guardar',
              onClick: (index: number) =>
                handleSaveVehicleClick(vehicles[index], index),
            },
            // SK-838 PROFORMA
            {
              label: 'Consultar proforma(s)',
              onClick: (index) => alert(`consultar proforma da linha ${index}`),
              disabled: false,
            },
            // FIM SECÇÃO SK-838 PROFORMA
            {
              label: 'Duplicar',
              onClick: handleDuplicateVehicleClick,
            },
            {
              label: 'Eliminar',
              onClick: handleRemoveVehicleClick,
            },
          ],
          handleSelected: (selected) => { setSelectedRows([...selected]) },
          handleDownloadProformaOnClick: (file: ProformaFile) => doNothing
        }}
        groupEditButtonText="Editar em grupo"
        groupEditButtonProps={{
          disabled: selectedRows.length === 0,
        }}
        searchUserButtonText="Pesquisar"
        searchUserButtonProps={{
          onClick: handleSearchOptionsClick,
        }}
        filterDrawerProps = {filterDrawerProps}

        // FALTA  VALIDAR A ADIÇÃO DE ACESSÓRIOS POR CAUSE DO vehicleId/preconfiguredVehicleId
        // SK-839
        groupEditModalProps={vehicleGroupEditModal}
      />
      <Alert open={open} onClose={handleClose} {...alertProps} />
    </>
  );
};

export default BackofficePreconfigured;
