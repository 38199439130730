import { AccountIcon, Alert, AlertProps, BackofficeEditUserPage } from '@hi-interactive-admin/simulador-kinto';
import { useCallback, useEffect, useState, ChangeEvent, memo } from 'react';
import { useParams } from 'react-router-dom';
import { UserModel, newUser } from '../../model/User';
import { CustomerService } from '../../services/CustomerServices';
import { UserServices } from '../../services/UserServices';
import defaultImg from '../../assets/icon.svg';
import { Option } from '../../model/interfaces';
import { KintoRootProps } from '../../model/KintoComponentProps';
import { ProfileServices } from '../../services/ProfileServices';
import usePermissionsHook from '../../customCallbacks/permissions/permissionsHook';
import { formatDateWithTimeZone, passwordFormat, validateEmail } from '../../Utilities/Utils';
import useNavbarHook from '../../customCallbacks/navbarHook';
import { AppSettings, EmptyAppSettings } from '../../model/Settings';
import { VehicleService } from '../../services/VehicleService';
import { useAsyncRequest } from '../../services/AsyncRequestContext';
import { ContactsService } from '../../services/ContactsService';
import { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteInputChangeReason } from '@mui/material';

type BackofficeEditUserRouteParams = {
  userId: string;
};

const userService = new UserServices();
const customerService = new CustomerService();
const profileService = new ProfileServices();
const vehicleService = new VehicleService();
const contactService = new ContactsService();

const BackofficeEditUser: React.FC<KintoRootProps>  = ({ onLogout }) => {
  const timeZone:string = "pt-PT";
  const params = useParams<BackofficeEditUserRouteParams>();
  const [settings, setSettings] = useState<AppSettings>(new EmptyAppSettings());
  const { asyncResponse, setAsyncResponse } = useAsyncRequest();
  
  const { 
    // descomentar estas funcionalidades conforme forem necessárias utilizar
    navigate,
    handleCardClick,
    // handleLogout,
    topBarPropsMemo,
    dashboardMenuButtonPropsMemo, 
    proposalMenuButtonPropsMemo, 
    clientsMenuButtonPropsMemo, 
    contractsMenuButtonPropsMemo,
    performanceMenuButtonPropsMemo, 
    backofficeMenuButtonPropsMemo
  } = useNavbarHook(onLogout);
  
  const {currentUser, setCurrentUser, propertyDisabled, propertyHidden} = usePermissionsHook();
  // o currentUser pode não ser o mesmo que o utilizador que está a ser editado
  const [user, setUser] = useState<UserModel>(newUser);

  const [changePassword, setChangePassword] = useState(false);
  const [showingCurrentPassword, setShowingCurrentPassword] = useState(false);
  const [showingNewPassword, setShowingNewPassword] = useState(false);
  const [showingConfirmPassword, setShowingConfirmPassword] = useState(false);

  // constantes de mensagems de aviso 
  //inicio
  const [open, setOpen] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<Omit<AlertProps, "open" | "onClose">>({
    variant: "info",
    text: "",
    title: "",
  });
  const openAlert = (alertProps: Omit<AlertProps, "open" | "onClose">) => {
    setAlertProps(alertProps);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //fim

  const [concessionOptions, setConcessionOptions] = useState<Option[]>([]);
  //lista de opções de perfis
  const [profilesOptions, setProfilesOptions] = useState<Option[]>([]);
  // opções de tipos de aluguer
  const [rentalTypeOptions, setRentalTypeOptions] = useState<Option[]>([]);
  // opções de condições comerciais
  const [commercialConditionsOptions, setCommercialConditionsOptions] = useState<Option[]>([]);
  // opções de tipos de renda
  const [paymentTermsOptions, setPaymentTermsOptions] = useState<Option[]>(settings.Data.Vehicles_TypeOfRental[1].result);

  // tooltips de erros em campos de preenchimento obrigatório
  const [curretPassword, setCurretPassword] = useState<string>();
  const [curretPasswordError, setCurretPasswordError] = useState<boolean>(false);
  const [curretPasswordErrorText, setCurretPasswordErrorText] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [newPasswordError, setNewPasswordError] = useState<boolean>(true);
  const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(true);
  const [newPasswordErrorText, setNewPasswordErrorText] = useState<string>('Deve ter pelo menos 8 carateres e conter pelo menos uma letra maiuscula e um número e as duas palavras passe devem ser iguais')
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState<string>('Deve ter pelo menos 8 carateres e conter pelo menos uma letra maiuscula e um número e as duas palavras passe devem ser iguais');

  const [invalidInputList, setInvalidInputList] = useState<{
    inputPropName: string,
    errorText: string,
    hasError: boolean
  }[]>([]);
 
  const handleImage = useCallback( (image:string) => {
    let fileResult = image;
    let fileResultType = fileResult.split(',').shift();
    let imageFile = fileResult.split(',',2).pop();
    if (fileResultType === 'data:image/svg+xml') {
      return fileResult;
    }
    else {
      let displayImage = fileResultType + ';base64,' + imageFile;
      return displayImage;
    }
  }, []);

  // const [applicantContactsList, setApplicantContactsList] = useState<RequiredDataProps[]>();
  const [applicantContactsOptionsList, setApplicantContactsOptionsList] = useState<Option[]>();
  const [applicant, setApplicant] = useState<Option>({ value: "", label: "" });

  const searchApplicant = useCallback((e: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
    let applicantsOptionsList: Option[] = [{label: '', value: ''}]
    const regex = /^CN\d{6} - /;
    if(value && !regex.test(value) && value !== 'undefined' && value.length > 3 ){
      contactService.getApplicants(value).then((data) => {
        if( data && data.succeeded){
          data.value.forEach((element: any) => {
            applicantsOptionsList.push({
              label: `${element.no} - ${element.name}`,
              value: element.no
            });
          });
          setApplicantContactsOptionsList(applicantsOptionsList);
        }
      });
    }
    if(value && value !== 'undefined' && regex.test(value)){
      let searchValue = value.split(' - ')[0];
      contactService.getApplicants(searchValue).then((data) => {
        if( data && data.succeeded){
          data.value.forEach((element: any) => {
            applicantsOptionsList.push({
              label: `${element.no} - ${element.name}`,
              value: element.no
            });
          });
          setApplicantContactsOptionsList(applicantsOptionsList);
        }
      });
    }
    if((value === '' && (reason === 'clear' || reason === 'input'))){
      contactService.getContacts().then((data) => {
        if(data && data.succeeded){  
          data.value.forEach((element: any) => {
            applicantsOptionsList.push({
              label: `${element.no} - ${element.name}`,
              value: element.no
            });
          });
          setApplicantContactsOptionsList(applicantsOptionsList);
        }
      });
    }
  },[])
  
  const selectApplicant = useCallback( (event: React.SyntheticEvent<Element, Event>, value: any, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<any>) => {
    let emptyApplicantsOption: Option = {label: '', value: ''};
    if(value !== null){
      setUser(x => ({...x, webPortalContactId: value.value}));
      setApplicant(value)
    } else {
      setUser(x=> ({...x, webPortalContactId: ''}));
      setApplicant(emptyApplicantsOption)
    }
  },[])

  
 const handeNameInput = useCallback(( e:any) => {
    setUser(x => {
      return {...x, username: e.target.value};
    });
    setInvalidInputList((old) => {
      return old.map((item) => {
        if (item.inputPropName === 'nameTextFieldProps') {
          return {
            ...item,
            errorText: e.target.value === '' ? 'O nome do utilizador é obrigatório. Por favor preencha o campo.' : '',
            hasError: e.target.value === '',
          };
        }
        return { ...item };
      });
    });
  }, []); 

  const handleNameFocus = useCallback(() => {
    setInvalidInputList((old) => {
      // se a lista de erros já contém um objecto para o campo de nome de utilizador e o campo está preenchido atualiza a lista de erros
      if(old.some( x => x.inputPropName === 'nameTextFieldProps') && user?.username !== ''){
        return old.map((item) => {
          if (item.inputPropName === 'nameTextFieldProps') {
            return {
              ...item,
              errorText: '',
              hasError: false,
            };
          }
          return { ...item };
        });
      //se a lista de erros não contém um objecto para o campo de nome de utilizador e o campo está preenchido adiciona um objecto à lista de erros
      } else if(!old.some( x => x.inputPropName === 'nameTextFieldProps') && user?.username !== ''){
        return [...old, {
          inputPropName: "nameTextFieldProps",
          errorText: "",
          hasError: false
        }]  
      // se a lista de erros já contém um objecto para o campo de nome de utilizador e o campo não está preenchido atualiza a lista de erros
      } else if(old.some( x => x.inputPropName === 'nameTextFieldProps') && user?.username === ''){
        return old.map((item) => {
          if (item.inputPropName === 'nameTextFieldProps') {
            return {
              ...item,
              errorText: 'O nome do utilizador é obrigatório. Por favor preencha o campo.',
              hasError: true,
            };
          }
          return { ...item };
        });
      // se a lista de erros não contém um objecto para o campo de nome de utilizador e o campo não está preenchido adiciona um objecto à lista de erros
      } else {
        return [...old, {
          inputPropName: "nameTextFieldProps",
          errorText: "O nome do utilizador é obrigatório. Por favor preencha o campo.",
          hasError: true
        }];
      }
    });
  }, [user]);

  const handleEmailTextInput = useCallback(( e:any) => {
    setUser(x => {
      return {...x, [e.target.name]: e.target.value};
    });
    setInvalidInputList((old) => {
      return old.map((item) => {
        if (item.inputPropName === 'emailTextFieldProps') {
          return {
            ...item,
            errorText: e.target.value === '' ? // se o input não for preenchido
              // a mensagem de erro apresentada é
              'O email do utilizador é obrigatório. Por favor preencha o campo.' :  // senão
              !validateEmail(e.target.value) ? // se o input não for um email válido
                // a mensagem de erro apresentada é
                'O email do utilizador não é válido. Por favor preencha com um email valido.' : // senão
                '', // não apresenta mensagem de erro
            hasError: e.target.value === '' || !validateEmail(e.target.value),
          };
        }
        return { ...item };
      });
    });
  }, []); 

  const handleEmailFocus = useCallback(() => {
    setInvalidInputList((old) => {
      // se a lista de erros já contém um objecto para o campo de email de utilizador e o campo está preenchido de forma correta, atualiza a lista de erros
      if(old.some( x => x.inputPropName === 'emailTextFieldProps') && user?.email !== '' && validateEmail(user?.email)){
        return old.map((item) => {
          if (item.inputPropName === 'userNameTextFieldProps') {
            return {
              ...item,
              errorText: '',
              hasError: false,
            };
          }
          return { ...item };
        });
      // se a lista de erros já contém um objecto para o campo de email de utilizador e o campo não está preenchido, atualiza a lista de erros
      } else if(old.some( x => x.inputPropName === 'emailTextFieldProps') && user?.email === '' ) {
        return old.map((item) => {
          if (item.inputPropName === 'emailTextFieldProps') {
            return {
              ...item,
              errorText: 'O email do utilizador é obrigatório. Por favor preencha o campo.',
              hasError: true,
            };
          }
          return { ...item };
        });
      // se a lista de erros já contém um objecto para o campo de email de utilizador e o campo não está preenchido de forma correta, atualiza a lista de erros
      } else if(old.some( x => x.inputPropName === 'emailTextFieldProps') && !validateEmail(user?.email)) {
        return old.map((item) => {
          if (item.inputPropName === 'emailTextFieldProps') {
            return {
              ...item,
              errorText: 'O email do utilizador não é válido. Por favor preencha com um email valido.',
              hasError: true,
            };
          }
          return { ...item };
        });
      // se a lista de erros não contém um objecto para o campo de email de utilizador e o campo não está preenchido de forma correta, adiciona um objecto à lista de erros
      } else if(!old.some( x => x.inputPropName === 'emailTextFieldProps') && user?.email !== '' && !validateEmail(user?.email)) {
        return [...old, {
          inputPropName: "emailTextFieldProps",
          errorText: "O email do utilizador não é válido. Por favor preencha com um email valido.",
          hasError: true
        }];
      // se a lista de erros não contém um objecto para o campo de email de utilizador e o campo está preenchido de forma correta, adiciona um objecto à lista de erros
      } else if(!old.some( x => x.inputPropName === 'emailTextFieldProps') && user?.email !== '' && validateEmail(user?.email)) {
        return [...old, {
          inputPropName: "emailTextFieldProps",
          errorText: '',
          hasError: false
        }];
      //se a lista de erros não contém um objecto para o campo de email de utilizador e o campo está preenchido de forma correta, adiciona um objecto à lista de erros
      } else {
        return [...old, {
          inputPropName: "emailTextFieldProps",
          errorText: "O email do utilizador é obrigatório. Por favor preencha o campo.",
          hasError: true
        }];
      }
    });
  }, [user]);

  const handleDropdownChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    setUser(x => {
      if(e.target.name === 'active') {
        return {...x, active: e.target.value === 'active' ? true : false};
      }
      return {...x, [e.target.name]: e.target.value};
    });
    // validação das mensages de erro para o campo perfil
    if(e.target.name === 'profile') {
      setInvalidInputList((old) => {
        return old.map((item) => {
          if (item.inputPropName === 'profileDropDownProps') {
            return {
              ...item,
              errorText: e.target.value === '' ? 'O perfil do utilizador é obrigatório. Por favor selecione um perfil.' : '',
              hasError: e.target.value === '',
            };
          }
          return { ...item };
        });
      });
    }

    if(e.target.name === 'typeofRental') {
      vehicleService.getCommercialConditions(e.target.value).then(data => {
        if(data.succeeded) {
          let options: {value:string,label:string}[] = [];
          data.value.forEach((element: any) => {
            options.push({
              label:element.label,
              value:element.value
            });
          });
          setCommercialConditionsOptions(options);
        }
      });
    }
  }, []);

  const handleProfileFocus = useCallback(() => {
    setInvalidInputList((old) => {
      // se a lista de erros já contém um objecto para o campo de nome de utilizador e o campo está preenchido
      if(old.some( x => x.inputPropName === 'profileDropDownProps') && user?.profileId ){
        return old.map((item) => {
          if (item.inputPropName === 'profileDropDownProps') {
            return {
              ...item,
              errorText: '',
              hasError: false,
            };
          }
          return { ...item };
        });
      } else if(old.some( x => x.inputPropName === 'profileDropDownProps') && !user?.profileId){
        return old.map((item) => {
          if (item.inputPropName === 'profileDropDownProps') {
            return {
              ...item,
              errorText: 'O perfil do utilizador é obrigatório. Por favor selecione um perfil.',
              hasError: true,
            };
          }
          return { ...item };
        });
      } else {
        return [...old, {
          inputPropName: "profileDropDownProps",
          errorText: "O perfil do utilizador é obrigatório. Por favor selecione um perfil.",
          hasError: true
        }];
      }
    });
  }, [user]);


  // valida se a palavra passe atual está correta e permite a alteração da palavra passe
 const handleAllowPasswordChangeClick = useCallback(( e:any) => {
  const currentUserId = localStorage.getItem("userId");
  if(currentUserId !== user?.userId.toString()){
    setCurretPasswordError(true);
    setChangePassword(false);
    openAlert({
      variant: "error",
      text: "Não autorizado! Apenas o próprio utilizador pode alterar a password!",
      title: "Atenção",
    });  
    return;
  }
  if(user && curretPassword){
    userService.validatePwd(user?.username, curretPassword).then( result => {
    // userService.validatePwd(user?.username, encryptMD5(curretPassword, true)).then( result => {
      if(result){
        setCurretPasswordError(false);
        setCurretPasswordErrorText('')
        setChangePassword(true)
      }
      else {
        setCurretPasswordError(true);
        setCurretPasswordErrorText('Palavra passe incorrecta!')
        setChangePassword(false)
      }
    })
  }
  }, [curretPassword,user]); 
  // altera a palavra passe
  const handleChangePasswordButtonClick = useCallback(( e:any) => {
    if (user && newPassword) {
      userService.changePwd(user, newPassword).then(data => {
        if(data.succeeded){
          setCurretPassword('');
          setCurretPasswordError(false);
          setCurretPasswordErrorText('')
          setNewPassword('');
          setNewPasswordError(true);
          setNewPasswordErrorText('Deve ter pelo menos 8 carateres e conter pelo menos uma letra maiuscula e um número')
          setConfirmPassword('');
          setConfirmPasswordError(true);
          setConfirmPasswordErrorText('As duas palavras passe devem ser iguais');
          setChangePassword(false);
          openAlert({
            variant: "success",
            text: "O palavra passe foi atualizada com sucesso. ",
            title: "Sucesso",
          });
        }
        else {
          openAlert({
            variant: "error",
            text: "Não foi possível atualizar a palavra passe.",
            title: "Erro",
          });
        }
      });
    }
  }, [newPassword,user]); 
  
  // input de palavra passe atual
  const handleCurrentPasswordOnInputChange = useCallback(( e:any) => {
    setCurretPassword(e.target.value);
  }, []);
  // input de nova palavra passe
  const handleConfirmPasswordOnInputChange = useCallback(( e:any) => {
    const passwordMeetsRequirements = passwordFormat(e.target.value);
    if(passwordMeetsRequirements.hasErrors) {
      if(e.target.value !== newPassword) {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorText('As duas palavras passe devem ser iguais');
      } else {
        setNewPasswordError(false);
        setNewPasswordErrorText('');
        setConfirmPasswordError(false);
        setConfirmPasswordErrorText('');
      }
    } else {
      if(passwordMeetsRequirements.errorType === 'size') {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorText('A palavra passe deve ter pelo menos 8 carateres');
      } else if(passwordMeetsRequirements.errorType === 'uppercase') {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorText('A palavra passe deve conter pelo menos uma letra maiuscula');
      } else if(passwordMeetsRequirements.errorType === 'number') {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorText('A palavra passe deve conter pelo menos um número');
      } else if(passwordMeetsRequirements.errorType === 'both') {
        setConfirmPasswordError(true);
        setConfirmPasswordErrorText('A palavra passe deve conter pelo menos uma letra maiuscula e um número');
      }
    }

    setConfirmPassword(e.target.value);
    
  }, [newPassword]);
  // input de confirmação de nova palavra passe
  const handleNewPasswordOnInputChange = useCallback(( e:any) => {
    const passwordMeetsRequirements = passwordFormat(e.target.value);
    if(passwordMeetsRequirements.hasErrors) {
      if(e.target.value !== confirmPassword) {
        setNewPasswordError(true);
        setNewPasswordErrorText('As duas palavras passe devem ser iguais');
      } else {
        setNewPasswordError(false);
        setNewPasswordErrorText('');
        setConfirmPasswordError(false);
        setConfirmPasswordErrorText('');
      }
    } else {
      if(passwordMeetsRequirements.errorType === 'size') {
        setNewPasswordError(true);
        setNewPasswordErrorText('A palavra passe deve ter pelo menos 8 carateres');
      } else if(passwordMeetsRequirements.errorType === 'uppercase') {
        setNewPasswordError(true);
        setNewPasswordErrorText('A palavra passe deve conter pelo menos uma letra maiuscula');
      } else if(passwordMeetsRequirements.errorType === 'number') {
        setNewPasswordError(true);
        setNewPasswordErrorText('A palavra passe deve conter pelo menos um número');
      } else if(passwordMeetsRequirements.errorType === 'both') {
        setNewPasswordError(true);
        setNewPasswordErrorText('A palavra passe deve conter pelo menos uma letra maiuscula e um número');
      }
    }
      
    setNewPassword(e.target.value);

    // var minNumberofChars = 8;
    // var maxNumberofChars =16;
    // setNewPassword(e.target.value);
    // var pwdRegex = /^[a-zA-Z0-9]{8,16}$/;
    // var pwdRegex2 = /^(?=.*[a-zA-Z0-9!@#$%^&*]){8,16}$/;

    // if(e.target.value.length < minNumberofChars && e.target.value.length > maxNumberofChars) {
    //   setNewPasswordError(true);
    //   setNewPasswordErrorText('A palavra passe deve ter entre 8 e 16 caracteres');
    // } else if(!pwdRegex.test(e.target.value)) {
    //   setNewPasswordError(true);
    //   setNewPasswordErrorText('A palavra passe deve conter pelo menos uma letra maiuscula e um número');
    // } else if(e.target.value !== confirmPassword) {
    //   setConfirmPasswordError(true);
    //   setConfirmPasswordErrorText('As duas palavras passe devem ser iguais');
    //   setNewPasswordError(false);
    //   setNewPasswordErrorText('');
    // } else {
    //   setNewPasswordError(false);
    //   setNewPasswordErrorText('');
    //   setConfirmPasswordError(false);
    //   setConfirmPasswordErrorText('');
    // }
  }, [confirmPassword]); 

  // callbacks para atualização de imagem de utilizador
  const handleUpdateAvatarChange = useCallback(( e:any) => {
    const formdata = new FormData();
    if(e.target.files && user) {
      let file = e.target.files[0];
      let fileType:string = file.name.split('.').pop()!;
      // if(file.size > 2097152)
      // {
      //   openAlert({
      //     variant: "info",
      //     text: "O Tamanho do ficheiro é superior a 2MB. Por favor introduza um ficheiro dentro do limite de tamanho. ",
      //     title: "Atenção",
      //   });
      // }
      // else 
      if(!".svg, .png, .jpg, .jpeg".includes(fileType))
      // if(e.target.files[0].type !== 'image/svg+xml')
      {
        openAlert({
          variant: "error",
          text: "O ficheiro seleccionado não é do tipo apropriado. Seleccione um ficheiro com um dos seguintes formatos: svg, png, jpg ou jpeg",
          title: "Erro",
        });  
      }
      else {
        formdata.append("file",e.target.files[0]);
        userService.uploadImage(user.userId,formdata).then(data => {
          console.log(data)
          if (data.succeeded)
          {
            let fileResult = (data.value as UserModel).avatarSrc;
            let fileResultType = fileResult.split(',').shift();
            let imageFile = fileResult.split(',',2).pop();
            if (fileResultType === 'data:image/svg+xml'){
              setUser({...user, avatarSrc:(data.value as UserModel).avatarSrc});
            }
            else {
              let displayImage = fileResultType + ';base64,' + imageFile;
              setUser({...user, avatarSrc:displayImage});
            }
            openAlert({
              variant: "success",
              text: "A imagem foi atualizada.",
              title: "Sucesso",
              autoHideDuration: 5000,
            });
            }
        }) 
      }
    }
    else {
      openAlert({
        variant: "info",
        text: "Não foi seleccionado um ficheiro para atualização.",
        title: "Atenção",
      });
    }         
  }, [user]); 

  const handleRemoveAvatarChange = useCallback(( e:any) => {
    if(user) {
      userService.removeImage(user.userId).then(data => {
        if (data.succeeded) {
          setUser({...user, avatarSrc: ""});
          openAlert({
            variant: "success",
            text: "A imagem foi removida.",
            title: "Sucesso",
          });
        }
      }) 
    } else {
      openAlert({
        variant: "error",
        text: "Não foi possível remover a imagem.",
        title: "Erro",
      });
    }
            
  }, [user]);

  // atualizar e gravar utilizador
  const handleUpdateUserClick = useCallback((e: any)  => {
    if (user !== undefined && user.username !== '' && user.email !== '' && user.profileId ) {
      userService.add(user).then((data) => {
        if(data.succeeded){
          openAlert({
            variant: "success",
            text: "O utilizador foi atualizado com sucesso.",
            title: "Sucesso",
            autoHideDuration: 5000,
          });
          setUser(data.value as UserModel);
          setCurrentUser(data.value as UserModel);
          if(!propertyHidden('Utilizadores', 'Backoffice')){
            navigate(`/users`)
          }  
        } else {
          openAlert({
            variant: "error",
            text: "Não foi possível atualizar o utilizador.",
            title: "Erro",
          });
        }
      })
    } 
  }, [user, navigate, propertyHidden, setCurrentUser]);
  // remover utilizador

  const handleRemoveUserClick = useCallback(async ( e:any) => {
    let outPutMessage = "";
    let asyncResponseObject: {
      result: string,
      errors: string
    } = {
      result: '',
      errors: outPutMessage
    }
    if(user) {
      await userService.delete(user.userId).then((data) => {
        if(data && data.succeeded){

          setAsyncResponse(asyncResponseObject);
          openAlert({
            variant: "success",
            text: "O utilizador foi removido com sucesso.",
            title: "Sucesso",
            autoHideDuration: 5000,
          })
        } else {
          outPutMessage = "Não foi possível remover o utilizador. Existem dados associados ao utilizador actual.";
          asyncResponseObject = {
            errors: outPutMessage,
            result:''
          }
          setAsyncResponse(asyncResponseObject);

          openAlert({
            variant: "error",
            text: "Não foi possível remover o utilizador. Existem dados associados ao utilizador actual.",
            title: "Erro",
          })
        }
        navigate(`/users`)
      } );
    } else {
      navigate(`/users`);
    }   
  }, [user, navigate, setAsyncResponse]);

  useEffect(() => {
    //carrega utilizador
    userService.getUserById(Number(params.userId),null).then(userData => {
      if(userData.succeeded){
        //GESTÃO DE UTILIZADORES
        setUser(userData.value as UserModel);
      
        //carrega concessões
        customerService.getCenterDealer().then((data)=>{
          if(data && data.length > 0){
            let options:Option[] = [];
            data.forEach((element:any) => {
              options.push({
                label: `${element.id} - ${element.description}`,
                value: element.id,
              })            
            })
            setConcessionOptions(options);
          }
        });

        //CARREGAR PERFIS
        profileService.get().then(data => {
          if(data.succeeded) {
            data.value.forEach((element: any) => {
              setProfilesOptions(rows => {
                let updatedProfileOptions =  [...rows];
                if(!updatedProfileOptions.some(x => x.value === element.id)) {
                  updatedProfileOptions.push({label:element.name,value:element.id});
                }
                return updatedProfileOptions;
              })
            });
          }
        });

        // tipos de aluguer
        vehicleService.getRentalTypes().then(data => {
          if(data.succeeded) {
            let options: {value:string,label:string}[] = [];
            data.value.forEach((element: any) => {
              options.push({
                label:element.label,
                value:element.value
              });
            });
            setRentalTypeOptions(options);
          }
        });

        // condições comerciais
        if(
          userData.value['commercialConditions'] && 
          userData.value['commercialConditions'] !== '' && 
          userData.value['typeofRental'] && 
          userData.value['typeofRental'] !== ''
        ){
          vehicleService.getCommercialConditions(userData.value['typeofRental']).then(data => {
            if(data.succeeded) {
              let options: {value:string,label:string}[] = [];
              data.value.forEach((element: any) => {
                options.push({
                  label:element.label,
                  value:element.value
                });
              });
              setCommercialConditionsOptions(options);
            }
          });
        }

        if(userData.value.webPortalContactId) {
          let applicantsOptionsList: Option[] = [{label: '', value: ''}];
          contactService.getApplicants(userData.value.webPortalContactId).then((data) => {
            if( data && data.succeeded){
              if(data.value.length > 0){
                data.value.forEach((element: any) => {
                  applicantsOptionsList.push({
                    label: `${element.no} - ${element.name}`,
                    value: element.no
                  });
                });
                setApplicant(applicantsOptionsList.find(x => x.value === userData.value.webPortalContactId) ?? {label: '', value: ''});
                setApplicantContactsOptionsList(applicantsOptionsList);
              }
            }
          });
        } else {
          contactService.getContacts().then((data) => {
            if(data && data.succeeded){
              let applicantsOptionsList: Option[] =[{
                label:'',
                value:''
              }];
      
              data.value.forEach((element: any) => {
                applicantsOptionsList.push({
                  label: `${element.no} - ${element.name}`,
                  value: element.no
                });
              });
              setApplicantContactsOptionsList(applicantsOptionsList);
            }
      
          });
        }



 
      }
    });
  }, [params.userId]);

  //MESSAGEM PERSISTENTE DE CRIAÇÃO/ATUALIZAÇÃO CALCULO
  useEffect(() => {
    // persiste mensagens de calculo para outras páginas ( quer de sucesso ou nao)
    if (asyncResponse) {
      // Display the openAlert message with the response data.
      if(asyncResponse.errors !== "") {
        openAlert({
          variant: "error",
          text: asyncResponse.errors,
          title: "Atenção",
          // autoHideDuration: 3000,
        })
      } else {
        openAlert({
          variant: "success",
          text: "O utilizador foi removido com sucesso.",
          title: "Atenção",
          autoHideDuration: 5000,
        })
      }
      // Reset the asyncResponse in the context to avoid repeated alerts.
    }
  }, [ asyncResponse, ]);
  return (
    <>
      <BackofficeEditUserPage
        topBarProps={topBarPropsMemo}
        dashboardMenuButtonProps={dashboardMenuButtonPropsMemo}
        proposalMenuButtonProps={proposalMenuButtonPropsMemo}
        clientsMenuButtonProps={clientsMenuButtonPropsMemo}
        contractsMenuButtonProps={contractsMenuButtonPropsMemo}
        performanceMenuButtonProps={performanceMenuButtonPropsMemo}
        backofficeMenuButtonProps={backofficeMenuButtonPropsMemo}

        breadCrumbsLinks={[
          {
            label: 'Backoffice',
            onClick: handleCardClick('backoffice'),
          },
          {
            label: 'Gestão de Utilizadores',
            onClick: handleCardClick('users'),
          },
          { label:  user?.username ?? '' },
        ]}
        // cabeçalho do utilizador
        pageTitle={ `Utilizador ${user?.username}`}
        createdBy={{ label: 'Criado por', value: user?.createdByUserId ? 'Pedro Coutinho' : '' }} // mapear isto para obter o nome do utilizador com base no id
        creationDate={{ label: 'Criado em', value: formatDateWithTimeZone(user?.created.toString(), timeZone) ?? '' }}
        saveLinkText="Alterar e guardar"
        saveLinkProps={{
          onClick: handleUpdateUserClick,
        }}
        nameTextFieldProps={{ 
          label: 'Username do Utilizador',
          value: user.username,
          name:'username',
          onInput: handeNameInput,
          onFocus: handleNameFocus,
          helperText: invalidInputList.find(x => x.inputPropName === 'nameTextFieldProps')?.errorText ?? '',
          error: invalidInputList.find(x => x.inputPropName === 'nameTextFieldProps')?.hasError ?? false,
          disabled: false,
          hidden: false
        }}
        emailTextFieldProps={{
          label: 'Email',
          value: user.email,
          name:'email',
          onInput:handleEmailTextInput,
          onFocus: handleEmailFocus,
          helperText: invalidInputList.find(x => x.inputPropName === 'emailTextFieldProps')?.errorText ?? '',
          error: invalidInputList.find(x => x.inputPropName === 'emailTextFieldProps')?.hasError ?? false,
          disabled: false,
          hidden: false
        }}
        stateDropDownProps={{
          label: 'Estado',
          options: [
            { label: 'Ativo', value: 'active' },
            { label: 'Inativo', value: 'inactive' },
          ],
          value: user.active ? 'active' : 'inactive',
          name: 'active',
          onChange: handleDropdownChange,
          disabled: false,
          hidden: false
        }}
        profileDropDownProps={{
          label: 'Perfil',
          options: profilesOptions,
          value: user.profileId ?? {label: '', value: 0},
          name: 'profileId',
          onChange: handleDropdownChange,
          onFocus: handleProfileFocus,
          helperText: invalidInputList.find(x => x.inputPropName === 'profileDropDownProps')?.errorText ?? '',
          error: invalidInputList.find(x => x.inputPropName === 'profileDropDownProps')?.hasError ?? false,
          disabled: propertyDisabled("Perfis", 'Backoffice'),
          hidden: propertyHidden("Perfis", 'Backoffice')
        }}
        concessionDropDownOptions={{
          label: 'Concessão',
          options: concessionOptions,//[{ label: 'Santogal Honda', value: 'sh' }],
          name:'concessions',
          value: user.concessions ? user.concessions :[''],//'sh',
          multiple: true,
          onChange: handleDropdownChange,
          disabled: false,
          hidden: false
        }}
        applicantDropDownProps= {{ 
          placeholder: "Selecionar",
          value: applicant, //user.webPortalContactId,
          onChange: selectApplicant,
          onInputChange: searchApplicant,
          label: "Contacto Requerente",
          options: applicantContactsOptionsList ?? [],
          // name: "webPortalContactId",
          disabled: false, 
          hidden: false
        }}
        rentalTypeDropDownProps= {{
          label: 'Tipo de Aluguer',
          options: rentalTypeOptions,
          value: user ? user.typeofRental : '',
          name: 'typeofRental',
          onChange: handleDropdownChange,
        }}
        commercialConditionsDropDownProps={{
          label: 'Condições Comerciais',
          options: commercialConditionsOptions,
          value: user ? user.commercialConditions : '',
          name: 'commercialConditions',
          onChange: handleDropdownChange,
          disabled: (user?.typeofRental && user.typeofRental === '') || !user?.typeofRental,
        }}
        incomeTypeDropDownProps={{
          label: 'Tipo de Rendimento',
          options: paymentTermsOptions,
          value: user ? user.typeofIncome : '',
          name: 'typeofIncome',
          onChange: handleDropdownChange,
        }}
        deleteUserLinkText="Remover utilizador"
        deleteUserConfirmationModalProps={{
          text: ' Todos os acessos serão eliminados!',
          onConfirm: handleRemoveUserClick, 
          title: 'Tem a certeza que pretende remover este utilizador?',
          cancelText: 'Cancelar',
          confirmText: 'Remover',
        }}

        tabsText={['Dados Pessoais', 'Definições']}
        // dados pessoais do utilizador
        personalData={{
          userImageSrc: user?.avatarSrc ? handleImage(user.avatarSrc) : defaultImg,
          photoAllowedTypes: ".svg, .png, .jpg, .jpeg",
          onAttachPhotoChange: handleUpdateAvatarChange,
          removePhotoClick: handleRemoveAvatarChange,
          changePhotoText: 'Alterar foto',
          removePhotoText: 'Remover foto',
          passwordTitle: 'Palavra passe',

          currentPasswordTextFieldProps: {
            label: 'Palavra passe atual',
            helperText: curretPasswordErrorText, //'Deve ter pelo menos 8 carateres e conter pelo menos uma letra maiuscula e um número',
            error: curretPasswordError,
            value: curretPassword,
            onChange: handleCurrentPasswordOnInputChange,
          },
          newPasswordTextFieldProps: {
            label: 'Nova palavra passe',
            helperText: newPasswordErrorText, //'Deve ter pelo menos 8 carateres e conter pelo menos uma letra maiuscula e um número',
            error: newPasswordError,
            value: newPassword,
            onChange: handleNewPasswordOnInputChange,
          },
          confirmPasswordTextFieldProps: {
            label: 'Confirmar palavra passe',
            helperText: confirmPasswordErrorText,//'As duas palavras passe devem ser iguais',
            error: confirmPasswordError,
            value: confirmPassword,
            onChange:handleConfirmPasswordOnInputChange,
          },

          onCurrentPasswordShowToggleClick: () => setShowingCurrentPassword((prev) => !prev),
          currentPasswordVisible: showingCurrentPassword,

          onNewPasswordShowToggleClick: () => setShowingNewPassword((prev) => !prev),
          newPasswordVisible: showingNewPassword,

          confirmPasswordVisible: showingConfirmPassword,
          onConfirmPasswordShowToggleClick: () => setShowingConfirmPassword((prev) => !prev),

          changePasswordButtonText: 'Alterar palavra passe',
          changePasswordLinkText: 'Alterar palavra passe',
          changePasswordClick: handleAllowPasswordChangeClick,
          changePassword: changePassword,

          changePasswordButtonProps: {
            disabled: (newPasswordError || confirmPasswordError),
            onClick:handleChangePasswordButtonClick,
          },

          cancelButtonText: 'Cancelar',
          cancelButtonProps: { 
            onClick: () => {
              setCurretPassword('');
              setNewPassword('');
              setConfirmPassword('');
              setNewPasswordError(true);
              setConfirmPasswordError(true);
              setNewPasswordErrorText('Deve ter pelo menos 8 carateres e conter pelo menos uma letra maiuscula e um número e as duas palavras passe devem ser iguais')
              setConfirmPasswordErrorText('Deve ter pelo menos 8 carateres e conter pelo menos uma letra maiuscula e um número e as duas palavras passe devem ser iguais');

              setChangePassword(false)
            } 
          },
        }}
        // notificações do utilizador
        definitions={[
          {
            switch: {},
            text: 'Receber notificação do novo estado de um processo para "Procurement Concluido!"',
          },
          {
            switch: {},
            text: 'Receber notificação quando um cliente já atribuido a si faz uma nova simulação',
          },
          {
            switch: {},
            text: 'Receber notificação quando um cliente já atribuido a si faz uma nova proposta',
          },
          {
            switch: {},
            text: 'Receber notificação quando o gestor da plataforma ou procurement adicionar a fatura proforma ou desconto atribuído',
          },

          {
            switch: {},
            text: 'Receber notificação quando a decisão da análise de risco for submetida na plataforma',
          },
          {
            switch: {},
            text: 'Receber notificação quando a decisão do cliente, sobre a análise de risco, for submetida na plataforma',
          },
          {
            switch: {},
            text: 'Quero receber notificações quando ocorrem situações anómalas ou quando existem componentes indisponíveis na plataforma. Ex: NAV não responde',
          },

          {
            switch: {},
            text: 'Receber uma notificação na plataforma com as aprovações pendentes',
          },
          {
            switch: {},
            text: 'Receber notificação quando o comercial escolhe uma viatura sem valor de VR ou de manutenção carregado',
          },
        ]}
        headSwitchProps={{}}
        headText={'Escolher notificações'}
      />
      <Alert open={open} onClose={handleClose} {...alertProps} />
    </>
  );
};

export default memo(BackofficeEditUser);
